/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

// eslint-disable-next-line no-unused-vars
type AspectsAndPages = {
  boxW: number
  boxH: number
  usersPerPage: number
}

export type UserLayoutPosition = {
  w: number
  h: number
  row: number
  col: number
  top: number
  left: number
}

/**
 * To get the gridAspectRatio layout size for VideoMeeting gridView
 * @param minWidth minimum width
 * @param boxW calculated width
 * @param screenWidth display container width
 * @param screenHeight display container height
 * @param userCount total number of track / users
 *
 * @returns boxW: number,boxH: number | boolean
 */

const aspect916 = (width: number) => {
  return (width * 9) / 16
}

const aspect43 = (width: number) => {
  return (width * 4) / 2.5
}

export const getGridAspect = (
  boxW: number,
  screenWidth: number,
  screenHeight: number,
  userCount: number,
  maxBoxW: number,
  aspectType: '16:9' | '4:3' = '16:9'
): { boxW: number; boxH: number; rows: number; cols: number } | undefined => {
  boxW += 5

  if (maxBoxW && boxW > maxBoxW) {
    return
  }

  const boxH = aspectType === '16:9' ? aspect916(boxW) : aspect43(boxW)
  const colUsers = Math.floor(screenWidth / boxW)

  if (userCount > colUsers) {
    const rowUsers = Math.ceil(userCount / colUsers)
    const nH = boxH * rowUsers
    if (nH > screenHeight) {
      return
    }
  }
  if (boxH > screenHeight || boxW > screenWidth) {
    return
  }

  const res = getGridAspect(boxW, screenWidth, screenHeight, userCount, maxBoxW, aspectType)
  if (res) {
    return res
  }
  const rowUsers = Math.ceil(userCount / colUsers)
  return { boxW, boxH, rows: rowUsers, cols: colUsers }
}

/**
 * Calculates the positions of boxes for each user in a grid layout.
 *
 * @param {number} boxHeight - The height of each box in the grid.
 * @param {number} boxWidth - The width of each box in the grid.
 * @param {number} usersPerPage - The maximum number of users to display per page.
 * @param {number} screenWidth - The width of the screen.
 * @param {number} screenHeight - The height of the screen.
 * @param {number} maxBoxW - The maximum width of each box.
 * @param {number} [topOffset=0] - The top offset of the grid.
 * @param {number} [leftOffset=0] - The left offset of the grid.
 * @return {UserLayoutPosition[]} An array of positions for each user in the grid.
 */

export const calculateBoxPositionsForEachUser = (
  boxHeight: number,
  boxWidth: number,
  usersPerPage: number,
  screenWidth: number,
  screenHeight: number,
  maxBoxW: number,
  topOffset = 0,
  leftOffset = 0
) => {
  const boxW = boxWidth
  const boxH = boxHeight
  let colUsers = Math.floor(screenWidth / boxW)
  colUsers = colUsers > usersPerPage ? usersPerPage : colUsers
  const rowUsers = Math.ceil(usersPerPage / colUsers)
  const lastRowUsers = usersPerPage % colUsers
  const positions: UserLayoutPosition[] = []

  // calculate row center

  const totalRowH = boxH * rowUsers
  const totalColW = boxW * colUsers
  const totalLastRowW = boxW * lastRowUsers
  const topCenter = (screenHeight - totalRowH) / 2
  const leftCenter = (screenWidth - totalColW) / 2
  const leftLastRowCenter = (screenWidth - totalLastRowW) / 2

  // console.log('calcu', boxH, colUsers, rowUsers, lastRowUsers, totalRowH, topCenter, maxBoxW)
  // calcu 286.6666666666667 2 1 0 286.6666666666667 2.166666666666657
  // calcu 228.57142857142858 2 1 0 228.57142857142858 31.214285714285708 291

  for (let i = 0; i < rowUsers; i++) {
    for (let j = 0; j < colUsers; j++) {
      if (i === rowUsers - 1 && lastRowUsers > 0) {
        positions.push({
          w: boxW,
          h: boxH,
          top: topOffset + i * boxH + topCenter,
          left: j * boxW + leftLastRowCenter + leftOffset,
          row: i,
          col: j,
        })
      } else {
        positions.push({
          w: boxW,
          h: boxH,
          top: topOffset + i * boxH + topCenter,
          left: j * boxW + leftCenter + leftOffset,
          row: i,
          col: j,
        })
      }
    }
  }

  return positions
}

/**
 * Calculates the aspect ratio and positions for a grid layout based on the provided parameters.
 *
 * @param {number} boxW - The width of each box in the grid.
 * @param {number} boxH - The height of each box in the grid.
 * @param {number} usersPerPage - The number of users to display per page.
 * @param {number} screenWidth - The width of the screen.
 * @param {number} screenHeight - The height of the screen.
 * @param {number} [topOffset=0] - The top offset for the grid.
 * @param {'16:9' | '4:3'} [aspectType='16:9'] - The aspect type for the grid.
 * @param {number} maxBoxW - The maximum width for each box in the grid.
 * @return {Array} An array of positions for the grid layout.
 */

export const getAspectAndPositions = ({
  boxW,
  boxH,
  usersPerPage,
  screenWidth,
  screenHeight,
  topOffset = 0,
  aspectType = '16:9',
  maxBoxW,
}: {
  boxW: number
  boxH: number
  usersPerPage: number
  screenWidth: number
  screenHeight: number
  topOffset: number
  aspectType: '16:9' | '4:3'
  maxBoxW: number
}) => {
  const size = getGridAspect(boxW, screenWidth, screenHeight, usersPerPage, maxBoxW, aspectType)

  if (size) {
    const positions = calculateBoxPositionsForEachUser(
      size.boxH,
      size.boxW,
      usersPerPage,
      screenWidth,
      screenHeight,
      maxBoxW,
      topOffset
    )

    if (positions.length > 0) {
      // console.log("screenHeight", screenHeight, size.boxH, positions[0].top)
      // 291 286.6666666666667 293.16666666666663
      // screenHeight 287.5 228.57142857142858 29.464285714285708
    }
    return positions
  }
  return []
}

/**
 * Calculates the box positions for unpinned users in a grid layout.
 *
 * @param {number} boxWidth - The width of each box in the grid.
 * @param {number} usersPerPage - The number of users to display per page.
 * @param {number} screenHeight - The height of the screen or container.
 * @param {number} left - The left offset of the grid.
 * @return {UserLayoutPosition[]} An array of box positions for unpinned users.
 */

export const calculateBoxPositionsForUnpinnedUsers = (
  boxWidth: number,
  usersPerPage: number,
  screenHeight: number,
  left: number
) => {
  const boxW = boxWidth / 1.1
  const boxH = (boxW * 9) / 16
  // const colUsers = Math.floor(screenHeight / boxH)

  const maxUsers = usersPerPage > 3 ? 3 : usersPerPage
  const positions: UserLayoutPosition[] = []
  const topCenter = (screenHeight - boxH * maxUsers) / 2
  for (let i = 0; i < usersPerPage; i++) {
    positions.push({ w: boxW, h: boxH, top: i * boxH + topCenter, left, row: 0, col: i })
  }

  return positions
}

/**
 * To get the aspectRatio layout size for VideoMeeting PinnedParticipant / SingleParticipantView
 * @returns width: number,: number
 */

export const getAspectSize = ({ w, h }: { w: number; h: number }) => {
  // const u = 2
  const cw = w - 50
  const ch = h - 50

  // calculate best fit aspect ratio for 16:9 or 4:3
  let width = cw
  let height = ch
  if (cw / ch > 16 / 9) {
    width = (ch * 16) / 9
  } else {
    height = (cw * 9) / 16
  }
  const left = (w - width) / 2
  const top = (h - height) / 2
  return { width: width, height: height, top: top, left: left }
}

/**
 * Calculates the positions of audio grid cards within a layout.
 *
 * @param {number} layoutWidth - The width of the layout.
 * @param {number} layoutHeight - The height of the layout.
 * @param {number} cardWidth - The width of each card.
 * @param {number} cardHeight - The height of each card.
 * @param {number} numberOfCards - The total number of cards.
 * @param {number} [topOffset=0] - The offset from the top of the layout.
 * @return {{ positions: UserLayoutPosition[], hiddenCards: number }} An object containing the positions of the cards and the number of hidden cards.
 */

export const getAudioGridPositions = (
  layoutWidth: number,
  layoutHeight: number,
  cardWidth: number,
  cardHeight: number,
  numberOfCards: number,
  topOffset = 0
) => {
  const cardWidthWithPadding = cardWidth + 15
  const cardHeightWithPadding = cardHeight + 15
  const numberOfColumns = Math.floor(layoutWidth / cardWidthWithPadding)

  let left = (layoutWidth - cardWidthWithPadding * numberOfCards) / 2 // (1560 - 60 * 2) / 2 =
  let top = (layoutHeight - cardHeightWithPadding) / 2 + topOffset // (260 - 60) /2 + 260 = 361.3636363636363
  let hiddenCards = 0
  let displayCards = numberOfCards

  if (numberOfCards > numberOfColumns) {
    hiddenCards = numberOfCards - numberOfColumns - 1
    left = (layoutWidth - cardWidthWithPadding * (numberOfColumns - 1)) / 2
    top = (layoutHeight - cardHeightWithPadding) / 2
    displayCards = numberOfColumns - 1
  }

  const positions: UserLayoutPosition[] = []

  for (let i = 0; i < displayCards; i++) {
    positions.push({ w: cardWidthWithPadding, h: cardHeightWithPadding, top: top, left: left, row: 0, col: i })
    left += cardWidthWithPadding
  }
  return { positions, hiddenCards }
}
