/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Linkify from 'react-linkify';
import {Button} from '../../Components';
import NewIcon from '../../Components/base/icon/newIcons';
import {
  messageApi,
  useGetMessagesByMeetingRequestIdQuery,
  useGetTodayMeetingRequestCountQuery,
  useGetUploadedFileQuery,
} from '../../Services/messageApi';
import {useGetSettingValue} from '../../Services/settingReducer';
import {ChatMessage, ChatMessageType} from '../../Store/Chat/ChatModel';
import {getActiveMeetingRequestId} from '../../Store/Chat/chat';
import {useAppDispatch, useAppSelector} from '../../Store/hooks';
import {Accept} from '../../models/meetingRequest.model';
import {useHandleMeetingNavigation} from '../LiveKit/hooks/livekitWebOnlyHooks';
import VideoMeetingStatus from './VideoMeetingStatus';
import {DisplayFormattedTime} from '../../Components/formattedDateAndTime';
import {toastError} from '../../Components/toast';
import {getMeetingInfo} from '../../Services/livekitReducer';
import Icon from '../../Components/base/icon/icon';

/**
 * A component that displays a sent message in a chat conversation.
 * It displays the message and the timestamp of when it was sent.
 * It also handles different types of messages, including text and video requests.
 * For video requests, it displays the request status and allows the user to join the meeting.
 *
 * @param {ChatMessage} msg - The message to be displayed.
 * @return {JSX.Element} The JSX element representing the sent message.
 */
function SentMessage({msg}: {msg: ChatMessage}) {
  return (
    <div
      className={`${
        msg.sent === false ? 'opacity-50' : 'opacity-100'
      } self-end`}>
      <div className="flex flex-row gap-x-2 items-center">
        <div
          id={`sentTime-${msg?.messageId}`}
          className="text-xxs text-gray-400 text-left">
          <DisplayFormattedTime displayFromTime={msg.dateSend} />
        </div>
        <RenderMessage msg={msg} />
      </div>
    </div>
  );
}

export default SentMessage;

/**
 * Renders a chat message based on the message type.
 *
 * For text messages, it renders a {@link DisplayTextMessage} component.
 * For video messages, it renders a {@link DisplayVideoRequest} component.
 *
 * @param {{ msg: ChatMessage }} props - The component props.
 * @param {ChatMessage} props.msg - The chat message to be rendered.
 *
 * @returns {JSX.Element} The rendered component.
 */
const RenderMessage = ({msg}: {msg: ChatMessage}) => {
  console.log('render message in sent', msg);
  
  return (
    <div className="max-w-[25rem]">
      {msg.type === ChatMessageType.Text && <DisplayTextMessage msg={msg} />}
      {msg.type === ChatMessageType.Video && <DisplayVideoRequest msg={msg} />}
      {msg.type === ChatMessageType.File && <DisplayFile msg={msg} />}
    </div>
  );
};

/**
 * Displays a video request message.
 *
 * This component renders a video request message with the video request details,
 * including the meeting ID, meeting start and end times, and the status of the
 * video request (accepted, declined, or expired).
 *
 * If the video request is not expired, it displays a button to join the meeting.
 * If the video request is expired, it displays a message indicating that the
 * request has expired.
 *
 * @param {{ msg: ChatMessage }} props The component props.
 * @param {ChatMessage} props.msg The chat message containing the video request.
 *
 * @returns {JSX.Element} The rendered component.
 */
function DisplayVideoRequest({msg}: {msg: ChatMessage}) {
  const {t} = useTranslation();
  const [isExpired, setIsExpired] = useState(true);

  const chatVideoLimit = useGetSettingValue('CHAT_VIDEO_REQUEST_PER_DAY');
  const chatAcceptTimeLimit = useGetSettingValue(
    'CHAT_ACCEPT_VIDEO_REQUEST_TIME_LIMIT(MINUTES)',
  );

  const {data: todayMeetingRequest} = useGetTodayMeetingRequestCountQuery();

  // const { data: file } = useGetUploadedFileQuery(msg?.messageId)

  const activeMeetingRequestId = useAppSelector(getActiveMeetingRequestId);

  useGetMessagesByMeetingRequestIdQuery(activeMeetingRequestId, {
    skip: activeMeetingRequestId !== msg?.meetingRequest?.id,
  });
  const {joinMeeting} = useHandleMeetingNavigation();
  const meetingInfo = useAppSelector(getMeetingInfo);

  const handleJoinClick = () => {
    if (
      meetingInfo &&
      (meetingInfo.isAudioMeeting || meetingInfo.isVideoMeeting)
    ) {
      toastError(t('meetingOngoingDisconnectToJoin'));
    } else {
      joinMeeting(msg?.meetingRequest?.meeting?.id);
    }
  };

  useEffect(() => {
    let setTimerInterval: any;
    if (chatAcceptTimeLimit) {
      if (
        new Date().valueOf() - new Date(msg?.dateSend).valueOf() >
        parseInt(chatAcceptTimeLimit) * 60 * 1000
      ) {
        setIsExpired(true);
      } else {
        setIsExpired(false);
      }
      setTimerInterval = setInterval(() => {
        if (
          new Date().valueOf() - new Date(msg?.dateSend).valueOf() >
          parseInt(chatAcceptTimeLimit) * 60 * 1000
        ) {
          setIsExpired(true);
        } else {
          setIsExpired(false);
        }
      }, 1000);
    }
    return () => {
      clearInterval(setTimerInterval);
    };
  }, [chatAcceptTimeLimit]);

  console.log('todayMeetingRequest', todayMeetingRequest);

  return (
    <div
      className={`${
        !msg.meetingRequest?.accept
          ? 'bg-white text-gray-700'
          : msg.meetingRequest?.accept === Accept.Yes
          ? 'bg-chat-sent text-primary'
          : 'bg-white text-gray-700'
      } flex flex-col rounded-md px-5 py-2 my-2 max-w-[20rem] text-md border-2`}>
      <div className="flex flex-row justify-start items-center gap-x-3 text-sm">
        {msg.meetingRequest?.accept === Accept.Yes ? (
          <NewIcon icon="VIDEO_CAM_WHITE" size="small" height="small" />
        ) : (
          <NewIcon icon="VIDEO_CAM" size="small" height="small" />
        )}
        {!isExpired &&
          msg.meetingRequest?.accept !== Accept.Yes &&
          msg.meetingRequest?.accept !== Accept.No && (
            <div
              id={`chk_videoRequestGiven-${msg?.messageId}`}
              className="block">
              {t('videoRequestSent')}
            </div>
          )}
        {msg.meetingRequest?.accept === Accept.Yes && (
          <div id={`chk_videoRequestGiven-${msg?.messageId}`} className="block">
            {t('videoRequestAccepted')}
          </div>
        )}
        {msg.meetingRequest?.accept === Accept.No && (
          <div id={`chk_videoRequestGiven-${msg?.messageId}`} className="block">
            {t('videoRequestDeclined')}
          </div>
        )}
        {isExpired && !msg.meetingRequest?.accept && (
          <div id={`chk_videoRequestGiven-${msg?.messageId}`} className="block">
            {t('videoRequestExpired')}
          </div>
        )}
      </div>

      {(msg.meetingRequest?.meeting?.actualStartAt ||
        (!msg.meetingRequest?.accept && !isExpired)) && (
        <div className="bg-white h-[0.2px] opacity-20 mt-3" />
      )}

      {!msg.meetingRequest?.accept && !isExpired && (
        <div className="flex text-sm">
          {
            <span id={`chk_meetLimit-${msg?.messageId}`}>
              {t('numberOfFreeMeetingsRemaining')}:&nbsp;
              {typeof chatVideoLimit !== 'undefined' &&
                typeof todayMeetingRequest !== 'undefined' &&
                parseInt(chatVideoLimit) - todayMeetingRequest}{' '}
            </span>
          }
        </div>
      )}
      {msg.meetingRequest?.accept === Accept.Yes && !isExpired && (
        <div className="flex flex-row mt-3 items-center justify-center text-sm">
          {
            <Button
              id={`join-${msg?.messageId}`}
              data-testid={`join-${msg.meetingRequest?.id}`}
              size="sm"
              rounded
              color="join"
              onClick={handleJoinClick}>
              {t('join')}
            </Button>
          }
        </div>
      )}
      <VideoMeetingStatus message={msg} />
    </div>
  );
}

/**
 * Displays a text message sent by the user.
 *
 * It renders a message in a white background with a rounded corner and a light gray border.
 * The message is displayed in a Linkify component which allows to render a link if the message contains a http or https link.
 *
 * @param {ChatMessage} msg The message to be displayed.
 * @return {JSX.Element} The rendered component.
 */
function DisplayTextMessage({msg}: {msg: ChatMessage}) {

  console.log('sent text message', msg);
  
  return (
    <div
      data-testid={msg?.message}
      className={`bg-chat-sent rounded-md px-3 py-2 my-2 text-white text-sm`}>
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a
            className="underline"
            target="blank"
            href={decoratedHref}
            key={key}>
            {decoratedText}
          </a>
        )}
        key={msg?.message}>
        {
          <div id={`sentMsg_${msg?.messageId}`} className="break-all text-md">
            {msg?.message}
          </div>
        }
      </Linkify>
    </div>
  );
}

function DisplayFile({msg}: {msg: ChatMessage}) {
  const baseImageURL = useGetSettingValue('IMAGE_URL');
  const fileUrl = `${baseImageURL}/${msg?.attachedFile ? msg?.attachedFile : msg?.message}`;
  const fileType = msg?.attachedFile ? msg?.attachedFile?.split('.').pop()?.toLowerCase() : msg?.message?.split('.').pop()?.toLowerCase();
  const [isModalOpen, setIsModalOpen] = useState(false);

  console.log('sent file message', msg);

  const openFilePreview = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      id={msg?.message}
      data-testid={msg?.message}
      className="rounded-md px-3 py-2 my-2 flex-1 text-gray-700 text-sm">
      <Linkify
        componentDecorator={(decoratedHref, key) => (
          <a
            className="underline"
            target="_blank"
            href={decoratedHref}
            key={key}
            rel="noreferrer">
            {decoratedHref}
          </a>
        )}
        key={msg?.message}>
        <div id={`sentMsg_${msg?.messageId}`} className="break-all">
          {/* Preview logic */}
          {['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(
            fileType as string,
          ) ? (
            <img
              src={fileUrl}
              alt="Preview"
              className="max-w-full max-h-64 object-contain rounded cursor-pointer"
              onClick={openFilePreview}
              onError={e => (e.currentTarget.style.display = 'none')}
            />
          ) : fileType === 'pdf' ? (
            <div
              onClick={openFilePreview}
              className="flex flex-col items-center cursor-pointer border border-gray-300 rounded">
              <Icon
                icon="PDFICON"
                size="large"
                height="small"
                className="mb-2 cursor-pointer"
              />
              <span className="text-center text-sm text-gray-600 break-words">
              {msg?.attachedFile ? msg?.attachedFile : msg?.message}
              </span>
            </div>
          ) : fileType === 'svg' ? (
            <img
              src={fileUrl}
              alt="SVG Preview"
              className="max-w-full max-h-64 object-contain rounded cursor-pointer"
              onClick={openFilePreview}
            />
          ) : (
            <div className="file-name text-gray-600">{msg?.attachedFile ? msg?.attachedFile : msg?.message}</div>
          )}

          {/* Fullscreen Preview Modal */}
          {isModalOpen && (
            <div className="fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-80">
              {/* Close Button */}
              <button
                onClick={e => {
                  e.stopPropagation();
                  closeModal();
                }}
                className="absolute top-20 right-4 w-8 h-8 flex items-center justify-center bg-white text-black text-lg font-bold rounded-full shadow-lg hover:bg-gray-300 z-50 border border-gray-200">
                ✕
              </button>

              {/* Modal Content */}
              <div className="relative w-full h-full flex justify-center items-center">
                {['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(
                  fileType as string,
                ) ? (
                  <img
                    src={fileUrl}
                    alt="Full Preview"
                    className="max-h-full max-w-full object-contain pt-24 justify-center items-center"
                  />
                ) : fileType === 'pdf' ? (
                  <iframe
                    src={fileUrl}
                    className="w-full h-full border-none p-3"
                    title="PDF Preview"
                  />
                ) : fileType === 'svg' ? (
                  <img
                    src={fileUrl}
                    alt="Full Preview"
                    className="max-w-full max-h-full object-contain"
                  />
                ) : (
                  <div className="file-name text-gray-600">{msg?.attachedFile ? msg?.attachedFile : msg?.message}</div>
                )}
              </div>
            </div>
          )}
        </div>
      </Linkify>
    </div>
  );
}
