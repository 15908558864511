/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import {MeetingDetails, VideoType} from './../../models/meetingDetails.model';
import {useEffect, useState} from 'react';
import {
  useCreateMeetingDetailsMutation,
  useDeleteMeetingDetailsMutation,
  useUpdateMeetingDetailsMutation,
  useGetMeetingDetailsQuery,
  useUploadEventVideoMutation,
  useDeleteEventVideoMutation,
} from '../../Services/meetingDetailsApi';
import {useGetAllMeetingCategoryQuery} from '../../Services/allOthersApi';
import {useGetCancellationPolicyQuery} from '../../Services/policyApi';
import {meetingDetailsFormValidationSchema} from '../../Utils/validation';
import {useFieldArray, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {CancellationPolicy} from '../../models/cancellationPolicy.model';
import {uid} from '../../Utils/helper';

interface HelpList {
  help: string;
}
interface FormFields {
  title: string;
  description: string;
  help: string[];
  categories: string[];
  autoDelete: boolean;
  kidFriendly: boolean;
  kidsOnly: boolean;
  videoType: string;
  isAnonymous: boolean;
  isFreeAudioMeeting: boolean;
  display_isAudioMeeting: boolean;
  display_isStreaming: boolean;
  cancellationPolicy: CancellationPolicy | undefined;
  otherCategory: string;
  helpListText: string;
  helpsList: HelpList[];
}

/**
 * Custom React hook for managing event details.
 *
 * @param {object} meetingDetailId - The ID of the meeting details.
 * @return {object} An object containing various event details, functions, and states.
 */

export const useEventDetailsHook = ({
  meetingDetailId,
}: {
  meetingDetailId: string;
}) => {
  const [createMeetingDetails, {isSuccess: createMeetingDetailsSuccess}] =
    useCreateMeetingDetailsMutation();
  const [
    deleteMeetingDetails,
    {
      isSuccess: deletedMeetingDetailsSuccess,
      isError: deletedMeetingDetailsError,
      isLoading: deletedMeetingDetailsLoading,
    },
  ] = useDeleteMeetingDetailsMutation();
  const [updateMeetingDetails, {isSuccess: updatedMeetingDetailsSuccess}] =
    useUpdateMeetingDetailsMutation();
  const [
    uploadEventVideo,
    {isSuccess: uploadEventVideoSuccess, isLoading: uploadEventVideoLoading},
  ] = useUploadEventVideoMutation<any>();
  const [
    deleteEventVideo,
    {
      isSuccess: deleteEventVideoSuccess,
      isLoading: deleteUploadEventVideoLoading,
    },
  ] = useDeleteEventVideoMutation<any>();
  const {data: meetingCategoryList, isSuccess: meetingCategoryListSuccess} =
    useGetAllMeetingCategoryQuery();
  const {data: cancellationPolicies} = useGetCancellationPolicyQuery();

  const {data: meetingDetailsData, refetch: refetchMeetingDetails} =
    useGetMeetingDetailsQuery(meetingDetailId ? meetingDetailId : '', {
      skip: meetingDetailId ? false : true,
    });

  const [meetingDetails, setMeetingDetails] = useState<MeetingDetails>();
  const [showCancellationPolicy, setShowCancellationPolicy] = useState(false);
  const [showOtherCategory, setShowOtherCategory] = useState<boolean>(false);
  const [meetingCategory, setMeetingCategory] = useState<string[]>([]);
  const [isNewEvent, setIsNewEvent] = useState<boolean>(false);
  const [navigate, setNavigate] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [ShowAnonymous, setShowAnonymous] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isImagesandVideoUpdated, setIsImagesandVideoUpdated] = useState(false);
  const [createdMeetingDetails, setCreatedMeetingDetails] = useState<
    MeetingDetails | undefined
  >();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    trigger,
    reset,
    formState: {errors, isDirty, isValid, isSubmitted, isSubmitting},
  } = useForm<FormFields>({
    mode: 'onChange',
    resolver: yupResolver(meetingDetailsFormValidationSchema),
  });

  const {fields, append, remove} = useFieldArray({
    name: 'helpsList',
    control,
  });

  const resetForm = () => {
    const meetingDetailsEdit = {
      title: '',
      description: '',
      autoDelete: false,
      kidFriendly: false,
      kidsOnly: false,
      videoType: VideoType.Audio,
      isAnonymous: false,
      display_isAudioMeeting: true,
      isFreeAudioMeeting: true,
      helpsList: [{id: '', help: ''}],
    } as MeetingDetails;

    reset(meetingDetailsEdit);
    setMeetingCategory([]);
    setMeetingDetails(meetingDetailsEdit);
  };

  useEffect(() => {
    if (!isNewEvent && meetingDetailsData) {
      const resetMeetingDetails = Object.assign({}, meetingDetailsData);
      const help = Array.from(
        {length: 6},
        (_, i) => resetMeetingDetails.help[i] ?? '',
      );
      resetMeetingDetails.helpsList = help.map((help: string) => ({
        help,
        id: uid(),
      }));
      reset(resetMeetingDetails);
      setMeetingCategory(meetingDetailsData.categories);
      setMeetingDetails(meetingDetailsData);
    } else if (isNewEvent) {
      resetForm();
    }
  }, [meetingDetailsData, isNewEvent]);

  const onMeetingSubmit = (values: FormFields) => {
    setIsLoading(true);
    values.help = filterHelplist(values.helpsList);
    values.categories = meetingCategory;
    values.description = getValues('description');

    if (values.display_isAudioMeeting) {
      values.videoType = VideoType.Audio;
    } else if(values.display_isStreaming) {
      values.videoType = VideoType.Stream;
    }
    else {
      values.videoType = VideoType.Both;
    }

    if (meetingDetails?.id) {
      const meetingDetailsForUpdate = Object.assign({}, meetingDetails, values);
      setIsImagesandVideoUpdated(true);
      // setSubmitted(true)
      if (!submitted) {
        setSubmitted(true);
      }
      updateMeetingDetails(meetingDetailsForUpdate).then((res: any) => {
        setSubmitted(false);
        if (!res.errors) {
          setNavigate(true);
        }
        setIsLoading(false);
      });
    } else {
      createMeetingDetails(values as unknown as MeetingDetails).then(
        (res: any) => {
          if (!res.errors) {
            setCreatedMeetingDetails(res.data);
            setNavigate(true);
          }
          setIsLoading(false);
        },
      );
    }
  };

  const handleAddCategoryList = (item: string) => {
    const found = meetingCategory.find((check: string) => check === item);

    if (!found) {
      setMeetingCategory([...meetingCategory, item]);
    }
  };

  const handleRemoveCategory = (item: string) => {
    setMeetingCategory(meetingCategory.filter(category => category !== item));
  };

  return {
    meetingCategory,
    meetingCategoryList,
    meetingCategoryListSuccess,
    showOtherCategory,
    setShowOtherCategory,
    submitted,
    meetingDetails: meetingDetailsData ?? meetingDetails,
    onMeetingSubmit,
    isSubmitting,
    showCancellationPolicy,
    setShowCancellationPolicy,
    handleMeetingDelete: deleteMeetingDetails,
    deletedMeetingDetailsError,
    deletedMeetingDetailsLoading,
    createMeetingDetailsSuccess,
    deletedMeetingDetailsSuccess,
    updatedMeetingDetailsSuccess,
    deleteEventVideoSuccess,
    handleAddCategoryList,
    handleRemoveCategory,
    trigger,
    ShowAnonymous,
    setShowAnonymous,
    uploadEventVideo,
    deleteEventVideo,
    cancellationPolicies,
    setIsNewEvent,
    isNewEvent,
    register,
    control,
    navigate,
    handleSubmit,
    setValue,
    errors,
    isSubmitted,
    isDirty,
    isValid,
    watch,
    getValues,
    fields,
    append,
    remove,
    isLoading,
    uploadEventVideoLoading,
    uploadEventVideoSuccess,
    deleteUploadEventVideoLoading,
    createdMeetingDetails,
    isImagesandVideoUpdated,
    setIsImagesandVideoUpdated,
    refetchMeetingDetails,
  };
};

/**
 * Filters a list of HelpList objects to exclude empty help strings and returns the resulting list of help strings.
 *
 * @param {HelpList[]} helpList - The list of HelpList objects to filter.
 * @return {string[]} The list of non-empty help strings.
 */

function filterHelplist(helpList: HelpList[]) {
  const filteredHelpList = helpList.filter(help => help.help !== '');
  const helpListArray = filteredHelpList.map(help => help.help);
  return helpListArray;
}

export {};
