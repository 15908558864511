/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import * as React from 'react'

type CustomAudioGridContextTypes = {
  stagedParticipants: string[]

  addStagedParticipant: (stagedParticipant: string) => void
  removeStagedParticipant: (stagedParticipant: string) => void
  clearStagedParticipants: () => void
  isStagedParticipant: (stagedParticipant: string) => boolean
  getAllStagedParticipants: () => string[]
}

/** @public */
export const CustomAudioGridContext = React.createContext<CustomAudioGridContextTypes | undefined>(undefined)

/**
 * Ensures that a CustomAudioGridContext is provided via context.
 * If no context is provided, an error is thrown.
 *
 * @return {CustomAudioGridContextTypes | undefined} The CustomAudioGridContext if it exists, otherwise undefined.
 */

export function useCustomAudioGridContext() {
  const ctx = React.useContext(CustomAudioGridContext)
  if (!ctx) {
    throw Error('tried to access room context outside of livekit room component')
  }
  return ctx
}

/**
 * Returns the CustomAudioGridContext if it exists, otherwise undefined.
 *
 * @return {CustomAudioGridContextTypes | undefined} The CustomAudioGridContext if it exists, otherwise undefined.
 */

export function useMaybeCustomAudioGridContext() {
  return React.useContext(CustomAudioGridContext)
}

/**
 * Provides a CustomAudioGridContext to its children, managing a list of staged participants.
 *
 * @param {React.PropsWithChildren<{}>} props - The props for the provider, including the children to be wrapped.
 * @return {JSX.Element} The wrapped children with the CustomAudioGridContext.
 */

export function CustomAudioGridContextProvider({ children, ...props }: React.PropsWithChildren<{}>) {
  // const ctx = useCustomGridContext();
  const [stagedParticipants, setStagedParticipants] = React.useState<string[]>([])

  const addStagedParticipant = (stagedParticipant: string) => {
    if (stagedParticipant && stagedParticipant?.length > 0) {
      setStagedParticipants([...stagedParticipants, stagedParticipant])
    }
  }

  const removeStagedParticipant = (stagedParticipant: string) => {
    setStagedParticipants(stagedParticipants.filter((p) => p !== stagedParticipant).filter((p) => p !== ''))
  }

  const clearStagedParticipants = () => {
    setStagedParticipants([])
  }

  // TODO remove this code
  const isStagedParticipant = (stagedParticipant: string) => {
    return stagedParticipants.includes(stagedParticipant)
  }

  const getAllStagedParticipants = () => {
    return stagedParticipants
  }

  return (
    <CustomAudioGridContext.Provider
      value={{
        stagedParticipants,
        addStagedParticipant,
        removeStagedParticipant,
        clearStagedParticipants,
        isStagedParticipant,
        getAllStagedParticipants,
      }}
    >
      {children}
    </CustomAudioGridContext.Provider>
  )
}
