/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Button} from '../../../Components';
import useToggle from '../../../Components/_utils/useToggle';
import AvatarImage from '../../../Components/base/avatar/avatar';
import Field from '../../../Components/base/field/field';
import Loading from '../../../Components/base/loading/loading';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '../../../Components/base/modal/modal';
import {toastError, toastSuccess} from '../../../Components/toast';
import {useUserAnonymousEditHook} from '../../../Hooks/UserProfile';
import {getCurrentUser} from '../../../Services/userReducer';
import {useAppSelector} from '../../../Store/hooks';
import {Avatar} from '../../../models/avatar.model';
import ImageCropperAvatar from '../../ImageCropperAvatar';
import EditWrapper from './EditWrapper';
import UserAnonymous from './UserAnonymous';
import {useLocation, useNavigate} from 'react-router-dom';
import { useGetSettingValue } from '../../../Services/settingReducer';

/**
 * The UserAnonymousEditor component renders a toggle button for the anonymous mode on the user profile.
 * When the button is clicked, it opens a modal with the AnonymousEditor component which allows the user to update their anonymous profile.
 * The AnonymousEditor component is rendered conditionally based on the isEditorOpen state variable.
 * If the user is already in anonymous mode, the modal will open automatically and the checkbox will be checked.
 * @returns {JSX.Element} A JSX element representing the UserAnonymousEditor component.
 */

interface ErrorData {
  message: string;
}

const UserAnonymousEditor = () => {
  const {isOpen: isEditorOpen, toggle: toggleIsEditorOpen} = useToggle();
  const {avatarListLoading, nickNamesLoading, setValue} =
    useUserAnonymousEditHook();
  const currentUser = useAppSelector(getCurrentUser);

  const location = useLocation();
  const navigate = useNavigate();
  const {state} = location;

  useEffect(() => {
    if (state?.isAnon) {
      // Update the route state and open the editor
      navigate(location.pathname, {state: {isAnon: undefined}, replace: true});
      toggleIsEditorOpen();

      // Set the value of the checkbox to true
      setValue('anonymous', true);
    }
  }, [state?.isAnon]);

  /**
   * Toggles the isEditorOpen state variable when the edit button is clicked.
   * If the editor is not already open, it will open the editor.
   * Otherwise, it will do nothing.
   */
  const handleEditClick = () => {
    if (!isEditorOpen) toggleIsEditorOpen();
  };

  if (nickNamesLoading || avatarListLoading) {
    return <Loading />;
  }

  return (
    <EditWrapper keyId="userAnonymous" onEditClick={handleEditClick}>
      <UserAnonymous />
      {isEditorOpen && (
        <AnonymousEditor
          isEditorOpen={isEditorOpen}
          toggleIsEditorOpen={toggleIsEditorOpen}
        />
      )}
    </EditWrapper>
  );
};

export default UserAnonymousEditor;

interface UserAnonymousEditorProps {
  isEditorOpen: boolean;
  toggleIsEditorOpen: () => void;
}

/**
 * AnonymousEditor
 *
 * A component that renders a modal with input fields
 * for editing the user's anonymous profile.
 *
 * The component receives the following props:
 *
 * - `isEditorOpen`: a boolean indicating whether the
 *   modal is open or not.
 * - `toggleIsEditorOpen`: a function that toggles the
 *   `isEditorOpen` state variable.
 *
 * The component renders a modal with the following elements:
 *
 * - A header with the title "Anonymous Profile"
 * - A close button
 * - A form with input fields for:
 *   - Nick Name
 *   - Catch Phrase
 *   - Anonymous checkbox
 *   - Choose Avatar (with a row of avatar images)
 * - A save button
 *
 * When the save button is clicked, the component will
 * call the `submit` function from the `useUserAnonymousEditHook`
 * hook with the current values of the form.
 *
 * When the modal is closed, the component will call the
 * `toggleIsEditorOpen` function to toggle the `isEditorOpen`
 * state variable.
 *
 * @param {UserAnonymousEditorProps} props - the props for the component
 * @returns {JSX.Element} - the rendered component
 */
const AnonymousEditor = (props: UserAnonymousEditorProps) => {
  const {isEditorOpen, toggleIsEditorOpen} = props;
  const { t } = useTranslation();
  const [imagePrompt, setImagePrompt] = useState('');
  
  const {
    avatarList,
    profileUpdateSuccess,
    profileUpdateError,
    profileUpdateLoading,
    avatarListLoading,
    nickNamesLoading,
    autoCompletionNicknames,
    register,
    control,
    setValue,
    getValues,
    errors,
    watch,
    submit,
    trigger,
    openAiAvatarList,
    openAiAvatarListLoading,
    openAiAvatarListFetching,
    openAPIConfig,
    useOpenAPIConfigLoading,
    createImageLoading,
    handleGenerateImage
  } = useUserAnonymousEditHook(imagePrompt);

  const saveRef = useRef(null);
  const currentUser = useAppSelector(getCurrentUser);
  const maxAvatarCount = useGetSettingValue('MAX_IMAGE_GENERATION_COUNT');

  /**
   * Submits the form data to the server. Called when the save button is clicked or on enter key press.
   * @param {React.FormEvent<HTMLFormElement>} e - The form event
   */
  const callSave = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    //manually validate the form because of enter key submition on complete in modal.
    trigger().then(valid => {
      if (valid) {
        submit(getValues());
      }
    });
    const isCustomAvatar = watch('avatar') === 'customAvatar';

    if (saveRef.current && isCustomAvatar) {
      (saveRef.current as any).saveProcess();
    }
  };

  useEffect(() => {
    if (profileUpdateSuccess) {
      toastSuccess(t('anonymousProfileUpdatedSuccessfully'));
      toggleIsEditorOpen();
    }

    if (profileUpdateError) {
      if ('data' in profileUpdateError) {
        const errorData = profileUpdateError.data as ErrorData; 
        const errorMessage = errorData.message; 

        if (errorMessage) {
          toastError(t(`${errorMessage}`));
        }
      } else {
        toastError(t('An unexpected error occurred'));
      }
      toggleIsEditorOpen();
    }
  }, [profileUpdateSuccess, profileUpdateError]);

  /**
   * Closes the modal and toggles the isEditorOpen state variable.
   */
  const handleCloseEditor = () => {
    toggleIsEditorOpen();
  };

  const handleAvatarClick = (avatar: Avatar) => {
    setValue('avatar', avatar.avatarName);
  };

  const handlePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImagePrompt(e.target.value);
  };

  if (nickNamesLoading || avatarListLoading) {
    return <Loading />;
  }

  return (
    <Modal
      isOpen={isEditorOpen}
      toggle={() => {
        handleCloseEditor();
      }}
      closeOnClickOutside={false}>
      <div className="p-3 max-h-[80vh] overflow-y-scroll">
        <div className="flex flex-row justify-between">
          <ModalHeader keyId="anonymousProfile">
            <div>{t('anonymousProfile')}</div>
          </ModalHeader>
          <ModalCloseButton
            toggle={() => {
              handleCloseEditor();
            }}
          />
        </div>
        <ModalBody>
          <div className="flex flex-col gap-y-3 flex-1 min-h-[20rem] w-full relative">
            {/* Toggles and form inputs */}
            <div className="flex flex-col gap-y-2">
              {/* Nick Name */}
              <div className="flex flex-col gap-y-1 w-full">
                <div className="font-medium text-base">
                  {t('nickName') + ' *'}
                </div>
                <div
                  className="flex flex-col"
                  id="input_nickName"
                  data-testid="input_nickName">
                  {autoCompletionNicknames && (
                    <>
                      <Controller
                        control={control}
                        name={'nickName'}
                        defaultValue={currentUser.nickName}
                        render={({field: {onChange, value, name, ref}}) => (
                          <Field
                            {...register('nickName')}
                            data-testid="input_nickName"
                            defaultValue={currentUser.nickName}
                            error={
                              errors?.nickName || getValues('anonymous')
                                ? errors?.nickName?.message
                                : ''
                            }
                            name="nickName"
                            type="text"
                            placeholder={t('nickName') + ' *'}
                          />
                        )}
                      />
                    </>
                  )}
                </div>

                {/* Catch Phrase */}
                <div className="flex flex-col gap-y-2 mt-2 w-full">
                  <div className="font-medium text-base">
                    {t('catchPhrase') + ' *'}
                  </div>
                  <Field
                    {...register('catchPhrase')}
                    data-testid="input_catchPhrase"
                    defaultValue={currentUser.catchPhrase}
                    error={errors?.catchPhrase?.message}
                    name="catchPhrase"
                    type="text"
                    placeholder={t('catchPhrase') + ' *'}
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-row items-center">
                  <Field
                    {...register('anonymous')}
                    type="checkbox"
                    name="anonymous"
                    data-testid="input_anonymous"
                    defaultChecked={!!currentUser.anonymous}
                    onChange={(e: any) => {
                      setValue('anonymous', e.target.checked);
                    }}
                  />
                  <label className="ml-1" htmlFor="anonymous">
                    {t(`anonymous`)}
                  </label>
                </div>
                {watch('anonymous') && (
                  <div
                    id="chk_anonymous"
                    data-testid="chk_anonymous"
                    className={`italic text-gray-400 text-left`}>
                    {t('yourProfileWillBeAnonymousToOtherUsers')}
                  </div>
                )}
              </div>
            </div>

            {openAPIConfig.useOpenAIForGenerateAvatar === 'true' && useOpenAPIConfigLoading === false &&
              <>
                {/* Generate Avatar  */}
                <div className="flex flex-col gap-y-2 mt-2 w-full">
                  <div className="font-medium text-base">
                    {t('generateAvatar')}
                  </div>
                  <Field
                    data-testid="input_catchPhrase"
                    type="text"
                    placeholder={t('generateAvatar')}
                    onChange={handlePromptChange}
                    name={''} />
                  <div className="flex flex-row items-center">
                    <Button
                      data-testid="btn_ai"
                      id="btn_ai"
                      type="submit"
                      onClick={handleGenerateImage} disabled={(openAiAvatarList && openAiAvatarList.length >= parseInt(maxAvatarCount)) || !imagePrompt}
                      color="footerButton"
                    >
                      {openAiAvatarListFetching || openAiAvatarListLoading || createImageLoading ? `${t('generating')}...` : t('generateAvatarImage')}
                    </Button>
                  </div>
                  <div
                    id="chk_limitWarning"
                    className={`italic text-gray-400 text-left`}>
                    {`${t('note')}: ${t('youCanGenerateAnAIAvatarUpTo')} ${maxAvatarCount} ${t('only')}.`}
                  </div>
                </div>
              </>
            }
            {/* Choose AI Avatar */}
            {openAiAvatarList &&
              openAiAvatarList.length > 0 &&
              <div className="flex flex-col gap-y-3">
                <div className="font-medium text-base">
                  {t('chooseYourAIGeneratedAvatar')}
                </div>
                <div className="flex flex-row flex-wrap gap-3">
                  {
                    openAiAvatarList.map((avatarName: any, index: number) => {
                      const isSelected = avatarName === watch('avatar');

                      return (
                        <div
                          className="flex row gap-4"
                          key={`avatar${avatarName}`}>
                          <div
                            id={`img_avatar${avatarName}`}
                            data-testid={`img_avatar${avatarName}`}
                            className={`hover:scale-105 transition-all ease-in-out p-1 rounded-full cursor-pointer items-center ${isSelected ? 'bg-primary' : 'bg-none'
                              }`}
                            onClick={() => {
                              handleAvatarClick({
                                avatarName: avatarName
                              } as Avatar);
                            }}
                            key={`avatar-${avatarName}`}>
                            <AvatarImage
                              anonymous={true}
                              avatar={avatarName}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            }
            {/* Choose Avatar */}
            <div className="flex flex-col gap-y-3">
              <div className="font-medium text-base">
                {t('chooseYourAvatar')}
              </div>
              <div className="flex flex-row flex-wrap gap-3">
                {avatarList &&
                  avatarList.length > 0 &&
                  avatarList.map((avatar, index) => {
                    const isSelected = avatar.avatarName === watch('avatar');
                    const isCustomAvatar = watch('avatar') === 'customAvatar';

                    return (
                      <div
                        className="flex row gap-4"
                        key={`avatar${avatar.id}`}>
                        <div
                          id={`img_avatar${avatar.id}`}
                          data-testid={`img_avatar${avatar.id}`}
                          className={`hover:scale-105 transition-all ease-in-out p-1 rounded-full cursor-pointer items-center ${
                            isSelected ? 'bg-primary' : 'bg-none'
                          }`}
                          onClick={() => {
                            handleAvatarClick(avatar);
                          }}
                          key={`avatar-${avatar.id}`}>
                          <AvatarImage
                            anonymous={true}
                            avatar={avatar.avatarName}
                          />
                        </div>
                        {index === avatarList.length - 1 && (
                          <div
                            className="justify-center my-auto"
                            key={`custom-avatar-${avatar.id}`}>
                            <ImageCropperAvatar
                              ref={saveRef}
                              isSelected={!isSelected && isCustomAvatar}
                              selectAvatar={isSelected => {
                                handleAvatarClick({
                                  avatarName: 'customAvatar',
                                } as Avatar);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="self-center">
              <Button
                onClick={(e: any) => {
                  callSave(e);
                }}
                disabled={profileUpdateLoading}
                id="btn_saveAnonymous"
                className="mx-0 w-52" color="save">
                  
                {t('save')}
              </Button>
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};
