/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from "react"
import { useCheckUsersToDeleteQuery, useDeleteUserMutation, useGetCurrentUserDetailsQuery, useUpdateUserMutation, userApi } from "../Services/userApi"
import { useGetUserPreferenceQuery as getPreference, useUpdatePreferenceMutation } from '../Services/preferenceApi'
import { useGetAllCurrencyQuery as getCurrency, useGetAllLanguagesQuery } from '../Services/allOthersApi'
import { useGetReCaptchaQuery } from "../Services/envApi"
import { PaymentAccountActive } from "../models/user.model"
import { useForm } from "react-hook-form"
import { getCurrentUserDetail, getCurrentUser, changeCurrentUserDetail } from "../Services/userReducer"
import { useAppDispatch, useAppSelector } from "../Store/hooks"
import { CurrentlySupported } from "../models/currency.model"
import { settingsValidationSchema } from '../Utils/validation';
import { ledgerApi } from '../Services/ledgerApi';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { useGetBlueTickRequestByIdQuery } from '../Services/blueTickRequestApi';

export interface Initial {
  currency: string | undefined
  timezone: string | undefined
  timezoneUtc: string | undefined
  onlineList: boolean | undefined
  allowMessage: boolean | undefined
  allowVideo: boolean | undefined
  allowFreeConnection: boolean | undefined
  meetingLanguage: string[] | undefined 
  gstin : string | undefined
}

export const useSettingsHook = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch();
  const stateDispatch = useDispatch();

  const [recaptchaValue, setRecaptchaValue] = useState(false)
  const [val, setVal] = useState<Initial>();
  const [loadingData, setLoadingData] = useState(true);
  const [currencyError, setCurrencyError] = useState<'Not Available' | 'Not Supported'>()
  const [showRazorpay, setShowRazorpay] = useState<boolean>()
  const [stripeError, setStripeError] = useState(false);
  const [paymentGatewayLink, setPaymentGatewayLink] = useState<string>();
  const [disableField, setDisableField] = useState(false);
  const [accountActive, setAccountActive] = useState(false);
  const [language, setLanguage] = useState<string[]>([])

  const currentUserDetail = useAppSelector(getCurrentUserDetail);
  const currentUser = useAppSelector(getCurrentUser);

  const [updateUser] = useUpdateUserMutation()
  const [updatePreference, { error: updateError, isSuccess: updateSuccess, isLoading: settingsUpdateLoading }] = useUpdatePreferenceMutation()

  const { data: userData, isSuccess: userDataSuccess, refetch: currentUserDataRefetch, isFetching: currentUserDataRefetching } = useGetCurrentUserDetailsQuery()
  const { data: preference, isSuccess: preferenceSuccess, refetch: userPreferenceRefetch, isFetching: userPreferenceRefetching } = getPreference()
  const { data: blueTickRequest, isSuccess: blueTickRequestSuccess } = useGetBlueTickRequestByIdQuery(currentUser?.id)
  const { data: currencyData, isSuccess: currencySuccess } = getCurrency()
  const { data: siteKey } = useGetReCaptchaQuery();
  const { data: languageList, isSuccess: languageListSuccess } = useGetAllLanguagesQuery();
  const { data: userDelete, isSuccess: userDeleteSuccess, isFetching: userDeleteFetching} = useCheckUsersToDeleteQuery();
  const [ deleteUser] = useDeleteUserMutation();

  const {
    register, control, handleSubmit, setValue, reset, formState: { errors }
  } = useForm<Initial>({ resolver: yupResolver(settingsValidationSchema) });


  useEffect(() => {
    if (userData && preference) {
      setVal({
        currency: preference?.currency,
        timezone: preference?.timezone,
        timezoneUtc: preference?.timezoneUtc,
        gstin: preference?.gstin,
        meetingLanguage: preference?.meetingLanguage,

        onlineList: userData?.onlineList,
        allowMessage: userData?.allowMessage,
        allowVideo: userData?.allowVideo,
        allowFreeConnection: userData?.allowFreeConnection,
      });

      setLanguage(preference?.meetingLanguage ?? []);
      setShowRazorpay(preference?.currency === 'INR')
  
      if (currentUserDetail?.address && currentUser.paymentAccountActive === PaymentAccountActive.No) {
        const res = dispatch(ledgerApi.endpoints.checkAccount.initiate());

        res.then(val => {
          if (val.data?.msg) {
            const updatedUser = { ...currentUser };
            updatedUser.paymentAccountActive = PaymentAccountActive.Yes;
            updateUser(updatedUser);
          }
          if (!val.data?.msg) {
            if (!paymentGatewayLink && val?.data?.url) {
              setPaymentGatewayLink(val.data.url);
            }
          } else {
            if (!accountActive) {
              setAccountActive(true);
            }
            if (!disableField) {
              setDisableField(true);
            }
          }
        });
      } else if (currentUser.paymentAccountActive === PaymentAccountActive.Yes) {
        if (!disableField) {
          setDisableField(true);
        }
        if (!accountActive) {
          setAccountActive(true);
        }
      }
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferenceSuccess, currencySuccess, userDataSuccess, userPreferenceRefetching, currentUserDataRefetching, preference,currentUserDetail])

  useEffect(() => {
    if (preference?.currency && currencyData) {
      checkCurrency(preference?.currency)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preference, currencyData])

  useEffect(() => {
    reset(val)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val])
  
  if (userDataSuccess || preferenceSuccess || currencySuccess) {
    if (loadingData) {
      setLoadingData(false);
    }
  }
  const handleDeleteClick = () => {
    deleteUser(currentUser?.id);
  };
  const handleAddCategoryList = (item: any) => {
    const found = language?.find((lang: any) => lang === item);

    if (!found) {
      setLanguage([...language, item])
    }
  }

  const onSubmit = (values: Initial) => {

    let user = Object.assign({}, userData)
   if(accountActive){
    user.paymentAccountActive = PaymentAccountActive.Yes;
   }

    updatePreference({
      id: preference?.id,
      currency: values.currency || preference?.currency,
      timezone: values.timezone || preference?.timezone,
      timezoneUtc: values.timezoneUtc || preference?.timezoneUtc,
      language: preference?.language,
      showAudioGuide: preference?.showAudioGuide,
      gstin: values?.gstin,
      meetingLanguage: language
    });

   
    delete values.currency
    
    /*
        allowFreeConnection
        allowMessage
        allowVideo
        onlineList
    */
    if (user.allowFreeConnection !== values.allowFreeConnection
      || user.allowMessage !== values.allowMessage
      || user.allowVideo !== values.allowVideo
      || user.onlineList !== values.onlineList 
      || user.gstin !== values.gstin
    ) {
      updateUser({
        id: user.id,
        firstName: user.firstName,
        sub: user.sub,
        ...values
      });
    }


    
  }

  const handleRemoveLanguage = (item: any) => {
    setLanguage(language.filter(lang => lang !== item))
  }

  const onChange = (value: any) => {
    if (value) {
      if (!recaptchaValue) {
        setRecaptchaValue(true);
      }
    } else {
      if (recaptchaValue) {
        setRecaptchaValue(false);
      }
    }
  }

  const checkCurrency = (currency: string) => {
    const currencyObj = currencyData?.find(currencyObj => currencyObj.code === currency)
    
    if (currencyObj && preference) {
      if (currencyObj.currentlySupported === CurrentlySupported.No && !preference.actualCurrency) {
        setCurrencyError('Not Supported')
      } else if(preference.actualCurrency){
        setCurrencyError('Not Available')
      }
    } 
  }

  const createAccount = () => {
    const res = dispatch(ledgerApi.endpoints.createAccount.initiate());

    res.then(val => {
      if (val.data) {
        Auth.currentAuthenticatedUser().then((resultCurrent) => {
          const { identities, username, attributes, ...updatedUserDetail } = currentUserDetail as any;
          updatedUserDetail.address = val.data.id;
          resultCurrent.attributes = updatedUserDetail;

          Auth.updateUserAttributes(resultCurrent, resultCurrent.attributes).then((resultUpdate) => {
            stateDispatch(changeCurrentUserDetail(resultCurrent.attributes));
            const resCreateLink = dispatch(ledgerApi.endpoints.createLink.initiate());

            resCreateLink.then(valCreateLink => {
              if (valCreateLink.data) {
                window.location.href = valCreateLink.data.url;
              }
              
            });
          });
        });
      } else {
        if (!stripeError) {
          setStripeError(true);
        }
      }
    });
  }

  return {
    recaptchaValue,
    setVal,
    val,
    onChange,
    preference,
    currencyData,
    siteKey,
    loadingData,
    onSubmit,
    updateError,
    updateSuccess,
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    errors,
    currentUserDetail,
    currentUser,
    checkCurrency,
    currencyError,
    showRazorpay,
    stripeError,
    createAccount,
    link: paymentGatewayLink,
    disableField,
    accountActive,
    languageList,
    languageListSuccess,
    handleAddCategoryList,
    handleRemoveLanguage,
    language,
    userPreferenceRefetch,
    userPreferenceRefetching,
    currentUserDataRefetch,
    currentUserDataRefetching,
    settingsUpdateLoading,
    userDelete,
    handleDeleteClick,
    blueTickRequest
    
  };
}