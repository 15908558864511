/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import {yupResolver} from '@hookform/resolvers/yup';
import {addHours, differenceInMinutes} from 'date-fns';
import {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Meeting, MeetingType} from '../../models/meeting.model';
import {VideoType} from '../../models/meetingDetails.model';
import {useGetSettingValue} from '../../Services/settingReducer';
import {getPreference} from '../../Services/userReducer';
import {useAppDispatch, useAppSelector} from '../../Store/hooks';
import {formatDateToTimezone, getUTCDateTime} from '../../Utils/datetime';
import {meetingFormValidationSchema} from '../../Utils/validation';
import {useGetMeetingDetailsQuery} from '../../Services/meetingDetailsApi';
import {useGetByCUserCurrencyQuery} from '../../Services/setting.service';
import {mergeUniqueArray} from '../../Utils/functions';

import {
  useCreateMeetingMutation,
  useUpdateMeetingMutation,
  useGetMeetingQuery,
  useDeleteMeetingWithRefundMutation,
  useDeleteMeetingMutation,
  meetingApi,
} from '../../Services/meetingApi';
import {useGetAllLanguagesQuery} from '../../Services/allOthersApi';

interface IMeetingEditForm {
  fromDateTime?: string;
  toDateTime?: string;
  cost?: string;
  language?: string;
  minimumNoOfParticipants?: string;
  noOfParticipants?: string;
  maxDuration: number;
  minCost?: number;
  maximumParticipants: string;
  isFreeAudioMeeting: boolean;
  seatsAvailable: number;
  canEdit: boolean;
  meetingParticipantCount: number;
  defaultParticipantCount?: number;
  display_date?: string;
  display_time?: string;
  isMoviePremium?: boolean;
}

/**
 * Custom React hook for managing event editor functionality.
 *
 * @param {string} meetingDetailId - The ID of the meeting details.
 * @param {string} meetingId - The ID of the meeting.
 * @param {boolean} isNew - Whether the meeting is new or not.
 * @return {object} An object containing various properties and functions for managing the event editor.
 */

export const useEventEditorHooks = (
  meetingDetailId: string,
  meetingId: string,
  isNew: boolean,
  isMovieStream?: boolean,
) => {
  const EVENT_AUDIO_DURATION = useGetSettingValue('EVENT_AUDIO_DURATION');
  const EVENT_VIDEO_DURATION = useGetSettingValue('EVENT_VIDEO_DURATION');
  const MAX_PARTICIPANTS = parseInt(useGetSettingValue('MAX_PARTICIPANTS'));

  const [editMeeting, setEditMeeting] = useState<IMeetingEditForm>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [nextDate, setNextDate] = useState<boolean>(false);
  const [preferredLanguages, setPreferredLanguages] = useState<string[]>([]);
  const [date, setDate] = useState<string>();
  const preference = useAppSelector(getPreference);
  const dispatch = useAppDispatch();

  const {data: languages} = useGetAllLanguagesQuery();
  const {data: meeting, isLoading: isMeetingLoading} = useGetMeetingQuery(
    meetingId,
    {skip: !meetingId || isNew},
  );
  const {data: minCUserCost, isSuccess: minCUserCostSuccess} =
    useGetByCUserCurrencyQuery();

  const {data: meetingDetails, isLoading: isMeetingDetailsLoading} =
    useGetMeetingDetailsQuery(meetingDetailId ? meetingDetailId : '', {
      skip: meetingDetailId ? false : true,
    });

  const [
    createMeeting,
    {
      error: createError,
      isError: isCreateError,
      isSuccess: createSuccess,
      isLoading: isCreatorLoading,
    },
  ] = useCreateMeetingMutation();
  const [
    updateMeeting,
    {error: updateError, isError: isUpdateError, isSuccess: updateSuccess},
  ] = useUpdateMeetingMutation();
  const [
    deleteMeeting,
    {error: deleteError, isError: isDeletedError, isSuccess: deleteSuccess},
  ] = useDeleteMeetingMutation();

  const [
    deleteMeetingWithRefund,
    {
      error: deleteWithRefundError,
      isError: isDeletedWithRefundError,
      isSuccess: deleteWithRefundSuccess,
    },
  ] = useDeleteMeetingWithRefundMutation();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: {errors, isDirty, isValid, isSubmitting, isSubmitted},
    reset,
    watch,
  } = useForm<IMeetingEditForm>({
    mode: 'all',
    resolver: yupResolver(meetingFormValidationSchema),
  });

  const resetValue: IMeetingEditForm = {
    fromDateTime: getUTCDateTime(new Date()),
    toDateTime: addHours(new Date(), 1).toISOString(),
    cost: '',
    language: '',
    noOfParticipants: '',
    minimumNoOfParticipants: '',
    maxDuration:
      meetingDetails?.videoType === VideoType.Audio
        ? parseInt(EVENT_AUDIO_DURATION)
        : parseInt(EVENT_VIDEO_DURATION),
    minCost: minCUserCost, // TODO get from settings
    isFreeAudioMeeting: meetingDetails?.isFreeAudioMeeting || false,
    maximumParticipants: MAX_PARTICIPANTS.toString(),
    seatsAvailable: 0,
    canEdit: true,
    meetingParticipantCount: 0,
    defaultParticipantCount: 0,
  };

  const isTomorrow = (from: any, to: any) => {
    let f = new Date(from);
    let t = new Date(to);

    const _isNextDate =
      t.getDate() === f.getDate() + 1 ||
      (t.getMonth() === f.getMonth() + 1 && t.getDate() === 1);

    if (_isNextDate) {
      f.setDate(f.getDate() + 1);
      setNextDate(true);
      setDate(formatDateToTimezone(f.toString()));
    } else {
      setNextDate(false);
    }
  };

  useEffect(() => {
    if (!isNew && meeting && meetingDetails) {
      setErrorMessage(undefined);
      const editMeeting: IMeetingEditForm = {
        fromDateTime: meeting.user_fromDateTime,
        toDateTime: meeting.user_toDateTime,
        cost: meeting.cost.toString(),
        language: meeting.language,
        noOfParticipants: meeting.noOfParticipants + '',
        minimumNoOfParticipants: meeting.minimumNoOfParticipants + '',
        maxDuration:
          meetingDetails?.videoType === VideoType.Audio
            ? parseInt(EVENT_AUDIO_DURATION, 10)
            : parseInt(EVENT_VIDEO_DURATION),
        minCost: minCUserCost, // TODO get from settings
        isFreeAudioMeeting: meetingDetails?.isFreeAudioMeeting || false,
        maximumParticipants: MAX_PARTICIPANTS.toString(),
        seatsAvailable: meeting.seatsAvailable,
        canEdit:
          meeting?.meetingParticipant?.filter(x => x.isCohost === false)
            .length === 0,
        meetingParticipantCount: meeting?.meetingParticipant?.filter(
          x => x.isCohost === false,
        ).length,
        display_date: meeting?.fromDateTime,
        display_time: meeting?.toDateTime,
      };
      setEditMeeting(editMeeting);
      reset(editMeeting);
    } else if (isNew) {
      //removed SelectedDate from here
      setErrorMessage(undefined);
      setEditMeeting(resetValue);
      reset(resetValue);
    }
  }, [meeting, meetingDetails, isNew, minCUserCost]); //removed SelectedDate from here

  useEffect(() => {
    if (languages && preference) {
      setPreferredLanguages(
        mergeUniqueArray(preference.meetingLanguage, languages),
      );
    }
  }, [languages]);

  useEffect(() => {
    if (isCreateError) {
      setErrorMessage((createError as any)?.data?.message);
    }
  }, [isCreateError]);

  useEffect(() => {
    if (isUpdateError) {
      setErrorMessage((updateError as any)?.data?.message);
    }
  }, [isUpdateError]);

  useEffect(() => {
    if (isDeletedWithRefundError) {
      setErrorMessage((deleteWithRefundError as any)?.data?.message);
    }
  }, [isDeletedWithRefundError]);

  useEffect(() => {
    if (isDeletedError) {
      setErrorMessage((deleteError as any)?.data?.message);
    }
  }, [isDeletedError]);

  const onSubmit = (values: IMeetingEditForm) => {
    setSubmitted(true);

    const stream = getValues('isMoviePremium');

    if (meetingDetails) {
      const meetingObj: Meeting = isNew
        ? ({} as Meeting)
        : Object.assign({}, meeting);

      meetingObj.fromDateTime =
        values.fromDateTime || meeting?.fromDateTime || '';
      meetingObj.toDateTime = values.toDateTime || meeting?.toDateTime || '';
      meetingObj.cost = values.cost ? Number(values.cost) : 0;
      meetingObj.baseAmount = values.cost ? Number(values.cost) : 0;
      meetingObj.language = values.language || meeting?.language || '';
      meetingObj.noOfParticipants = Number(values.noOfParticipants);
      meetingObj.IsPremium = stream ? true : false;
      meetingObj.minimumNoOfParticipants = Number(
        values.minimumNoOfParticipants,
      );
      meetingObj.meetingDetails = meetingDetails;
      meetingObj.currency = preference?.currency;
      meetingObj.duration = differenceInMinutes(
        new Date(meetingObj.toDateTime),
        new Date(meetingObj.fromDateTime),
      ).toString();
      if (meetingObj?.id && editMeeting) {
        meetingObj.seatsAvailable =
          Number(values.noOfParticipants) -
          Number(editMeeting.noOfParticipants) +
          Number(editMeeting.seatsAvailable);
      } else {
        meetingObj.seatsAvailable = Number(values.noOfParticipants);
      }
      meetingObj.type = meetingDetails.videoType === VideoType.Stream ? MeetingType.MovieStream: MeetingType.Type1;
      meetingObj.isAutoDelete = meetingDetails?.autoDelete;

      if (meetingObj?.id) {
        updateMeeting(meetingObj).then((_res: any) => {
          dispatch(meetingApi.util.invalidateTags(['MeetingDetails']));
          if (submitted) {
            setSubmitted(false);
          }
        });
      } else {
        createMeeting(meetingObj)
          .then((_res: any) => {
            dispatch(meetingApi.util.invalidateTags(['MeetingDetails']));
            if (submitted) {
              setSubmitted(false);
            }
          })
          .catch((_err: any) => {
            setSubmitted(false);
          });
      }
    }
  };

  const handleDelete = (meeting: Meeting) => {
    deleteMeeting(meeting)
      .then((_res: any) => {
        dispatch(meetingApi.util.invalidateTags(['MeetingDetails']));
      })
  };

  return {
    createSuccess,
    updateSuccess,
    minCUserCostSuccess,
    minCUserCost,
    preferredLanguages,
    handleSubmit,
    onSubmit,
    errorMessage,
    editMeeting,
    meetingDetails,
    isUpdateError,
    isCreateError,
    createError,
    updateError,
    isCreatorLoading,
    setValue,
    register,
    trigger,
    errors,
    isValid,
    meeting,
    isNew,
    getValues,
    isDirty,
    isSubmitting,
    isSubmitted,
    watch,
    deleteWithRefundSuccess,
    deleteMeetingWithRefund,
    handleDelete,
    deleteSuccess,
    submitted,
    MAX_PARTICIPANTS,
    EVENT_AUDIO_DURATION,
    EVENT_VIDEO_DURATION,
    isTomorrow,
    nextDate,
    setNextDate,
    date,
    setDate,
    control,
    isMeetingLoading,
    isMeetingDetailsLoading,
  };
};
