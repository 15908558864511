/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownLeftAndUpRightToCenter } from '@fortawesome/free-solid-svg-icons'
import { ToolTip } from 'base/tooltip/tooltip'
import { useTranslation } from 'react-i18next'

interface CustomPipButtonProps {
  onClick: any
  isPipEnabled?: boolean
}

/**
 * Renders a custom PIP button component.
 *
 * @param {CustomPipButtonProps} props - The properties for the custom PIP button.
 * @param {Function} props.onClick - The click event handler for the button.
 * @param {boolean} [props.isPipEnabled] - Whether the PIP is enabled.
 * @return {JSX.Element} The rendered custom PIP button.
 */

export default function CustomPipButton(props: CustomPipButtonProps) {
  const { onClick, isPipEnabled } = props
  const { t } = useTranslation()

  return (
    <>
      <ToolTip tip={t('pip')} theme="transparent-dark">
        <button
          id="btn_pip"
          onClick={onClick}
          className={
            isPipEnabled
              ? ''
              : 'py-3 px-4 rounded-full border font-medium bg-gray-200 text-gray-700  hover:bg-gray-50 transition-all text-sm'
          }
        >
          <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
        </button>
      </ToolTip>
    </>
  )
}
