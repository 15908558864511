export enum BlueTickVerified {
    Complete = 'Complete',
    NotComplete = 'Not complete',
    Requested = 'Requested',
    Rejected = 'Rejected',
}

export interface BlueTickRequest {
    id: string;             
    document: string;       
    reason: string;         
    requestStatus: BlueTickVerified; 
    userId: string;        
    createdAt: string;      
    createdBy: string;      
    updatedAt: string;      
    updatedBy: string;     
    deletedAt?: string;     
}

export interface BlueTickRequestFormData {
    document: string;       
    reason: string;         
    requestStatus: BlueTickVerified; 
    userId: string;        
}
