/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { getToken } from '../Services/tokenReducer';
import { getCurrentUser } from '../Services/userReducer';
import { useAppSelector } from '../Store/hooks';
import InitialPage1 from './InitialPage1';
import beemgprimarywhitestroke from '../Assets/NewIcons/beeMG-logo(primary)-withstroke.svg';
import { Transition } from '@headlessui/react';
import { useGetIntroVideoURLForLargerScreensQuery, useGetIntroVideoURLForSmallerScreensQuery } from '../Services/envApi';
interface InitialPageContentProps {
  title: string;
  subtitle: string;
  content: string;
  button: string;
  onNext: () => void;
  currentStep: number;
  onStepClick: (index: number) => void;
  timer?: string;
}

const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

function InitialPageContent({
  title,
  subtitle,
  content,
  button,
  onNext,
  currentStep,
  onStepClick,
  timer,
}: InitialPageContentProps) {
  const { t } = useTranslation();

  return (
    <span className="flex items-center flex-col p-3 md:opacity-90 bg-none text-BeeMG-intro-black">
      <img src={beemgprimarywhitestroke} className="w-2/5 h-auto py-0 px-0 mb-6" />
      <span className="text-3xl font-bold mb-3">{title}</span>
      <p className="text-center text-lg max-w-md mb-1 font-bold">{subtitle}</p>
      {currentStep === 4 ? (
        <>
          <p className="text-center text-md px-5 max-w-md mb-1 font-semibold">{content}</p>
          <p className="text-center text-md mt-1 mb-6 font-semibold text-BeeMG-intro-orange">{timer}</p>
        </>
      ) : (
        <p className="text-center text-md px-3 max-w-md mb-6 font-semibold">{content}</p>
      )}
      <button
        onClick={onNext}
        className="mt-4 mb-2 px-16 py-2 bg-BeeMG-intro-black text-BeeMG-intro-yellow font-semibold rounded-md shadow-custom-orange z-10 transform -translate-y-2"
      >
        {button}
      </button>

      <div className="flex gap-x-2 mt-2">
        {Array.from({ length: 5 }, (_, index) => {
          return (
            <span
              key={index}
              onClick={() => onStepClick(index)}
              className={`w-1.5 h-1.5 rounded-full ${currentStep === index ? 'bg-BeeMG-intro-black' : 'bg-BeeMG-intro-orange'}`}
            ></span>
          );
        })}
      </div>
    </span>
  );
}

function Sign1() {
  const [showVideo, setShowVideo] = useState(true);
  const [bgColor, setBgColor] = useState('bg-white');
  const [currentStep, setCurrentStep] = useState(0);
  const [timer, setTimer] = useState<number>(10);

  const mobileVideoRef = useRef(null)
  const largeScreenVideoRef = useRef(null)
  const navigate = useNavigate();

  const currentUser = useAppSelector(getCurrentUser);
  const token = useAppSelector(getToken);
  const { pathname } = useLocation();

  const { data: introVideoForSmallerScreens } = useGetIntroVideoURLForSmallerScreensQuery();
  const { data: introVideoForLargerScreens } = useGetIntroVideoURLForLargerScreensQuery();
  const handleVideoEnd = () => {
    setShowVideo(false);
    setBgColor('bg-BeeMG-intro-yellow');
  };

  const handleVideoProgress = (e: React.SyntheticEvent<HTMLVideoElement>) => {
    const video = e.currentTarget;
    if (video.currentTime >= video.duration / 2) {
      setBgColor('bg-BeeMG-intro-yellow');
    }
  };

  async function handleRedirect() {
    // Clear localStorage
    window.localStorage.clear();
  
    // Conditional navigation based on user and device type
    if (currentUser && token) {
      navigate('s/home');
    } else {
      if (isMobile) {
        // Check for Android or iPhone devices
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.includes("android")) {
          // Navigate to Play Store link for Android devices
          window.location.href = "https://play.google.com/store/apps/details?id=com.prod.beemg";
        } else if (userAgent.includes("iphone") || userAgent.includes("ipad")) {
          // Navigate to App Store link for iPhone devices
          window.location.href = "https://apps.apple.com/us/app/beemg/id6737306262";
        } else {
          // Default fallback navigation for other devices
          navigate('/termsAndConditions');
        }
      } else {
        // Default navigation for non-mobile devices
        navigate('/termsAndConditions');
      }
    }
  }
  
  
  const steps = [
    { title: 'Creators', subtitle: 'Monetize your passion', content: 'Are you ready to turn your talent into earnings? Host live events, engage in real-time, and build your community like never before.', bgColor: 'yellow', button: 'Next', bgImage: 'group4' },
    { title: 'Creators', subtitle: 'Dive into a world of possibilities', content: 'BeeMG- the ultimate hub to craft, manage and monetize your live events effortlessly.', bgColor: 'yellow', button: 'Next', bgImage: 'group4' },
    { title: 'Collaborators', subtitle: 'Connect & Grow', content: 'Discover the world of collaboration with BeeMG - the ultimate hub to connect with people like you!', bgColor: 'white', button: 'Next', bgImage: 'group5' },
    { title: 'Collaborators', subtitle: 'Start collaborating', content: 'Start a conversation and bring your creative ideas to life! Click for Registration and let’s get started!', bgColor: 'white', button: `Let's begin`, bgImage: 'group5' },
    { title: '', subtitle: '', content: 'You will be redirected to our BeeMG Mobile App for a full uncut experience.', timer: `Redirecting in 00:${`${timer.toString().padStart(2, '0')}`} seconds`, bgColor: 'yellow', button: 'Redirect now', bgImage: '' },
  ];

  useEffect(() => {
    if (mobileVideoRef.current) {
      (mobileVideoRef.current as any).muted = true
        ; (mobileVideoRef.current as any).play()
    }
    if (largeScreenVideoRef.current) {
      (largeScreenVideoRef.current as any).muted = true
        ; (largeScreenVideoRef.current as any).play()
    }
  }, [])

  useEffect(() => {
    if (currentStep === 4 && isMobile) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(countdown);
            handleRedirect(); // Trigger redirect after countdown ends
            return prevTimer;
          }
          return prevTimer - 1;
        });
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [currentStep, isMobile, navigate, currentUser]);

  const handleNext = () => {
    if (currentStep === 3) {
      if (isMobile) {
        setCurrentStep(4);
        setTimer(10);
      } else {
        handleRedirect();
      }
    } else if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleStepClick = (index: number) => {
    setCurrentStep(index);
  };

  useEffect(() => {
    if (token && currentUser && pathname !== '/s/home') {
      navigate('/s/home');
    }
  }, [token, currentUser, pathname, navigate]);

  return (
    <div className={`${bgColor} relative w-screen h-screen`}>
      <Transition
        show={showVideo}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {/* Container for video to ensure full-screen display */}
        <div className="w-screen h-screen">
          {/* Mobile video for small screens */}
          <video
            className="block sm:hidden md:hidden w-full h-full object-cover"
            onEnded={handleVideoEnd}
            onTimeUpdate={handleVideoProgress}
            autoPlay
            playsInline
            muted
            ref={mobileVideoRef}
          >
            {introVideoForSmallerScreens ? <source src={introVideoForSmallerScreens} type="video/mp4" /> : ""}
          </video>

          {/* Tablet and larger screens */}
          <video
            className="hidden sm:block md:block w-full h-full object-cover"
            onEnded={handleVideoEnd}
            onTimeUpdate={handleVideoProgress}
            autoPlay
            playsInline
            muted
            ref={largeScreenVideoRef}
          >
            {introVideoForLargerScreens ? <source src={introVideoForLargerScreens} type="video/mp4" /> : ""}
          </video>
        </div>
      </Transition>

      {!showVideo && (
        <Transition
          appear
          show={!showVideo}
          enter="transition-opacity duration-30"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          <InitialPage1 bgColor={steps[currentStep].bgColor} bgImage={steps[currentStep].bgImage}>
            <span className="flex justify-center items-center">
              <InitialPageContent
                title={steps[currentStep].title}
                subtitle={steps[currentStep].subtitle}
                content={steps[currentStep].content}
                onNext={handleNext}
                button={steps[currentStep].button}
                currentStep={currentStep}
                onStepClick={handleStepClick}
                timer={steps[currentStep].timer}
              />
            </span>
          </InitialPage1>
        </Transition>
      )}
    </div>
  )
}

export default Sign1
