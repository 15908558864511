/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { createRef, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import NoSession from '../Components/NoSession'
import { Accordion, AccordionBorder, AccordionItem, AccordionPanel } from '../Components/base/accordion/accordion'
import Button from '../Components/base/button/button'
import Icon from '../Components/base/icon/icon'
import NewIcon from '../Components/base/icon/newIcons'
import Loading from '../Components/base/loading/loading'
import { useMeetingJoinHooks } from '../Hooks/AttendingHooks'
import useMyEventsPaginationHook from '../Hooks/MyEvents/MyEventsPaginationHook'
import PageAndSortHook from '../Hooks/PageAndSortHook'
import { useGetMeetingDetailsQuery } from '../Services/meetingDetailsApi'
import { getMyEventById } from '../Services/myEventsReducer'
import { useCheckEventCreationPolicyQuery } from '../Services/userPolicyAgreementsApi'
import { getCurrentUser, getCurrentUserDetail } from '../Services/userReducer'
import { useAppDispatch, useAppSelector } from '../Store/hooks'
import { Meeting, MeetingType } from '../models/meeting.model'
import { MeetingDetails } from '../models/meetingDetails.model'
import { PaymentAccountActive } from '../models/user.model'
import CardImagePlaceHolder from './EventsCard/CardImagePlaceHolder'
import CardTitle from './EventsCard/CardTitle'
import { DisplayEventTypeIcons } from './EventsCard/DisplayEventTypeIcons'
import { EventCard } from './EventsCard/EventCard'
import TextLink from './EventsCard/TextLink'
import { useHandleMeetingNavigation } from './LiveKit/hooks/livekitWebOnlyHooks'
import Pagination from './Pagination'
import { useGetFutureMeetingsByMeetingDetailsIdQuery } from '../Services/meetingApi'
import { Menu, Transition } from '@headlessui/react'
import { DisplayFormattedDateAndTime } from '../Components/formattedDateAndTime'
import { DelayDisplay } from '../Components/DelayDisplay'
import FilterIcon  from '../Assets/NewIcons/beeMG-icons-filter-24.svg'
import { userApi } from '../Services/userApi'


const style = {
  menu: `absolute flex flex-col w-[12.6rem] p-2 py-3 gap-1 rounded-lg border border-gray-200 left-10 bg-white`,
  sortItem: "block rounded-md px-2 py-1 w-full text-left text-base font-normal whitespace-nowrap  hover:bg-gray-200 cursor-pointer",
  item: ` flex flex-row w-full flex-nowrap gap-1 text-left`,
  myEvents: `block lg:w-11/12 md:px-3`,
  sm_myEvents: `block px-3`,
  schedule: `font-medium text-black mb-1 text-sm min-w-schedule pt-1`,
  sm_schedule: `font-medium text-black text-xs min-w-schedule pt-1`,
  editEventText: `flex md:flex-col sm:gap-2 xs:gap-2 md:gap-0 text-center items-center justify-center min-w-cardRightText md:w-40 pl-14`,
  active: `text-black px-2 font-medium`,
  eventTabs: `flex whitespace-pre text-gray-400 cursor-pointer font-normal md:text-lg text-sm hover:text-black hover:font-medium px-1 sm:px-2 border-2 border-transparent hover:border-2 hover:bg-gray-200 rounded-lg`,
  addNewEvent: `bg-transparent text-black font-medium cursor-pointer text-sm md:text-lg hover:underline whitespace-nowrap`,
}

/**
 * MyEvents component renders a list of events the user has created.
 * @returns {React.ReactElement} MyEvents component
 */
function MyEvents() {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation();
  const accordionRef = useRef();
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(getCurrentUser);
  const currentUserDetails = useAppSelector(getCurrentUserDetail)
  const { meetingDetailId } = useParams()
  const [searchParams] = useSearchParams();
  const searchStr = window.location.search.substring(1);
  const [currentFilter, setCurrentFilter] = useState<string>()
  const [paymentActive, setPaymentActive] = useState<boolean>(false)

  const [isScheduleLinkActive, setScheduleLinkActive] = useState<boolean>(false);

  const { scheduleEvents,
    isPageLoading,
    pagination,
    sortingList, setPagination, setSortingString, setSortingList, pageData } = useMyEventsPaginationHook()

  useEffect(() => {
    if (pathname && pathname.includes('/schedule')) {
      setScheduleLinkActive(true)
    } else {
      setScheduleLinkActive(false)
    }
  }, [pathname])

  useEffect(() => {
    if (currentUser.paymentAccountActive === PaymentAccountActive.Yes) {
      dispatch(userApi.endpoints.getUserDetail.initiate(currentUser.sub)).then((value: any) => {
        setPaymentActive(true)
      })
    }
  }, [currentUserDetails, currentUser])

  useEffect(() => {
    const pageIndex = searchParams.get('page') ? parseInt(searchParams.get('page') as string) : 1;
    if (pageIndex) {
      setPagination({ ...pagination.pagination, pageIndex: pageIndex - 1 });
    }

    let sort = searchParams.get('sort')
    if (sort) {
      setSortingList({
        ...{ title: false, upcomingMeeting: false, updatedAt: false },
        [sort]: true
      })
      setSortingString(sort)
    }
  }, [searchParams]);

  const { data: checkEventPolicy } = useCheckEventCreationPolicyQuery();

  let refs: any = []

  /**
   * Scroll to the meeting details div with the given meetingDetailId, minus an offset to account for the header.
   * @param meetingDetailId The id of the meeting details to scroll to.
   */
  const scrollToTargetAdjusted = (meetingDetailId: string) => {
    const element = document.getElementById('div_' + meetingDetailId);
    const headerOffset = 98;
    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }


  useEffect(() => {
    if (meetingDetailId) {
      if (accordionRef.current) {
        (accordionRef.current as any).setSelectedToggle(meetingDetailId)
      }

      if (refs && meetingDetailId) {
        setTimeout(() => {
          if (refs[meetingDetailId]?.current) {
            refs[meetingDetailId].current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
          scrollToTargetAdjusted(meetingDetailId)
        }, 1000);
      }
    }
    else {
      if (accordionRef.current) {
        if((accordionRef.current as any).getSelectedToggle()){
          (accordionRef.current as any).setSelectedToggle('')
        }
      }
    }
  }, [meetingDetailId, refs])

  /**
   * Closes the accordion panel for the given meeting detail id if the meeting
   * detail id matches the current meetingDetailId in the URL.
   * @param {string} accMeetingDetailId The meeting detail id to compare with the
   * current meetingDetailId.
   */
  const closeAccordion = (accMeetingDetailId: string) => {
    if (meetingDetailId === accMeetingDetailId) {
      // navigate(`/s/events/my-events`)
    }
  }

  if (isPageLoading) {
    return <Loading />
  }

  if (pageData?.length === 0 || pageData?.length === undefined && scheduleEvents?.length === 0) {
    return <DisplayMyEventHeaderForNoSession />
  }

  refs = scheduleEvents.reduce((acc: any, value: any) => {
    acc[value.id] = createRef();
    return acc;
  }, {});

  /**
   * Updates the URL query parameter to sort the list of events accordingly.
   * @param {string} sorting A string indicating the sorting criteria.
   * One of "title", "upcomingMeeting", or "updatedAt".
   */
  const changeSorting = (sorting: string) => {
    const page = searchParams.has('page')

    if (page) {
      navigate(`/s/events/my-events?page=1?&sort=${sorting}`)
    } else {
      navigate(`/s/events/my-events?sort=${sorting}`)
    }
  }

  // comment lines need for future development

  return (
    <div className={style.myEvents}>
     <DisplayMyEventsHeader />
      {!isPageLoading && (
        <>
          <Accordion ref={accordionRef}>
            {scheduleEvents?.map((meetingDetail: any) => (
              <div
                id={'div_' + meetingDetail.id}
                ref={refs[meetingDetail.id]}
                key={'div_' + meetingDetail.id}
                onClick={() => closeAccordion(meetingDetail.id)}
                className="mb-1">
                <AccordionBorder
                  id={meetingDetail.id}
                  keyId={meetingDetail.id}
                  key={meetingDetail.id}>
                  <AccordionItem color="card" id={meetingDetail.id}>
                    <EventCard
                      link={`/s/events/my-events/${meetingDetail.id}/eventDetails?${searchStr}`}>
                      <EventCard.Image>
                        <MyEventCardImage meetingDetailsId={meetingDetail.id} />
                      </EventCard.Image>
                      <EventCard.Title>
                        <DisplayEventTitle meetingDetail={meetingDetail} />
                      </EventCard.Title>
                      <EventCard.Icons>
                        <DisplayEventTypeIcons meetingDetail={meetingDetail} />
                      </EventCard.Icons>
                      <EventCard.Right>
                        {meetingDetail.meetings?.length > 0 && (
                          <div className="flex flex-col text-sm md:text-base ">
                            {meetingDetail.meetings.map(
                              (meeting: any, index: number) =>
                                index < 3 && (
                                  <DisplayUpcomingEventTime
                                    meeting={meeting}
                                    key={meeting.id}
                                  />
                                ),
                            )}
                          </div>
                        )}
                      </EventCard.Right>
                      <EventCard.Actions>
                        <DisplayViewLink meetingDetail={meetingDetail} />
                      </EventCard.Actions>
                    </EventCard>
                  </AccordionItem>
                  <AccordionPanel id={meetingDetail.id}>
                    {meetingDetail.id === meetingDetailId && (
                      <>
                        <div className="flex justify-center md:justify-center md:space-x-5 my-3 pb-4 sm:pl-3 md:pl-10">
                          <div className={style.eventTabs}>
                            <NavLink
                              to={`/s/events/my-events/${meetingDetailId}/eventDetails?${searchStr}`}
                              data-testid="tab_details"
                              id="tab_details"
                              className={({isActive}: any) =>
                                isActive ? style.active : ''
                              }>
                              {t('summary')}
                            </NavLink>
                          </div>
                          <div className={style.eventTabs}>
                            <NavLink
                              data-testid="tab_schedule"
                              id={`tab_schedule${meetingDetailId}`}
                              to={`/s/events/my-events/${meetingDetailId}/schedule/new/edit?${searchStr}`}
                              className={
                                isScheduleLinkActive ? style.active : ''
                              }>
                              {t('sessions')}
                            </NavLink>
                          </div>
                          <div className={style.eventTabs}>
                            <NavLink
                              to={`/s/events/my-events/${meetingDetailId}/pastEvents?${searchStr}`}
                              id={`tab_pastEvents${meetingDetailId}`}
                              data-testid="tab_pastEvents"
                              className={({isActive}: any) =>
                                isActive ? style.active : ''
                              }>
                              {t('history')}
                            </NavLink>
                          </div>
                          {/* <div className={style.eventTabs}>
                        <NavLink
                          to={`/s/events/my-events/${meetingDetailId}/participants`}
                          id="tab_participants"
                          className={({isActive}: any) => isActive && style.active }>
                          {t('participants')}
                        </NavLink>
                      </div>
                      <div className={style.eventTabs}>
                        <NavLink
                          to={`/s/events/my-events/${meetingDetailId}/reviews`}
                          id="tab_reviews"
                          className={({isActive}: any) => isActive && style.active }>
                          {t('reviews')}
                        </NavLink>
                      </div> */}
                        </div>

                        <div className="lg:px-8 px-2 min-h-review">
                          <Outlet />
                        </div>
                      </>
                    )}
                  </AccordionPanel>
                </AccordionBorder>
              </div>
            ))}
          </Accordion>
        </>
      )}
      {isPageLoading && (
        <div className="h-[60vh]">
          <Loading />
        </div>
      )}
      <Pagination pagination={pagination} isPageLoading={isPageLoading} />
    </div>
  )

  /**
   * DisplayEventTitle is a React component that displays the title of an event.
   * @param {{ meetingDetail: MeetingDetails }} props - The meeting details object.
   * @returns {JSX.Element} The JSX element of the event title.
   */
  function DisplayEventTitle({ meetingDetail }: { meetingDetail: MeetingDetails }) {

    const {
      data: meetingDetailsData
    } = useGetMeetingDetailsQuery(meetingDetailId ? meetingDetailId : '', { skip: meetingDetailId ? false : true })

    const truncatedTitle =
      meetingDetailsData?.id === meetingDetail.id
        ? (meetingDetailsData?.title ?? meetingDetail.title).length > 25
          ? (meetingDetailsData?.title ?? meetingDetail.title).substring(
              0,
              25,
            ) + '...'
          : meetingDetailsData?.title ?? meetingDetail.title
        : meetingDetail.title.length > 25
        ? meetingDetail.title.substring(0, 25) + '...'
        : meetingDetail.title
    return (
      <>
        <CardTitle keyId={meetingDetail.id} meetingTitle={truncatedTitle} />{' '}
      </>
    )
}


/**
 * A React component that displays the header of the My Events page.
 * The header component contains a button to create a new event, a dropdown menu to filter events by name, latest, or upcoming, and a message to activate the payment account to create paid events.
 * @returns {JSX.Element} The JSX element of the My Events header.
 */
  function DisplayMyEventsHeader() {
    return (
      <>
        {!paymentActive && 
          <>
            <div className='h-3' />
            <div className="flex relative flex-col items-center justify-center">
              <span className="bg-red-600 absolute w-screen text-white py-1 mt-3 px-10 mx-auto text-sm text-center">
                {t('activateYourPaymentAccountToCreatePaidEvents')}.{' '}
                <Link id="lnk_paymentActivate" className="underline" to="/s/settings">{t('clickHereToActivate')}</Link>
              </span>
            </div>
            <div className='h-10' />
          </>
        }
        <div className="flex items-center justify-center mb-6 mt-2 relative">
          {checkEventPolicy && (
            <Link to={`/s/events/newEvent`}>
              <div className='flex flex-row items-center gap-x-2'>
                <NewIcon icon='CREATEEVENT' />
                <div
                  id="btn_addNewEvent" data-testid="btn_addNewEvent"
                  className={style.addNewEvent}
                >
                  {t('createEvent')}
                </div>
              </div>
            </Link>
          )}
          {!checkEventPolicy && (
            <Link to={'/s/eventPolicy'}>
              <div className='flex flex-row items-center gap-x-2'>
                <Icon icon='EDIT' />
                <div
                  id="btn_addNewEvent" data-testid="btn_addNewEvent"
                  className={style.addNewEvent}
                >
                  {t('createEvent')}
                </div>
              </div>
            </Link>
          )}
          <div className='absolute top-0 bottom-0 right-2'>

            <Menu>
              <div className="relative">
                <Menu.Button
                  id="dropMenu_lang_myEvents"
                  data-testid="btn_eventFilter"
                  key={'attendingEvents'}
                  className=" text-gray-400 font-normal flex items-center cursor-pointer">
                  <p data-testid='txt_currentFilter' className="px-2">{t(currentFilter ?? '')}</p> &nbsp; <img src={FilterIcon} className="w-6 h-6" alt="Filter Icon"/>
                </Menu.Button>

                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                ></Transition>

                <Menu.Items className={style.menu}>
                  <Menu.Item>
                    <div className={style.sortItem}
                      onClick={() => { changeSorting('updatedAt'); setCurrentFilter('latestEvents') }}>
                      <span id="filter_latestEvent" data-testid="filter_latestEvent" className={style.item}>{sortingList['updatedAt'] && <Icon icon="TICK_GREEN" />} {t('latestEvents')}</span>
                    </div>
                  </Menu.Item>
                  <Menu.Item>
                    <div className={style.sortItem}
                      onClick={() => { changeSorting('upcomingMeeting'); setCurrentFilter('upcomingEvents') }}>
                      <span id="filter_upcomingMeeting" data-testid='filter_upcomingMeeting' className={style.item}>{sortingList['upcomingMeeting'] && <Icon icon="TICK_GREEN" />} {t('upcomingEvents')} </span>
                    </div>
                  </Menu.Item>
                  <Menu.Item>
                    <div className={style.sortItem}
                      onClick={() => { changeSorting('title'); setCurrentFilter('eventName') }}>
                      <span id="filter_eventName" data-testid='filter_eventName' className={style.item}>{sortingList['title'] && <Icon icon="TICK_GREEN" />}  {t('eventName')} </span>
                    </div>
                  </Menu.Item>
                </Menu.Items>
              </div>
            </Menu>
          </div>
        </div>
      </>
    )
  }


  /**
   * A React component that displays the header of the My Events page when there are no events.
   * The header component contains a button to create a new event, a dropdown menu to filter events by name, latest, or upcoming, and a message to activate the payment account to create paid events.
   * @returns {JSX.Element} The JSX element of the My Events header for no session.
   */
  function DisplayMyEventHeaderForNoSession() {
    return (
      <>
        <div className={style.myEvents}>
        {!paymentActive &&
        <DelayDisplay delayTime={1000}>
          <>
            <div className='h-3' />
            <div className="flex relative flex-col items-center justify-center">
              <span id="txt_paymentActivate" data-testid="txt_paymentActivate" className="bg-red-600 absolute w-screen text-white py-1 mt-3 px-10 mx-auto text-sm text-center">
                {t('activateYourPaymentAccountToCreatePaidEvents')}.{' '}
                <Link id="lnk_paymentActivate" data-testid="lnk_paymentActivate" className="underline" to="/s/settings"><small>{t('clickHereToActivate')}</small></Link>
              </span>
            </div>
            <div className='h-10' />
          </>
          </DelayDisplay>
          }
          <div className="flex items-center justify-center mb-4 mt-4 relative">
            {checkEventPolicy && (
              <Link to={'/s/events/newEvent'}>
                <div className='flex flex-row items-center gap-x-2'>
                  <NewIcon icon='CREATEEVENT' />

                  <div
                    id="btn_addNewEvent" data-testid="btn_addNewEvent"
                    className={style.addNewEvent}
                  >
                    {t('createEvent')}
                  </div>
                </div>
              </Link>
            )}
            {!checkEventPolicy && (
              <Link to={'/s/eventPolicy'}>
                <div className='flex flex-row items-center gap-x-2'>
                  <Icon icon='EDIT' />
                  <div
                    id="btn_addNewEvent" data-testid="btn_addNewEvent"
                    className={style.addNewEvent}
                  >
                    {t('createEvent')}
                  </div>
                </div>
              </Link>
            )}
            <div data-testid='iconEventFilter' className='absolute bottom-0 right-2'>
              <NewIcon icon="FILTER" size="medium" height="medium" />
            </div>
          </div>
          <NoSession type="events" className="h-[60vh] items-center justify-center" />
        </div>
      </>
    )
  }

  /**
   * A React component that displays the upcoming event time of a meeting.
   * The component uses the useMeetingJoinHooks to get the join time, rejoin, and ended time of the meeting.
   * The component renders a span with the time of the meeting and a button to join the meeting if the meeting has not ended or if the user has rejoined the meeting.
   * @param {Meeting} meeting - The meeting object.
   * @returns {JSX.Element} The JSX element of the component.
   */
  function DisplayUpcomingEventTime({ meeting }: { meeting: Meeting }) {
    const { joinTime, rejoin, endedTime } = useMeetingJoinHooks({ meeting })
    const { joinMeeting } = useHandleMeetingNavigation()
    const { refetch: futureMeetingRefetch } = useGetFutureMeetingsByMeetingDetailsIdQuery(meetingDetailId ? meetingDetailId : '', { skip: !meetingDetailId })

    useEffect(() => {
      if (endedTime) {
        futureMeetingRefetch()
      }
    }, [endedTime])


    return (
      <div className="flex flex-row">
        {!endedTime && (
          <span data-testid='txt_upcomingEventTime'
            {...(!joinTime &&
              !rejoin && {
              className:
                ' flex flex-col text-xs text-justify-start items-center md:mt-1',
            })}
            {...((joinTime || rejoin) && {
              className:
                ' flex flex-col text-xs text-justify-start items-center my-auto',
            })}>
            <DisplayFormattedDateAndTime
              displayFromTime={meeting.fromDateTime}
              displayToTime={meeting.toDateTime}
              displayDate={meeting.fromDateTime}
            />
          </span>
        )}
        {joinTime && !rejoin && (meeting.type === MeetingType.Type1 || meeting.type === MeetingType.Type2) ? (
          <Button
            id="btn_startMeet"
            size="s"
            rounded
            color="join"
            onClick={e => {
              e.stopPropagation()
              joinMeeting(meeting.id)
            }}>
            {t('startMeeting')}
          </Button>
        ) : meeting.type === MeetingType.MovieStream && (
          <Button
            id="btn_startMeet"
            size="s"
            rounded
            color="join"
            onClick={e => {
              e.stopPropagation()
              navigate(`/s/hostPage/${meeting.id}`)
            }}>
            {t('startLiveStream')}
          </Button>
        )}

        {joinTime && rejoin && (
          <Button
            id="btn_rejoin"
            size="s"
            rounded
            color="join"
            onClick={e => {
              e.stopPropagation()
              joinMeeting(meeting.id)
            }}>
            {t('rejoin')}
          </Button>
        )}
      </div>
    )
  }

  /**
   * Renders a view link for a meeting detail.
   * @param meetingDetail the meeting detail
   * @returns a JSX element containing the view link
   * If the meeting detail has meetings, it will render a link to the meeting,
   * otherwise it will render a link to the schedule page to add a new session.
   */
  function DisplayViewLink({ meetingDetail }: { meetingDetail: MeetingDetails }) {
    return <div className={style.editEventText}>
      <NewIcon icon='CALENDER' size='medium' height='medium' stroke='#545454' />
      {meetingDetail.meetings?.length > 0 ?
        <div id={`viewEvent${meetingDetail.id}`} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
          <TextLink link={`/s/meeting/${meetingDetail.id}`} type="view">
            <div data-testid='btn_viewEvent' className='normal-case font-normal'>
              {t('viewEvent')}
            </div>
          </TextLink>
        </div>
        :
        <div id={`viewEvent${meetingDetail.id}`} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
          <TextLink link={`/s/events/my-events/${meetingDetail.id}/schedule/new/edit${PageAndSortHook()}`} type="view">
            <div data-testid='btn_scheduleSession' className='normal-case font-normal'>
              {t('schedule')}<br /> {t('sessions')}
            </div>
          </TextLink> </div>}
    </div>
  }

}
/**
 * A component that renders an image for a meeting detail on the MyEvents page.
 *
 * @param {{ meetingDetailsId: string }} props - The component props.
 * @param {string} props.meetingDetailsId - The ID of the meeting detail.
 *
 * @returns {JSX.Element} The rendered image.
 */
const MyEventCardImage = ({ meetingDetailsId }: { meetingDetailsId: string }) => {
  const meetingDetail = useAppSelector((state) => getMyEventById(state, meetingDetailsId))

  return (<CardImagePlaceHolder images={meetingDetail?.image} />)
}

export default MyEvents