/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { Combobox, Transition } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import useToggle from '../../Components/_utils/useToggle'
import Button from '../../Components/base/button/button'
import Field from '../../Components/base/field/field'
import Icon from '../../Components/base/icon/icon'
import Loading from '../../Components/base/loading/loading'
import { Modal, ModalBody, ModalCloseButton, ModalFooter, ModalHeader } from '../../Components/base/modal/modal'
import { ToolTip } from '../../Components/base/tooltip/tooltip'
import { toastError, toastSuccess } from '../../Components/toast'
import { useEventEditorHooks } from '../../Hooks/MyEvents/EventEditorHooks'
import PageAndSortHook from '../../Hooks/PageAndSortHook'
import { getPreference } from '../../Services/userReducer'
import { tr } from '../../Utils/helper'
import { meetingDetailsDeleteFormValidationSchema } from '../../Utils/validation'
import { VideoType } from '../../models/meetingDetails.model'
import { MeetingTimer } from '../MeetingRequest/MeetingTimer'
import { useCurrencyHook } from '../../Hooks/CurrencyHooks'
import useMyEventsPaginationHook from '../../Hooks/MyEvents/MyEventsPaginationHook'
import {useGetTaxesQuery} from '../../Services/taxApi'
import { addHours } from 'date-fns'
import { useGetPlatformFeesQuery } from '../../Services/platformFeeApi'
import { platform } from 'process'
import { Taxes } from '../../models/taxes.model'
import { calculateTaxAmount } from '../../Components/base/carousel/whirligig/utils'
import { FreeEvent } from '../AttendingEvents/AttendingEventsFreeEvent'


/**
 * The EventEditor component is the form used to create or edit an event. It shows the fields
 * for the event details, the participants, and the prices. It also shows the meeting timer and
 * the confirmation dialog for deleting the meeting.
 *
 * @param meetingId - The meeting id.
 * @param meetingDetailId - The meeting detail id.
 * @param meeting - The meeting object.
 * @param isNew - Whether the meeting is new or not.
 * @param editMeeting - The meeting object that is being edited.
 * @param pageAndSort - The page and sort object.
 * @param preferredLanguages - The preferred languages.
 * @param preference - The user preference.
 * @param navigate - The navigate function.
 * @param useToggle - The toggle function.
 * @param useEventEditorHooks - The event editor hooks.
 * @param tr - The translate function.
 * @param toastSuccess - The toast success function.
 * @param toastError - The toast error function.
 * @param useCurrencyHook - The currency hook.
 */
function EventEditor() {
  const { t } = useTranslation()

  const { meetingId, meetingDetailId } = useParams()
  const navigate = useNavigate();
  const preference = useSelector(getPreference)
  const { toggle: deleteToggle, isOpen: deleteIsOpen } = useToggle();
  const { toggle: saveToggle, isOpen: saveIsOpen } = useToggle();
  const meetingTimerRef = useRef<any>();
  let pageAndSort = PageAndSortHook();
  const [participantEdit, setParticipantEdit] = useState(false)
  const { formatCurrencySymbol } = useCurrencyHook()

  const { data: rawTaxesData, isLoading: taxesLoading } = useGetTaxesQuery();
  const [taxesData, setTaxesData] = useState<Taxes[]>([]);
  const [taxPercentages, setTaxPercentages] = useState<number[]>([]); // Specify as number array

  useEffect(() => {
    if (rawTaxesData) {
      const taxesArray = Array.isArray(rawTaxesData) ? rawTaxesData : [rawTaxesData];
      const filteredTaxes = taxesArray.filter(tax => tax.currency === preference.currency);
      setTaxesData(filteredTaxes);
    }
  }, [rawTaxesData]);

  useEffect(() => {
    setTaxPercentages(taxesData.map((tax) => tax.percentage));
  }, [taxesData]);


  const { data: platformFeeData, isLoading: platformFeeLoading } = useGetPlatformFeesQuery() as any;
  const [platformFeePercentages, setPlatformFeePercentages] = useState<number[]>([]);

  const [isMoviePremium, setisMoviePremium] = useState(false);

  useEffect(() => {
    if(isMoviePremium){
      setValue('isMoviePremium', isMoviePremium)
    }
  }, [isMoviePremium])



  useEffect(() => {
    if (platformFeeData) {
      const percentages = platformFeeData.map((fee: any) => fee.platformFee_percentage );
      setPlatformFeePercentages(percentages);
    }
  }, [platformFeeData]);


  const todaysDate = (new Date()).toISOString()

  const {
    createSuccess,
    updateSuccess,
    isCreateError,
    isUpdateError,
    createError,
    updateError,
    isCreatorLoading,
    MAX_PARTICIPANTS,
    minCUserCost,
    preferredLanguages,
    handleSubmit,
    onSubmit,
    errorMessage,
    editMeeting,
    meetingDetails,
    setValue,
    watch,
    register,
    trigger,
    getValues,
    errors,
    meeting,
    isNew,
    isDirty,
    submitted,
    handleDelete,
    deleteSuccess,
    EVENT_AUDIO_DURATION,
    EVENT_VIDEO_DURATION,
    isTomorrow, nextDate
  } = useEventEditorHooks(meetingDetailId ?? '', meetingId ?? '', meetingId === 'new')
  const { pageDataRefetch } = useMyEventsPaginationHook()

  useEffect(() => {
    if (createSuccess) {
      toastSuccess(t('meetingScheduledSuccessfully'))
      pageDataRefetch()
      navigate(`/s/events/my-events/${meetingDetailId}/schedule`)
    }
  }, [createSuccess, t])

  useEffect(() => {
    if (isCreateError) {
      toastError(tr((createError as any)?.data?.message, { minCost: minCUserCost, currency: preference.currency }))
    }
  }, [isCreateError, t])

  useEffect(() => {
    if (isUpdateError) {
      toastError(tr((updateError as any)?.data?.message, { minCost: minCUserCost, currency: preference.currency }))
    }
  }, [isUpdateError, t])

  useEffect(() => {
    if (updateSuccess) {
      toastSuccess(t('meetingUpdatedSuccessfully'))
      pageDataRefetch()
      navigate(`/s/events/my-events/${meetingDetailId}/schedule`)
    }
  }, [t, updateSuccess])

  useEffect(() => {
    if (deleteSuccess) {
      toastSuccess(t('meetingDeletedSuccessfully'))
      pageDataRefetch()
      navigate(`/s/events/my-events/${meetingDetailId}/schedule`)
    }
  }, [deleteSuccess])

  useEffect(() => {
    if (meetingTimerRef && meeting && meetingId !== 'new') {
      meetingTimerRef.current?.setStartDateAndEndDate({
        startDateTime: meeting.fromDateTime,
        endDateTime: meeting.toDateTime,
      })
    } else if (meetingTimerRef && meetingId === 'new') {
      meetingTimerRef.current?.setStartDateAndEndDate({
        startDateTime: new Date().toISOString(),
        endDateTime: addHours(new Date(), 1).toISOString(),
      })
    }
  }, [meetingId, meeting, meetingTimerRef])

  useEffect(() => {
    if (meeting?.actualStartAt) {
      setParticipantEdit(true)
    }
  }, [meeting])

  const [queryLanguage, setQueryLanguage] = useState('')

  let filteredLanguages;
  if (preferredLanguages) {
    filteredLanguages = queryLanguage === '' ? preferredLanguages : preferredLanguages.filter((language: string) =>
      language.toLowerCase().replace(/\s+/g, '').includes(queryLanguage.toLowerCase().replace(/\s+/g, ''))
    )
  }

  useEffect(() => {
    if (getValues('noOfParticipants')) {
      trigger('minimumNoOfParticipants')
    }
  }, [watch('noOfParticipants')])

  const [cost, setCost] = useState(editMeeting?.cost || '');
  const taxAmount = calculateTaxAmount(cost, taxPercentages);
  const platformFeeAmount = calculateTaxAmount(cost, platformFeePercentages);

  const calculatePlatformFees = (cost: string, platformFeePercentages: any[]) => {
    return platformFeePercentages.map((percentage: number) => ({
      percentage,
      amount: (parseFloat(cost) * (percentage / 100)).toFixed(2),
    }));
  };
  const platformFeeAmounts = calculatePlatformFees(cost, platformFeePercentages);
  const totalfee = platformFeeAmounts.reduce((acc, fee) => acc + parseFloat(fee.amount), 0).toFixed(2);
  const taxtalfeegst = calculateTaxAmount(totalfee, taxPercentages);
  const grantTotal = (parseFloat(cost) + parseFloat(taxAmount) + parseFloat(totalfee) + parseFloat(taxtalfeegst)).toFixed(2);
  if (!preferredLanguages) {
    return <Loading />
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col md:gap-1">
          {errorMessage && (
            <p
              data-testid="error_meetingTime"
              id="error_meetingTime"
              className="bg-orange-100 w-96 text-yellow-900 rounded-xl my-3 py-2 mt-3 mx-auto text-center">
              {t(errorMessage)}
            </p>
          )}
          <div className="flex justify-start gap-2">
            {/* {isNew && (
              <div className="text-black font-normal text-left text-md md:text-lg ">{t('newSession')}</div>
            )} */}
            {!isNew ? (
              <div className="text-black font-normal text-left text-md md:text-lg ">
                {t('editSession')}
              </div>
            ) : (
              <div className="text-black font-normal text-left text-md md:text-lg ">
                {t('createNewSession')}
              </div>
            )}
          </div>
          <div>
            <div className="flex justify-around md:justify-start lg:justify-start 2xl:justify-start flex-row items-center md:gap-2 lg:gap-0 2xl:gap-2 gap-0">
              <div>
                <MeetingTimer
                  RFMLayout={false}
                  ref={meetingTimerRef}
                  keyId={`addSession${meetingDetailId}`}
                  // startDateTime={isNew ? '' : editMeeting?.fromDateTime}
                  // endDateTime={isNew ? '' : editMeeting?.toDateTime}
                  duration={60}
                  onFinalSelection={(
                    selectedStartDateTime: string,
                    selectedEndDateTime: string,
                  ) => {
                    setValue('fromDateTime', selectedStartDateTime)
                    setValue('toDateTime', selectedEndDateTime)
                    isTomorrow(selectedStartDateTime, selectedEndDateTime)
                    trigger('fromDateTime')
                    trigger('toDateTime')
                  }}
                  disabled={!isNew && !editMeeting?.canEdit}
                  timeZone={preference.timezone}
                />
              </div>
              {/* <div>
              <span>
                {nextDate && <div className='font-semibold text-sm tracking-tight text-black text-center cursor-default'>{date}</div>}
              </span>
              </div>  */}
            </div>
            {(errors?.toDateTime as any)?.message && (
              <div
                id="error_duration"
                data-testid="error_duration"
                role="alert"
                className="text-sm text-red-500 my-2 text-start">
                {meetingDetails && meetingDetails.videoType === VideoType.Audio
                  ? t('audio')
                  : t('video')}{' '}
                {!nextDate &&
                  meetingDetails &&
                  meetingDetails.videoType === VideoType.Audio &&
                  tr((errors?.toDateTime as any)?.message, {
                    maxDuration: EVENT_AUDIO_DURATION,
                  })}
                {!nextDate &&
                  meetingDetails &&
                  meetingDetails.videoType === VideoType.Both &&
                  tr((errors?.toDateTime as any)?.message, {
                    maxDuration: EVENT_VIDEO_DURATION,
                  })}
                {nextDate &&
                  errors?.toDateTime &&
                  t(`EventEndTimeIsLessThanTheStartTime`)}
              </div>
            )}
          </div>
          <div className="flex flex-col lg:flex-row lg:gap-4 mt-1">
            <div className="w-full lg:w-1/2 h-full hideNumberFieldArrows">
              {meetingDetails && meetingDetails.isFreeAudioMeeting && (
                <Field
                  name="cost"
                  icon={
                    <ToolTip tip={'price'}>
                      <Icon icon="CURRENCY" />
                    </ToolTip>
                  }
                  type="number"
                  id="cost"
                  disabled
                  placeholder={t('freePrice')}
                />
              )}
              {meetingDetails && !meetingDetails.isFreeAudioMeeting && (
                <Field
                  {...register('cost')}
                  error={tr((errors?.cost as any)?.message, {
                    minPrice: minCUserCost?.toFixed(),
                    currency: preference.currency,
                  })}
                  name="cost"
                  icon={
                    <ToolTip tip={'price'}>
                      <span className={`flex text-xs border-r-2 py-2 pr-4 rounded-l-lg text-gray font-medium w-11 h-fit 
                      ${formatCurrencySymbol(preference.currency).length === 3 ? 'px-3' : formatCurrencySymbol(preference.currency).length === 4 ? 'px-2' : 'px-5'}`}>
                        {formatCurrencySymbol(preference.currency)}
                      </span>
                    </ToolTip>
                  }
                  type="text"
                  onInput={(e) => {
                    if (e.target instanceof HTMLInputElement) {
                      const newValue = e.target.value.slice(0, 8);
                      e.target.value = newValue;
                      setCost(newValue);
                    }
                  }}
                  id="input_price"
                  data-testid="input_price"
                  defaultValue={editMeeting?.cost}
                  placeholder={t('price') + ' *'}
                  onKeyPress={value => {
                    if (!/\d/.test(value.key)) {
                      value.preventDefault()
                    }
                  }}
                  disabled={!isNew && !editMeeting?.canEdit}
                />
              )}
            </div>
            <div className="w-full lg:w-1/2">
              {filteredLanguages && preferredLanguages && (
                <>
                  <div className="h-8 w-full">
                    <Combobox
                      value={getValues('language') ?? ''}
                      onChange={value => {
                        setValue('language', value)
                        trigger('language')
                      }}>
                      <div className="relative z-30">
                        <div id={`input_language${meetingDetailId}`}>
                          <Combobox.Input
                            className={`read-only:bg-gray-200 read-only:text-gray-400 leading-5 pl-12 relative w-full rounded-md p-input bg-white text-gray-400 md:text-base placeholder:text-sm placeholder-gray-400 text-sm focus:outline-none focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent border-1 border-gray-300 h-8 ${
                              errors?.language?.message && 'ring-red-500 ring-1'
                            }`}
                            onChange={() => {}}
                            onSelect={(event: any) => {
                              setQueryLanguage(
                                event.target.value.replace(/[^a-zA-Z]/g, ''),
                              )
                            }}
                            onKeyPress={(event: any) => {
                              if (
                                !`${event.target.value}${event.key}`.match(
                                  /^[a-zA-Z][a-zA-Z0-9]*$/,
                                )
                              ) {
                                event.preventDefault()
                                return false
                              }
                            }}
                            readOnly={!isNew && !editMeeting?.canEdit}
                            placeholder={`${t('language')} *`}
                            data-testid={`txt_language${editMeeting?.language}`}
                            autoComplete="off"
                          />
                          <div className="absolute top-0 w-10 ">
                            <ToolTip tip={'language'}>
                              <div className="p-1 bg-white border h-8 items-center justify-center rounded-l-md border-r-2 pl-2.5">
                                <Icon icon="LANG_B" size="small" />
                              </div>
                            </ToolTip>
                          </div>

                          {!isNew && editMeeting?.canEdit && (
                            <Combobox.Button className="absolute top-1.5 right-0.5 flex items-center">
                              <div className="px-2">
                                <Icon icon="DOWN" size="small" />
                              </div>
                            </Combobox.Button>
                          )}
                        </div>

                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          afterLeave={() => setQueryLanguage('')}>
                          <Combobox.Options className="focus:outline-none absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                            {filteredLanguages?.length === 0 &&
                            queryLanguage !== '' ? (
                              <Combobox.Option
                                value={queryLanguage}
                                className={({active}) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 mx-1 rounded-lg ${
                                    active
                                      ? 'bg-BeeMG-yellow text-black'
                                      : 'text-gray-900'
                                  }`
                                }>
                                {queryLanguage}
                              </Combobox.Option>
                            ) : (
                              filteredLanguages?.map((language: any) => (
                                <Combobox.Option
                                  key={language}
                                  className={({active}) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 mx-1 rounded-lg ${
                                      active
                                        ? 'bg-BeeMG-yellow text-black'
                                        : 'text-gray-900'
                                    }`
                                  }
                                  value={language}>
                                  {({selected, active}) => (
                                    <>
                                      <span
                                        className={`block truncate ${
                                          selected
                                            ? 'font-medium'
                                            : 'font-normal'
                                        }`}>
                                        {language}
                                      </span>
                                      {selected ? (
                                        <span
                                          className={`absolute inset-y-0 left-0 flex items-center pl-1 ${
                                            active ? 'text-white' : 'text-black'
                                          }`}>
                                          <Icon
                                            icon="TICK_GREEN"
                                            size="medium"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Combobox.Option>
                              ))
                            )}
                          </Combobox.Options>
                        </Transition>
                      </div>
                    </Combobox>
                  </div>
                  {(errors?.language as any)?.message && (
                    <span
                      id="error_message"
                      data-testid="error_message"
                      className="text-red-500 text-sm capitalize">
                      {t((errors?.language as any)?.message)}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:gap-4">
            <div className="w-full lg:w-1/2 h-full hideNumberFieldArrows">
              <Field
                {...register('noOfParticipants')}
                error={tr((errors?.noOfParticipants as any)?.message, {
                  maxParticipants: MAX_PARTICIPANTS,
                  minParticipants: editMeeting?.noOfParticipants,
                })}
                name="noOfParticipants"
                onWheel={e => e.currentTarget.blur()}
                icon={
                  <ToolTip tip={'numberOfUsers'}>
                    <span className="flex border-l-0 border-r-2 px-1 py-1.5 pr-2">
                      <Icon icon="USERS" className="ml-2" />
                    </span>
                  </ToolTip>
                }
                type="number"
                id="input_noOfParticipants"
                data-testid="input_noOfParticipants"
                defaultValue={editMeeting?.noOfParticipants}
                placeholder={t('numberOfUsers') + ' *'}
                onKeyPress={(event: any) => {
                  if (
                    !`${event.target.value}${event.key}`.match(/^\d{0,2}$/)
                  ) {
                    // block the input if result does not match
                    event.preventDefault()
                    event.stopPropagation()
                    return false
                  }
                }}
              />
              <span id="txt_maxParticipants" className="mr-2 text-xs">
                {tr('maximumUsersIs', {maxParticipants: MAX_PARTICIPANTS})}
              </span>
            </div>
            <div className="w-full lg:w-1/2 h-full hideNumberFieldArrows">
              <Field
                {...register('minimumNoOfParticipants')}
                error={tr(errors?.minimumNoOfParticipants?.message)}
                name="minimumNoOfParticipants"
                onWheel={e => e.currentTarget.blur()}
                icon={
                  <ToolTip tip={'minimumNoOfUsers'}>
                    <span className="flex border-l-0 border-r-2 px-1 py-1.5 pr-2">
                      <Icon icon="USER_BLACK" className="ml-2" />
                    </span>
                  </ToolTip>
                }
                type="number"
                id="input_minParticipants"
                defaultValue={editMeeting?.minimumNoOfParticipants}
                placeholder={t('minimumNoOfUsers') + ' *'}
                onKeyPress={event => {
                  if (!/\d/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                disabled={!isNew && !editMeeting?.canEdit}
              />
            </div>
          </div>

          <div>
            {meetingDetails?.videoType === VideoType.Stream && 
            <label>
              <input
                type="checkbox"
                checked={isMoviePremium}
                onChange={(e)=> setisMoviePremium(e.target.checked)}
              />
              Create Premium
            </label>}
          </div>

          <div className="flex text-xs lg:text-base gap-2 justify-center md:justify-end">
            {meeting?.id && !isNew && (
              <Button
                id="delete"
                data-testid="btn_delete"
                color="transparent"
                className="mt-2 md:mt-8 text-red-500"
                disabled={todaysDate > meeting.fromDateTime}
                onClick={deleteToggle}>
                {t('delete')}
              </Button>
            )}

            <Button
              id="btn_cancel"
              data-testid="btn_cancel"
              color="transparent"
              className="mt-2 md:mt-8 px-5 text-red-500"
              onClick={() => {
                if (meeting?.id && !isNew) {
                  navigate(
                    `/s/events/my-events/${meetingDetailId}/schedule/new/edit${pageAndSort}`,
                  )
                } else {
                  navigate(
                    `/s/events/my-events/${meetingDetailId}/schedule${pageAndSort}`,
                  )
                }
              }}>
              {t('cancel')}
            </Button>
            {meetingDetails && !meetingDetails.isFreeAudioMeeting ? (
               <Button
               id="btn_save"
               data-testid="btn_save"
               color="sessionsData"
               onClick={saveToggle}
              disabled={
                (submitted && !isDirty) || isCreatorLoading || participantEdit || Object.keys(errors).length > 0
              }
               className="mt-2 w-52 md:mt-8 px-10 flex justify-center items-center"
               >
               {t('save')}
             </Button>
            ) : (
              <Button
                type="submit"
                id="btn_save"
                data-testid="btn_save"
                color="sessionsData"
                disabled={(submitted && !isDirty) || isCreatorLoading || participantEdit}
                className="mt-2 w-52 md:mt-8 px-5 flex justify-center items-center"
                submit>
                {/* //TODO: need to add spinner */}
                {t('save')}
              </Button>
            )}
            {saveIsOpen && meetingDetails && !meetingDetails.isFreeAudioMeeting && (
                <Modal 
                  isOpen={saveIsOpen}
                  toggle={saveToggle}
                  position="large"
                  closeOnClickOutside={false}
                  >
                  <ModalCloseButton toggle={saveToggle} />
                <ModalHeader>
                  <div className='mt-3 px-5'>
                    {t('priceBreakDown')}
                  </div>
                </ModalHeader>
                  <ModalBody>
                    <div className='px-5'>
                    <table className="w-full border border-collapse border-black mb-4 font-semibold">
                      <thead>
                       
                          <tr className="border border-black">
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-32 h-10">{t('normal')}</td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10"></td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10">{t('type')}</td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10">%</td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10">{t('amount')}</td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10">{t('net')}</td>
                          </tr>
                        
                      </thead>
                      <tbody>
                        <tr className="border border-black-900">
                          <td className="p-2 text-center border border-black w-32 h-10">{t('BaseAmount')}</td>
                          <td className="p-2 text-center border border-black border-black w-24 h-10"></td>
                          <td className="p-2 text-center border border-black border-black w-24 h-10"></td>
                          <td className="p-2 text-center border border-black border-black w-24 h-10"></td>
                          <td className="p-2 text-center border border-black border-black w-24 h-10"></td>
                          <td className="p-2 text-center border border-black border-black w-24 h-10">{cost}</td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="w-full border border-collapse border-black mb-4 font-semibold">
                      <thead>
                       
                          <tr className="border border-black">
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-32 h-10">{t('taxCalculationOnBaseAmount')}</td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10"></td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10"></td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10"></td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10"></td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10"></td>
                          </tr>
                        
                      </thead>
                      <tbody>
                        
                       {taxesData.map((tax, index) => (
                          console.log("Tax item:", tax), // This will log each item in taxesData to the console
                          <tr className="border border-black-900" key={index}>
                            <td className="p-2 text-center border border-black w-32 h-10">{t('gst')}</td>
                            <td className="p-2 text-center border border-black w-24 h-10"></td>
                            <td className="p-2 text-center border border-black w-24 h-10">{t('included')}</td>
                            <td className="p-2 text-center border border-black w-24 h-10">{tax.percentage}%</td>
                           
                            <td className="p-2 text-center border border-black w-24 h-10">₹{calculateTaxAmount(cost, tax.percentage)}</td>
                            <td className="p-2 text-center border border-black w-24 h-10"></td>
                          </tr>
                        ))}
                      
                   
                        <tr className="border border-black-900">
                          <td className="p-2 text-center border border-black w-32 h-10"></td>
                          <td className="p-2 text-center border border-black w-24 h-10">{t('totalTaxes')}</td>
                          <td className="p-2 text-center border border-black w-24 h-10"></td>
                          <td className="p-2 text-center border border-black w-24 h-10"></td>
                          <td className="p-2 text-center border border-black w-24 h-10"></td>
                          <td className="p-2 text-center border border-black w-24 h-10">₹ {taxAmount}</td>
                        </tr>
                      </tbody>
                    </table>


                    <table className="w-full border border-collapse border-black mb-4 font-semibold">
                      <thead>
                       
                          <tr className="border border-black">
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-32 h-10">{t('platformFee')}</td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10"></td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10"></td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10"></td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10"></td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10"></td>
                          </tr>
                        
                      </thead>
                      
                      <tbody>
                     
                        {platformFeeData?.map((fee: { platformFee_percentage: number; platformFee_name: string}, index: number) => {
                          const feeAmount = platformFeeAmounts.find(f => f.percentage === fee.platformFee_percentage)?.amount || '0.00';

                          return (
                            <tr className="border border-black-900" key={index}>
                              <td className="p-0 text-center border border-black w-32 h-10">{fee.platformFee_name}</td>
                              <td className="p-2 text-center border border-black w-24 h-10"></td>
                              <td className="p-2 text-center border border-black w-24 h-10">{t('included')}</td>
                              <td className="p-2 text-center border border-black w-24 h-10">{fee.platformFee_percentage}% </td>
                              <td className="p-2 text-center border border-black w-24 h-10"> {isNaN(Number(totalfee)) ||  feeAmount == null ? '-' : (' ₹ '+ feeAmount)}</td>
                              <td className="p-2 text-center border border-black w-24 h-10"></td>
                            </tr>
                          );
                        }
                        )}

                        <tr className="border border-black-900 ">
                          <td className="p-2 text-center border border-black w-32 h-10"></td>
                          <td className="p-2 text-center border border-black w-24 h-10">{t('totalFee')}</td>
                          <td className="p-2 text-center border border-black w-24 h-10"></td>
                          <td className="p-2 text-center border border-black w-24 h-10"></td>
                          <td className="p-2 text-center border border-black w-24 h-10"></td>
                          <td className="p-2 text-center border border-black w-24 h-10">{isNaN(Number(totalfee)) ||  totalfee == null ? '-' : (' ₹ '+totalfee)}</td>
                        </tr>

                      </tbody>
                    </table>


                    <table className="w-full border border-collapse border-black mb-4 font-semibold">
                      <thead>
                       
                          <tr className="border border-black">
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-32 h-10">{t('taxForPlatformFee')}</td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10"></td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10"></td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10"></td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10"></td>
                            <td className="p-2 text-center bg-BeeMG-yellow border border-black w-24 h-10"></td>
                          </tr>
                        
                      </thead>
                      <tbody>
                       
                         {taxesData.map((tax, index) => (
                          console.log("Tax item:", tax), 
                          <tr className="border border-black-900" key={index}>
                            <td className="p-2 text-center border border-black w-32 h-10">{t('gst')}</td>
                            <td className="p-2 text-center border border-black w-24 h-10"></td>
                            <td className="p-2 text-center border border-black w-24 h-10">{t('included')}</td>
                            <td className="p-2 text-center border border-black w-24 h-10">{tax.percentage}%</td>
                           
                            <td className="p-2 text-center border border-black w-24 h-10">₹{calculateTaxAmount(totalfee, tax.percentage)}</td>
                            <td className="p-2 text-center border border-black w-24 h-10"></td>
                          </tr>
                        ))}
                       
                        <tr className="border border-black-900">
                          <td className="p-2 text-center border border-black w-32 h-10"></td>
                          <td className="p-2 text-center border border-black w-24 h-10">{t('totalTaxes')}</td>
                          <td className="p-2 text-center border border-black w-24 h-10"></td>
                          <td className="p-2 text-center border border-black w-24 h-10"></td>
                          <td className="p-2 text-center border border-black w-24 h-10"></td>
                          <td className="p-2 text-center border border-black w-24 h-10"> ₹{taxtalfeegst }</td>
                        </tr>
                      </tbody>
                    </table>
                    <table className='w-full border border-collapse border-black mb-4 bg-BeeMG-yellow font-semibold'>
                      <th className='text-left w-5/6 px-4 h-10 align-middle'>
                      {t('totalAmount')}
                      </th>
                      <th className='text-right w-1/6 pr-4 h-10 align-middle'>
                      {isNaN(Number(grantTotal)) ||  grantTotal == null ? '-' : (' ₹ '+grantTotal)}
                      </th>
                    </table>
                    </div>
                  </ModalBody>
                  <div className='pr-6'>
                  <ModalFooter>
                  {(Object.keys(errors).length) > 0 && (
                    <span
                      id="error_message"
                      data-testid="error_message"
                      className="text-red-500 text-sm capitalize">
                      {t('updateRequiredFields')}
                    </span>
                  )}
                    <Button
                    className='w-32'
                      onClick={saveToggle}
                      color="footerButton"
                      data-testid="btn_saveClose">
                      {t('close')}
                    </Button>
                  <Button
                    className='w-32'
                    type="submit"
                    id="btn_saveModal"
                    data-testid="btn_saveModal"
                    color="footerButton"
                    disabled={
                      (submitted && !isDirty) || isCreatorLoading || participantEdit || Object.keys(errors).length > 0
                    }
                    submit>
                    {t('save')}
                    </Button>                 
                  </ModalFooter>
                  </div>
                </Modal>
              )}
              
          </div>
        </div>
      </form>
      {meeting && meeting.meetingParticipant && (
        <Modal isOpen={deleteIsOpen} toggle={deleteToggle} closeOnClickOutside={false}>
          <ModalCloseButton toggle={deleteToggle} />
          <ModalHeader keyId="confirm">{t('confirm')}</ModalHeader>
          <ModalBody>
            {editMeeting && editMeeting?.meetingParticipantCount > 0 && (
              <>
                <div className="mb-3">
                  <span className="text-red-500 text-lg font-semibold">
                    {t('doYouWantToDeleteTheMeeting')}
                  </span>
                  <br />
                  <div className="ml-4 font-medium">
                    {editMeeting?.meetingParticipantCount}{' '}
                    {editMeeting?.meetingParticipantCount > 1
                      ? t('usersAreThere')
                      : t('userIsThere')}
                  </div>
                </div>
                <Reason meeting={meeting} toggle={deleteToggle} />
              </>
            )}
            {editMeeting && editMeeting?.meetingParticipantCount <= 0 && (
              <div className="space-y-4">
                <div className="text-red-500 text-lg font-bold text-center">
                  {t('doYouWantToDeleteTheMeeting')}
                </div>
                <ModalFooter>
                  <Button
                    id="btn_cancel"
                    data-testid="btn_cancel"
                    onClick={deleteToggle}
                    color="footerButton"
                    className="mr-1">
                    {t('cancel')}
                  </Button>
                  <Button
                    id="btn_delete"
                    data-testid="btn_deleteMeeting"
                    onClick={() => {
                      handleDelete(meeting)
                      deleteToggle()
                    }}
                    color="footerButton">
                    {t('delete')}
                  </Button>
                </ModalFooter>
              </div>
            )}
            <br />
          </ModalBody>
        </Modal>
      )}
    </div>
  )
}

export default EventEditor

  /**
   * Renders a modal for deleting a meeting with a reason.
   *
   * @param {object} meeting - The meeting to be deleted.
   * @param {function} toggle - A function to toggle the modal.
   * @returns {JSX.Element} - The modal component.
   */
const Reason = ({ meeting, toggle }: { meeting: any, toggle: any }) => {

  const { meetingDetailId } = useParams()
  let pageAndSort = PageAndSortHook();
  const { t } = useTranslation()
  const navigate = useNavigate();

  const { deleteMeetingWithRefund } = useEventEditorHooks(meeting?.id ?? '', meeting.meetingDetails?.id ?? '', meeting === 'new')
  const {pageDataRefetch} =  useMyEventsPaginationHook();
  const { register, handleSubmit, formState: { errors }, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(meetingDetailsDeleteFormValidationSchema)
  })
 
  /**
   * Deletes a meeting with a refund reason. If the deletion is successful,
   * navigates to the schedule page, refetches the page data, and shows a success toast.
   * If the deletion fails, shows an error toast.
   * @returns {void}
   */
  const handleDeleteWithRefund = () => {

    if (meeting) {
      const args = {
        id: meeting.id,
        reason: { meetingDeletedReason: getValues('meetingDeletedReason') }
      }
      deleteMeetingWithRefund(args).then((res: any) => {
        if (res?.data) {
          toastSuccess(t('meetingDeletedSuccessfully'))
          toggle()
          navigate(`/s/events/my-events/${meetingDetailId}/schedule${pageAndSort}`)
          pageDataRefetch()
        } else {
          toastError(t('somethingWentWrong'))
          toggle()
        }
      })
    }
  }

  return (
    <>
      {meeting && meeting.id &&
        <form onSubmit={handleSubmit(handleDeleteWithRefund)}>
          <Field {...register('meetingDeletedReason')}
            data-testid="reason"
            error={errors?.meetingDeletedReason?.message}
            name="meetingDeletedReason"
            type="textarea"
            rows={2} placeholder={t(`reason`) + ' *'} />
          <div className="flex gap-4 justify-end mt-4">
            <Button
              data-testid="btn_cancelReason"
              id="delete"
              color="save"
              className="px-5 w-52"
              onClick={toggle}>
              {t('cancel')}
            </Button>
            <Button submit id="btn_delete" data-testid="btn_deleteReason" className='w-52'
              color="footerButton">
              {t('delete')}
            </Button>
          </div>
        </form>
      }
    </>
  )
}

