/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import type { CreateLocalTracksOptions, LocalAudioTrack, LocalTrack, LocalVideoTrack } from 'livekit-client'
import {
  createLocalAudioTrack,
  createLocalTracks,
  createLocalVideoTrack,
  facingModeFromLocalTrack,
  Track,
  VideoPresets,
} from 'livekit-client'
import * as React from 'react'
import type { LocalUserChoices } from '@livekit/components-core'
import { log } from '@livekit/components-core'
import { PreJoinProps, useMediaDevices, usePersistentUserChoices } from '@livekit/components-react'
import { Switch } from '@headlessui/react'
import { CustomMediaDeviceMenu } from 'livekit/controlbar/CustomMediaSelectMenu'
import { PreJoinTrackToggle } from '../JoinScreen/PreJoinTrackToggle'
import { defaultAvatar } from 'livekit/room/audio_conference/CustomAudioParticipantPlaceholder'
import { ToolTip } from 'base/tooltip/tooltip'

const DEFAULT_USER_CHOICES: LocalUserChoices = {
  username: '',
  videoEnabled: false,
  audioEnabled: false,
  videoDeviceId: 'default',
  audioDeviceId: 'default',
  // e2ee: false,
  // sharedPassphrase: '',
}

export interface OnJoinProps {
  isAnonymous: boolean
  audio: {
    enabled: boolean
    deviceId: string
  }
  video: {
    enabled: boolean
    deviceId: string
  }
}

/**
 * Props for the PreJoin component.
 * @public
 */
export interface CustomPreJoinProps {
  meetingTitle: string
  anonymousName: string
  name: string
  avatar: string
  profileImg: string | undefined
  isHost: boolean
  isAudioOnly: boolean
  getTranslation: (key: string) => string
  onClickJoin: (joinProps: OnJoinProps) => void
  navigateTo: (path: string) => void
}

/**
 * Creates and manages local tracks for preview purposes.
 *
 * @param {CreateLocalTracksOptions} options - Options for creating local tracks.
 * @param {(err: Error) => void} [onError] - Optional error handler.
 * @return {LocalTrack[]} The created local tracks.
 */

export function usePreviewTracks(options: CreateLocalTracksOptions, onError?: (err: Error) => void) {
  const [tracks, setTracks] = React.useState<LocalTrack[]>()

  React.useEffect(() => {
    let trackPromise: Promise<LocalTrack[]> | undefined
    let needsCleanup = false
    if (options.audio || options.video) {
      trackPromise = createLocalTracks(options)
      trackPromise
        .then((tracks) => {
          if (needsCleanup) {
            tracks.forEach((tr) => tr.stop())
          } else {
            setTracks(tracks)
          }
        })
        .catch(onError)
    }

    return () => {
      needsCleanup = true
      trackPromise?.then((tracks) =>
        tracks.forEach((track) => {
          track.stop()
        })
      )
    }
  }, [JSON.stringify(options)])

  return tracks
}

/**
 * Creates and manages a local video or audio track for preview purposes.
 *
 * @param {boolean} enabled - Whether the track is enabled.
 * @param {string} deviceId - The ID of the device to use for the track.
 * @param {'videoinput' | 'audioinput'} kind - The type of track to create.
 * @return {{ selectedDevice: MediaDeviceInfo | undefined, localTrack: T | undefined, deviceError: Error | null }} An object containing the selected device, local track, and any device error.
 */

export function usePreviewDevice<T extends LocalVideoTrack | LocalAudioTrack>(
  enabled: boolean,
  deviceId: string,
  kind: 'videoinput' | 'audioinput'
) {
  const [deviceError, setDeviceError] = React.useState<Error | null>(null)
  const [isCreatingTrack, setIsCreatingTrack] = React.useState<boolean>(false)

  const devices = useMediaDevices({ kind })
  const [selectedDevice, setSelectedDevice] = React.useState<MediaDeviceInfo | undefined>(undefined)

  const [localTrack, setLocalTrack] = React.useState<T>()
  const [localDeviceId, setLocalDeviceId] = React.useState<string>(deviceId)

  const prevDeviceId = React.useRef(localDeviceId)

  const switchDevice = async (track: LocalVideoTrack | LocalAudioTrack, id: string) => {
    await track.setDeviceId(id)
    prevDeviceId.current = id
  }

  const createTrack = async (deviceId: string, kind: 'videoinput' | 'audioinput') => {
    try {
      const track =
        kind === 'videoinput'
          ? await createLocalVideoTrack({
              deviceId,
              resolution: VideoPresets.h720.resolution,
            })
          : await createLocalAudioTrack({ deviceId })

      const newDeviceId = await track.getDeviceId()
      if (newDeviceId && deviceId !== newDeviceId) {
        prevDeviceId.current = newDeviceId
        setLocalDeviceId(newDeviceId)
      }
      setLocalTrack(track as T)
    } catch (e) {
      if (e instanceof Error) {
        setDeviceError(e)
      }
    }
  }

  React.useEffect(() => {
    setLocalDeviceId(deviceId)
  }, [deviceId])

  React.useEffect(() => {
    if (enabled && !localTrack && !deviceError && !isCreatingTrack) {
      log.debug('creating track', kind)
      setIsCreatingTrack(true)
      createTrack(localDeviceId, kind).finally(() => {
        setIsCreatingTrack(false)
      })
    }
  }, [enabled, localTrack, deviceError, isCreatingTrack])

  // switch camera device
  React.useEffect(() => {
    if (!localTrack) {
      return
    }
    if (!enabled) {
      log.debug(`muting ${kind} track`)
      localTrack.mute().then(() => log.debug(localTrack.mediaStreamTrack))
    } else if (selectedDevice?.deviceId && prevDeviceId.current !== selectedDevice?.deviceId) {
      log.debug(`switching ${kind} device from`, prevDeviceId.current, selectedDevice.deviceId)
      switchDevice(localTrack, selectedDevice.deviceId)
    } else {
      log.debug(`unmuting local ${kind} track`)
      localTrack.unmute()
    }
  }, [localTrack, selectedDevice, enabled, kind])

  React.useEffect(() => {
    return () => {
      if (localTrack) {
        log.debug(`stopping local ${kind} track`)
        localTrack.stop()
        localTrack.mute()
      }
    }
  }, [kind, localTrack])

  React.useEffect(() => {
    setSelectedDevice(devices.find((dev) => dev.deviceId === localDeviceId))
  }, [localDeviceId, devices])

  return {
    selectedDevice,
    localTrack,
    deviceError,
  }
}

/**
 * Renders a profile image based on the provided avatar and profile image.
 * If the profile image is available and not null or undefined, it is displayed.
 * Otherwise, a default avatar is displayed.
 *
 * @param {string} avatar - The avatar image URL.
 * @param {string | undefined} profileImg - The profile image URL.
 * @param {boolean} isAnonymous - Whether the user is anonymous.
 * @return {JSX.Element} The rendered profile image.
 */

function RenderProfileImage({
  avatar,
  profileImg,
  isAnonymous,
}: {
  avatar: string
  profileImg: string | undefined
  isAnonymous: boolean
}) {
  const [_profileImg, setProfileImg] = React.useState<string | undefined>(undefined)
  const [_avatar, setAvatar] = React.useState<string | undefined>(undefined)

  React.useEffect(() => {
    if (profileImg && (profileImg.includes('null') || profileImg.includes('undefined'))) {
      setProfileImg(undefined)
    } else {
      setProfileImg(profileImg)
    }
  }, [profileImg])
  React.useEffect(() => {
    if (avatar && (avatar.includes('null') || avatar.includes('undefined'))) {
      setAvatar(undefined)
    } else {
      setAvatar(avatar)
    }
  }, [avatar])

  if (!isAnonymous && _profileImg) {
    return <img className="w-36 h-36 rounded-full" src={_profileImg} alt="Profile" />
  } else if (isAnonymous && _avatar) {
    return <img className="w-36 h-36 rounded-full" src={_avatar} alt="Avatar" />
  } else {
    return (
      <div className="bg-gray-200 flex w-40 h-40 justify-center items-center rounded-full">
        <img className="w-36 h-36 rounded-full" src={defaultAvatar} alt="Avatar" />
      </div>
    )
  }
}

/**
 * The `PreJoin` prefab component is normally presented to the user before he enters a room.
 * This component allows the user to check and select the preferred media device (camera und microphone).
 * On submit the user decisions are returned, which can then be passed on to the `LiveKitRoom` so that the user enters the room with the correct media devices.
 *
 * @remarks
 * This component is independent of the `LiveKitRoom` component and should not be nested within it.
 * Because it only access the local media tracks this component is self contained and works without connection to the LiveKit server.
 *
 * @example
 * ```tsx
 * <PreJoin />
 * ```
 * @public
 */

export function PreJoin({
  defaults = {},
  onValidate,
  onSubmit,
  onError,
  debug,
  joinLabel = 'Join Room',
  micLabel = 'Microphone',
  camLabel = 'Camera',
  userLabel = 'Username',
  persistUserChoices = true,
  meetingTitle,
  anonymousName,
  name,
  avatar,
  profileImg,
  isHost,
  isAudioOnly,
  getTranslation,
  onClickJoin,
  navigateTo,
  ...htmlProps
}: PreJoinProps & CustomPreJoinProps) {
  const [userChoices, setUserChoices] = React.useState(DEFAULT_USER_CHOICES)

  // TODO: Remove and pipe `defaults` object directly into `usePersistentUserChoices` once we fully switch from type `LocalUserChoices` to `UserChoices`.
  const partialDefaults: Partial<LocalUserChoices> = {
    ...(defaults.audioDeviceId !== undefined && { audioDeviceId: defaults.audioDeviceId }),
    ...(defaults.videoDeviceId !== undefined && { videoDeviceId: defaults.videoDeviceId }),
    ...(defaults.audioEnabled !== undefined && { audioEnabled: defaults.audioEnabled }),
    ...(defaults.videoEnabled !== undefined && { videoEnabled: defaults.videoEnabled }),
    ...(defaults.username !== undefined && { username: defaults.username }),
  }

  const {
    userChoices: initialUserChoices,
    saveAudioInputDeviceId,
    saveAudioInputEnabled,
    saveVideoInputDeviceId,
    saveVideoInputEnabled,
    saveUsername,
  } = usePersistentUserChoices({
    defaults: partialDefaults,
    preventSave: !persistUserChoices,
    preventLoad: !persistUserChoices,
  })

  // Initialize device settings
  const [audioEnabled, setAudioEnabled] = React.useState<boolean>(initialUserChoices.audioEnabled)
  const [videoEnabled, setVideoEnabled] = React.useState<boolean>(initialUserChoices.videoEnabled)
  const [audioDeviceId, setAudioDeviceId] = React.useState<string>(initialUserChoices.audioDeviceId)
  const [videoDeviceId, setVideoDeviceId] = React.useState<string>(initialUserChoices.videoDeviceId)
  const [username] = React.useState(initialUserChoices.username)
  const [enabled, setEnabled] = React.useState(false)
  const [isAnonymous, setIsAnonymous] = React.useState(false)
  const fullName = name ? name.replace(/\b\w/g, (c) => c.toUpperCase()) : ''
  const fullNameAnonymous = anonymousName ? anonymousName.replace(/\b\w/g, (c) => c.toUpperCase()) : ''

  const [audioLevel, setAudioLevel] = React.useState(0)

  // Save user choices to persistent storage.
  React.useEffect(() => {
    saveAudioInputEnabled(audioEnabled)
  }, [audioEnabled, saveAudioInputEnabled])
  React.useEffect(() => {
    saveVideoInputEnabled(videoEnabled)
  }, [videoEnabled, saveVideoInputEnabled])
  React.useEffect(() => {
    saveAudioInputDeviceId(audioDeviceId)
  }, [audioDeviceId, saveAudioInputDeviceId])
  React.useEffect(() => {
    saveVideoInputDeviceId(videoDeviceId)
  }, [videoDeviceId, saveVideoInputDeviceId])
  React.useEffect(() => {
    saveUsername(username)
  }, [username, saveUsername])

  const tracks = usePreviewTracks(
    {
      audio: audioEnabled ? { deviceId: initialUserChoices.audioDeviceId } : false,
      video: videoEnabled ? { deviceId: initialUserChoices.videoDeviceId } : false,
    },
    onError
  )

  const videoEl = React.useRef(null)

  const videoTrack = React.useMemo(
    () => tracks?.filter((track) => track.kind === Track.Kind.Video)[0] as LocalVideoTrack,
    [tracks]
  )
  const facingMode = React.useMemo(() => {
    if (videoTrack) {
      const { facingMode } = facingModeFromLocalTrack(videoTrack)
      return facingMode
    } else {
      return 'undefined'
    }
  }, [videoTrack])

  const audioTrack = React.useMemo(
    () => tracks?.filter((track) => track.kind === Track.Kind.Audio)[0] as LocalAudioTrack,
    [tracks]
  )

  // our code

  const handleSwitchChange = (isChecked: boolean) => {
    setEnabled(isChecked)
    // Set isAnonymous to true when enabled is true
    setIsAnonymous(isChecked)
  }

  const onJoinClick = () => {
    onClickJoin({
      isAnonymous,
      audio: {
        enabled: audioEnabled,
        deviceId: audioDeviceId,
      },
      video: {
        enabled: videoEnabled,
        deviceId: videoDeviceId,
      },
    })
  }

  React.useEffect(() => {
    if (videoEl.current && videoTrack) {
      videoTrack.unmute()
      videoTrack.attach(videoEl.current)
    }

    return () => {
      videoTrack?.detach()
    }
  }, [videoTrack])
  const audioEl = React.useRef(null)

  React.useEffect(() => {
    if (audioEl.current && audioTrack) {
      audioTrack.unmute()
      audioTrack.attach(audioEl.current)
    }

    return () => {
      audioTrack?.detach()
    }
  }, [audioTrack])

  React.useEffect(() => {
    if (audioEl.current && audioTrack) {
      audioTrack.unmute()
      audioTrack.attach(audioEl.current)
    }

    return () => {
      audioTrack?.detach()
    }
  }, [audioTrack])

  React.useEffect(() => {
    const handleAudioStream = () => {
      const audioContext = new AudioContext()
      const analyser = audioContext.createAnalyser()
      if (audioTrack && audioTrack.mediaStream) {
        const source = audioContext.createMediaStreamSource(audioTrack.mediaStream)
        source.connect(analyser)
      }

      // source.connect(analyser)
      analyser.fftSize = 256
      const bufferLength = analyser.frequencyBinCount
      const dataArray = new Uint8Array(bufferLength)

      const updateAudioLevel = () => {
        analyser.getByteFrequencyData(dataArray)
        let sum = 0
        for (let i = 0; i < bufferLength; i++) {
          sum += dataArray[i]
        }
        const average = sum / bufferLength
        const normalizedValue = average / 255
        setAudioLevel(normalizedValue)
        requestAnimationFrame(updateAudioLevel)
      }
      updateAudioLevel()
    }

    if (audioTrack) {
      handleAudioStream()
    }

    return () => {
      // Cleanup audio context when component unmounts
      if (audioTrack) {
        audioTrack.stop()
        audioTrack.mute()
      }
    }
  }, [audioTrack])

  const [isValid, setIsValid] = React.useState<boolean>()

  const handleValidation = React.useCallback(
    (values: LocalUserChoices) => {
      if (typeof onValidate === 'function') {
        return onValidate(values)
      } else {
        return values.username !== ''
      }
    },
    [onValidate]
  )

  React.useEffect(() => {
    const newUserChoices = {
      username,
      videoEnabled,
      videoDeviceId,
      audioEnabled,
      audioDeviceId,
      // e2ee,
      // sharedPassphrase,
    }
    setUserChoices(newUserChoices)
    setIsValid(handleValidation(newUserChoices))
  }, [username, videoEnabled, handleValidation, audioEnabled, audioDeviceId, videoDeviceId])

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault()
    if (handleValidation(userChoices)) {
      if (typeof onSubmit === 'function') {
        onSubmit(userChoices)
      }
    } else {
      console.warn('Validation failed with: ', userChoices)
    }
  }

  // useWarnAboutMissingStyles();

  function AudioLevel() {
    return (
      audioEnabled &&
      audioTrack && (
        <>
          <div
            className={`absolute transition-all ease-in-out justify-center items-center flex top-0 left-0 right-0 bottom-0 rounded-full overflow-hidden ${
              audioEnabled ? 'outline outline-yellow-400 outline-1 opacity-40  animate-audioLevel ' : ''
            }`}
          />
          <div
            className={`bg-orange-400 bg-opacity-60 absolute transition-all ease-in-out duration-300 delay-600 opacity-40 justify-center items-center flex top-0 left-0 right-0 bottom-0 rounded-full overflow-hidden transform ${
              audioLevel >= 0.08
                ? 'scale-110'
                : audioLevel >= 0.09
                ? 'scale-115'
                : audioLevel >= 0.1
                ? 'scale-120'
                : audioLevel >= 0.11
                ? 'scale-125'
                : audioLevel >= 0.12
                ? 'scale-130'
                : 'scale-140'
            }`}
          />
        </>
      )
    )
  }

  return (
    <div className="flex h-full flex-col justify-center items-center bg-white gap-y-3">
      <div className="text-xl md:text-2xl font-medium flex capitalize justify-center mt-2 mb-4" id="txt_meetingTitle">
        {meetingTitle}
      </div>
      {videoEnabled && videoTrack && !isAudioOnly ? (
        <div className="lg:h-80 md:h-72 lg:w-[30rem] md:w-[20rem]">
          <video ref={videoEl} className="h-full w-full object-cover rounded-lg" data-lk-facing-mode={facingMode} />
        </div>
      ) : (
        <div className="lg:h-80 md:h-72 lg:w-[30rem] md:w-[20rem] bg-gray-200 rounded-lg relative">
          <div className="flex flex-col h-full m-auto justify-center items-center lg:gap-y-6 md:gap-y-4 xs:gap-y-2 xs:px-4">
            <div className="relative m-1">
              <AudioLevel />
              <div className="relative z-10">
                <RenderProfileImage avatar={avatar} profileImg={profileImg} isAnonymous={isAnonymous} />
              </div>
            </div>
            <ToolTip tip={isAnonymous ? fullNameAnonymous : fullName}>
              <div className="lg:text-2xl md:text-xl xs:text-lg capitalize" id="userName">
                {isAnonymous ? fullNameAnonymous : fullName}
              </div>
            </ToolTip>
          </div>
        </div>
      )}

      {audioEnabled && audioTrack && (
        <div>
          <audio ref={audioEl} controls autoPlay className="hidden" />
          <span className="text-sm text-gray-500 block text-center">Audio test</span>
          <span>
            <span className="audio-level h-2 max-w-5">
              {' '}
              <progress value={audioLevel * 3} className="custom-progress" />
            </span>
          </span>
        </div>
      )}

      <div className="flex flex-row items-center gap-x-3">
        <div className="flex flex-row items-center">
          <>
            <PreJoinTrackToggle
              initialState={false}
              source={Track.Source.Microphone}
              onChange={(enabled: boolean) => setAudioEnabled(enabled)}
              className="cursor-pointer py-3 px-4 h-10 inline-flex justify-center items-center gap-2 -ml-px first:rounded-l-full first:ml-0 last:rounded-r-full border border-gray-200 font-medium bg-white text-gray-700 align-middle focus:z-10 transition-all text-sm"
            ></PreJoinTrackToggle>
          </>
          <>
            <CustomMediaDeviceMenu
              bottom="15rem"
              initialSelection={audioDeviceId}
              kind="audioinput"
              tracks={{ audioinput: audioTrack }}
              onActiveDeviceChange={(_, id) => setAudioDeviceId(id)}
              className="py-3 px-4 h-10 inline-flex justify-center items-center gap-2 -ml-px first:ml-0 last:rounded-r-full border font-medium bg-white text-gray-700 align-middle transition-all text-sm"
            />
          </>
        </div>
        {/* for video control */}
        <>
          {!isAudioOnly && (
            <div className="flex flex-row items-center">
              <>
                <PreJoinTrackToggle
                  initialState={false}
                  source={Track.Source.Camera}
                  onChange={(enabled: boolean) => setVideoEnabled(enabled)}
                  className="cursor-pointer py-3 px-4 h-10 inline-flex justify-center items-center gap-2 -ml-px first:rounded-l-full first:ml-0 last:rounded-r-full border border-gray-200 font-medium bg-white text-gray-700 align-middle focus:z-10 transition-all text-sm"
                ></PreJoinTrackToggle>
              </>
              <>
                {' '}
                <CustomMediaDeviceMenu
                  bottom="15rem"
                  initialSelection={videoDeviceId}
                  kind="videoinput"
                  tracks={{ videoinput: videoTrack }}
                  onActiveDeviceChange={(_, id) => setVideoDeviceId(id)}
                  className="py-3 px-4 h-10 inline-flex justify-center items-center gap-2 -ml-px first:ml-0 last:rounded-r-full border font-medium bg-white text-gray-700 align-middle transition-all text-sm"
                />
              </>
            </div>
          )}
        </>
      </div>
      <div className="flex md:flex-row flex-col  items-center md:gap-x-3 gap-y-2">
        <div className="text-2xl" id="txt_JoinAnonymous">
          {getTranslation && getTranslation('joinAnonymous')}?
        </div>
        <Switch
          id="chk_anonymousSlider"
          checked={enabled}
          onChange={handleSwitchChange}
          className={`${
            enabled ? 'bg-primary' : 'bg-gray-400'
          } relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span
            className={`${
              enabled ? 'translate-x-6' : 'translate-x-1'
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
      </div>
      <>
        {isAnonymous && !anonymousName ? (
          <span
            className="text-blue-900 text-sm hover:underline cursor-pointer"
            id="btn_anonymousProfile"
            onClick={() => {
              navigateTo('/s/profile')
            }}
          >
            {getTranslation && getTranslation('updateAnonymousProfile')}
          </span>
        ) : (
          <button
            className="bg-primary text-black px-6 py-2 rounded-full"
            id="btn_join"
            onClick={() => {
              onJoinClick()
            }}
          >
            {getTranslation && getTranslation('join')}
          </button>
        )}
      </>
      {/* <button
        className="lk-button lk-join-button"
        type="submit"
        onClick={handleSubmit}
        disabled={!isValid}
      >
        {joinLabel}
      </button> */}

      {/* //TODO: Testing purpose */}
      {/*   
      {debug && (
        <>
          <strong>User Choices:</strong>
          <ul className="lk-list" style={{ overflow: 'hidden', maxWidth: '15rem' }}>
            <li>Username: {`${userChoices.username}`}</li>
            <li>Video Enabled: {`${userChoices.videoEnabled}`}</li>
            <li>Audio Enabled: {`${userChoices.audioEnabled}`}</li>
            <li>Video Device: {`${userChoices.videoDeviceId}`}</li>
            <li>Audio Device: {`${userChoices.audioDeviceId}`}</li>
          </ul>
        </>
      )} */}
    </div>
  )
}
