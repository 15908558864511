/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import '@livekit/components-styles'
import { RoomAudioRenderer, useTracks } from '@livekit/components-react'
import { Track } from 'livekit-client'
import { useSearchParams } from 'react-router-dom'
import { LiveKitCustomControlBar } from './controlbar/LivekitCustomControlBar'
import LiveKitHeader from './header/LiveKitHeader'
import React, { useEffect, useState } from 'react'
import { CustomLiveKitRoom } from './room/CustomLivekitRoom'
import { CustomParticipantTile } from './room/CustomParticipantTile'
import { CustomGridLayout } from './room/CustomGridLayout'
import { CustomGridContextProvider } from './room/CustomGridContext'
import { CustomAudioParticipantTile } from './room/audio_conference/CustomAudioParticipantTile'
import { CustomAudioGridLayout } from './room/audio_conference/CustomAudioGridLayout'
import { CustomAudioGridContextProvider } from './room/audio_conference/CustomAudioGridContext'
import MinimizedComp from './MinimizedComp'
import i18n from '../Translations'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import { resources } from '../Translations/resources'
import { MeetingType } from 'Models/meeting.model'
/**
 * Generates a token based on search parameters.
 *
 * Fetches a token from a server if no token is provided in the search parameters.
 *
 * @return {string | undefined} The generated or fetched token, or undefined if no token is available.
 */

function useTokenGenerator() {
  const [searchParams] = useSearchParams()
  const [token, setToken] = useState<string | undefined>()
  const paramToken = searchParams.get('token')

  useEffect(() => {
    if (paramToken) {
      setToken(paramToken)
      return
    }
    // fetch(`http://localhost:3001?identity=${searchParams.get('identity')}&isHost=${searchParams.get('isHost')}&isCoHost=${searchParams.get('isCoHost')}`, {
    //   method: 'GET',
    // })
    fetch(`http://localhost:3001?isHost=${searchParams.get('isHost')}&isCoHost=${searchParams.get('isCoHost')}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        setToken(data.token)
      })
      .catch((error) => console.log(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return token || paramToken
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

interface LiveKitAppProps {
  isAudioOnly: boolean
  token: string
  endTime: Date
  serverUrl: string
  waitingTimeForHostOrCoHost: number
  onPipToggle?: (isPip: boolean) => void
  meetingTitle?: string
  meetingId?: string
  onEnd?: (meetingId: string) => void
  onLeave?: (meetingId: string) => void
  toast?: string
  onClickLogo?: (path: string) => void
  profileImg: string
  onMessage?: (type: string, message: string) => void
  meetingType: MeetingType
  logo?: string
}

/**
 * A custom audio conference component that renders a grid of audio participants.
 *
 * @return {JSX.Element} The rendered audio conference grid
 */

function MyAudioConference() {
  const tracks = useTracks(
    [
      { source: Track.Source.Microphone, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false }
  )

  return (
    <CustomAudioGridContextProvider>
      <CustomAudioGridLayout tracks={tracks}>
        <CustomAudioParticipantTile />
      </CustomAudioGridLayout>
    </CustomAudioGridContextProvider>
  )
}

/**
 * A React component that renders a grid of video conference participants.
 *
 * @return {JSX.Element} The rendered video conference grid
 */

function GridVideoConference() {
  // `useTracks` returns all camera and screen share tracks. If a user
  // joins without a published camera track, a placeholder track is returned.
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false }
  )
  return (
    <CustomGridContextProvider>
      <CustomGridLayout
        tracks={tracks}
        // style={{ height: "calc(100vh - var(--lk-control-bar-height))" }}
        className="bg-yellow-400 flex justify-center items-center gap-0"
      >
        {/* The GridLayout accepts zero or one child. The child is used
      as a template to render all passed in tracks. */}
        <CustomParticipantTile />
      </CustomGridLayout>
    </CustomGridContextProvider>
  )
}

/**
 * A component that displays a minimized representation of a meeting, including the meeting title, profile image, and video tracks.
 *
 * @param {string} meetingTitle - The title of the meeting.
 * @param {string} profileImg - The URL of the profile image.
 * @param {(type: string, message: string) => void} [onMessage] - An optional callback function to handle messages.
 * @return {JSX.Element} The rendered minimized meeting component.
 */

function PipComponent({
  meetingTitle,
  profileImg,
  onMessage,
}: {
  meetingTitle: string
  profileImg: string
  onMessage?: (type: string, message: string) => void
}) {
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false }
  )

  return <MinimizedComp meetingTitle={meetingTitle} profileImg={profileImg} tracks={tracks} onMessage={onMessage} />
}

/**
 * A React component that renders a LiveKit app with audio and video conferencing functionality.
 *
 * @param {LiveKitAppProps} props - The properties for the LiveKit app component, including isAudioOnly, token, endTime, serverUrl, waitingTimeForHostOrCoHost, meetingTitle, toast, and onMessage.
 * @return {JSX.Element} The JSX element representing the LiveKit app component.
 */

export function LiveKitApp(props: LiveKitAppProps) {
  const {
    isAudioOnly,
    token,
    endTime,
    serverUrl,
    waitingTimeForHostOrCoHost,
    meetingTitle,
    toast,
    onMessage,
    meetingType,
    logo,
  } = props
  const [isMinimized, setIsMinimized] = useState(false)
  const [isPipEnabled, setIsPipEnabled] = useState(false)
  // const token = useTokenGenerator()

  return (
    <>
      <div
        className={`${
          !isMinimized
            ? ' h-screen flex flex-col w-full fixed z-200 bg-white'
            : ' m-auto mt-2 fixed z-200 bottom-5 right-4 flex flex-col rounded-xl gap-y-2 shadow-lg border border-gray-200 w-60 bg-white'
        }`}
      >
        <I18nextProvider i18n={i18n}>
          <CustomLiveKitRoom
            video={!isAudioOnly}
            audio={true}
            isAudioOnly={isAudioOnly}
            token={token ?? ''}
            connectOptions={{ autoSubscribe: true }}
            serverUrl={serverUrl}
            data-lk-theme="default"
            meetingEndTime={endTime.toISOString()}
            alertsBeforeMeetingEnds={[5 * 60, 2 * 60]}
            waitingTimeForHostOrCoHost={waitingTimeForHostOrCoHost}
            toasterMsg={toast}
            onMessage={(type, message) => onMessage && onMessage(type, message)}
            // options={{
            //   publishDefaults: {
            //     videoCodec: 'vp9',
            //   },
            // }}
          >
            {!isMinimized && (
              <div className={`${!isMinimized && 'w-full text-center p-2 top-0'}`}>
                <LiveKitHeader
                  logo={logo}
                  onClick={() => {
                    setIsMinimized(!isMinimized)
                    setIsPipEnabled(!isPipEnabled)
                    props.onClickLogo && props.onClickLogo('/s/creator')
                  }}
                />
              </div>
            )}

            {/* Your custom components with basic audio or video conferencing functionality */}
            <div className={`${!isMinimized && 'flex-1 w-full overflow-y-scroll scrollbar-hide'}`}>
              <RoomAudioRenderer />
              {!isAudioOnly && !isMinimized && <GridVideoConference />}
              {isAudioOnly && !isMinimized && <MyAudioConference />}

              {/* Minimized view with meeting details */}

              {isMinimized && meetingTitle && (
                <PipComponent meetingTitle={meetingTitle} profileImg={props.profileImg} onMessage={onMessage} />
              )}
            </div>

            {/* Additional UI elements and the control bar */}
            <div className={`${!isMinimized && 'w-full p-2 sticky bottom-0'}`}>
              <LiveKitCustomControlBar
                className="bg-blue-500"
                controls={{
                  microphone: true,
                  camera: true,
                  leave: true,
                  chat: true,
                  settings: true,
                }}
                isPip={() => {
                  setIsMinimized(!isMinimized)
                  setIsPipEnabled(!isPipEnabled)
                  props.onPipToggle && props.onPipToggle(!isMinimized)
                }}
                onLeave={() => props.onLeave && props.meetingId && props.onLeave(props.meetingId)}
                onEnd={() => props.onEnd && props.meetingId && props.onEnd(props.meetingId)}
                isPipEnabled={isMinimized}
                meetingType={meetingType}
              />
            </div>
          </CustomLiveKitRoom>
        </I18nextProvider>
      </div>
    </>
  )
}
