/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {socketClient} from './socketio';
import {Meeting} from '../models/meeting.model';
import {meetingApi} from '../Services/meetingApi';
import {
  updateMeetingNotificationCount,
  updateUpcomingMeeting,
} from '../Services/meetingReducer';
import {messageApi} from '../Services/messageApi';
import {updateOnlineUsers} from '../Services/onlineUsersReducer';
import {setSocketLogined} from '../Services/socketReducer';
import {userApi} from '../Services/userApi';
import {store} from '../Store';
import {
  addToChat,
  updateChatUsers,
  updateVideoRequestChat,
} from '../Store/Chat/chat';
import {ChatMessage} from '../Store/Chat/ChatModel';
import {
  AcknowledgementMessage,
  Message,
  MessageType,
  WebCommandMessage,
  WebCommandTypes,
} from './socketTypes';

/**
 * Adds event listeners to the socket client for various message types.
 *
 * Handles text messages, video requests, video meeting accepts and rejects,
 * updates to user online status, upcoming meetings, meeting notification counts,
 * web commands, and acknowledgement messages.
 *
 * @return {void}
 */

const addSocketListeners = () => {
  const {currentUser} = (store.getState() as any)?.user;
  const fromCurrentUser = (id: string) => id === currentUser?.id;

  const dispatch = store.dispatch;

  // Text message
  socketClient.addListener(MessageType.Text, (message: Message) => {
    const textMessage = message.message as ChatMessage;

    dispatch(addToChat(textMessage));
    if (textMessage?.fromUser) {
      const payload = {
        message: textMessage,
        fromCurrentUser: fromCurrentUser(textMessage.fromUser?.id),
      };
      dispatch(updateChatUsers(payload));
      dispatch(messageApi.util.invalidateTags(['UnreadChatCount']));
    }
  });

  // Video Request
  socketClient.addListener(MessageType.VideoRequest, (message: Message) => {
    const videoRequest = message.message as ChatMessage;

    dispatch(addToChat(videoRequest));
    if (videoRequest?.fromUser) {
      const payload = {
        message: videoRequest,
        fromCurrentUser: fromCurrentUser(videoRequest.fromUser?.id),
      };
      dispatch(updateChatUsers(payload));
      dispatch(messageApi.util.invalidateTags(['UnreadChatCount']));
    }
  });

    //upload file
    socketClient.addListener(MessageType.UploadFile, (message: Message) => {
      const fileMessage = message.message as ChatMessage;
      dispatch(addToChat(fileMessage));
      if (fileMessage?.fromUser) {
        const payload = {
          message: fileMessage,
          fromCurrentUser: fromCurrentUser(fileMessage.fromUser?.id),
        };
        dispatch(updateChatUsers(payload));
        dispatch(messageApi.util.invalidateTags(['UnreadChatCount']));
      }
    });

  // Video Request Accept
  socketClient.addListener(
    MessageType.VideoMeetingAccept,
    (message: Message) => {
      dispatch(updateVideoRequestChat(message));
      dispatch(messageApi.util.invalidateTags(['VideoRequest']));
      dispatch(messageApi.util.invalidateTags(['UnreadChatCount']));
    },
  );

  // Video Request Reject
  socketClient.addListener(
    MessageType.VideoMeetingReject,
    (message: Message) => {
      dispatch(updateVideoRequestChat(message));
      dispatch(messageApi.util.invalidateTags(['VideoRequest']));
      dispatch(messageApi.util.invalidateTags(['UnreadChatCount']));
    },
  );

  // get file details
  socketClient.addListener(MessageType.GetFileDetails, (message: Message) => {
    const fileMessage = message.message as ChatMessage;
    dispatch(addToChat(fileMessage));
    if (fileMessage?.fromUser) {
      const payload = {
        message: fileMessage,
        fromCurrentUser: fromCurrentUser(fileMessage.fromUser?.id),
      };
      dispatch(updateChatUsers(payload));
      dispatch(messageApi.util.invalidateTags(['UnreadChatCount']));
    }
  });


  // Update Online status of other users
  socketClient.addListener(MessageType.UpdateUserStatus, (message: Message) => {
    if (message.message) dispatch(updateOnlineUsers(message.message));
  });

  // Upcoming meeting event
  socketClient.addListener(MessageType.UpcomingMeeting, (message: Message) => {
    console.log("Upcoming meeting: ", message);
    if (message && message.message) {
      const meeting = message.message as any as Meeting;
      dispatch(meetingApi.util.invalidateTags(['AttendingMeeting']));
      dispatch(updateUpcomingMeeting(meeting));
    }
  });

  // Events badge / Meeting notification count
  socketClient.addListener(
    MessageType.MeetingNotificationCount,
    (message: Message) => {
      if (message && message.message !== null || message.message !== undefined) {
        const meetingNotificationCount = message.message as number;
        dispatch(meetingApi.util.invalidateTags(['MeetingNotificationCount']));
        dispatch(updateMeetingNotificationCount(meetingNotificationCount));
      }
    },
  );

  // Web command - (Profile Update, etc..)
  socketClient.addListener(MessageType.WebCommand, (message: Message) => {
    if (message) {
      const webCommand = message.message as WebCommandMessage;
      switch (webCommand.type) {
        case WebCommandTypes.ProfileUpdate:
          dispatch(userApi.util.invalidateTags(['CurrentUser']));
          break;
        case WebCommandTypes.RFMUpdate:
          dispatch(meetingApi.util.invalidateTags(['AttendingMeeting']));
          break;
        case WebCommandTypes.BlockingUsersUpdate:
          dispatch(meetingApi.util.invalidateTags(['blockedChats']));
          break;
        default:
          break;
      }
    }
  });

  // Acknowledgement messages - (addUser, addToGroup, etc..)
  socketClient.addListener(MessageType.Acknowledgement, (message: Message) => {
    if (message.message) {
      const ackMsg = message.message as AcknowledgementMessage;
      switch (ackMsg.type) {
        case MessageType.AddUser:
          dispatch(setSocketLogined(true));
          break;
      }
    }
  });
};

export default addSocketListeners;
