/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import * as React from 'react'
import { ParticipantTileProps, useParticipantTile, useEnsureTrackRef } from '@livekit/components-react'
import IndividualParticipantPlaceholder from './IndividualParticipantPlaceholder'
import { useCustomRoomContext } from '../CustomRoomContext'

/**
 * A React component that represents a moderator in an audio conference.
 * It handles rendering the moderator's participant tile with a custom layout.
 *
 * @param {ParticipantTileProps} trackRef - The track reference of the moderator.
 * @param {React.ReactNode} children - The children elements to be rendered inside the moderator tile.
 * @param {function} onParticipantClick - The callback function when the moderator tile is clicked.
 * @param {boolean} disableSpeakingIndicator - Whether to disable the speaking indicator for the moderator.
 * @param {object} htmlProps - Additional HTML props to be applied to the moderator tile.
 * @return {JSX.Element} The moderator tile element.
 */

export function Moderator({
  trackRef,
  children,
  onParticipantClick,
  disableSpeakingIndicator,
  ...htmlProps
}: ParticipantTileProps) {
  const trackReference = useEnsureTrackRef(trackRef)
  const { viewType } = useCustomRoomContext()

  const { elementProps } = useParticipantTile<HTMLDivElement>({
    htmlProps,
    disableSpeakingIndicator,
    onParticipantClick,
  })

  return (
    <div
      {...elementProps}
      className={`rounded-md p-1 object-cover ${
        viewType === 'grid' ? 'aspect-[1/1.2] h-[90%]' : 'aspect-[1/1.2] h-[80%]'
      }`}
    >
      <IndividualParticipantPlaceholder
        participant={trackReference.participant}
        trackRef={trackReference}
        source={trackReference.source}
        publication={trackReference.publication}
        isModerator={true}
      >
        {children}
      </IndividualParticipantPlaceholder>
    </div>
  )
}
