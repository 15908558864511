/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import * as React from 'react'
import { ParticipantTileProps, useParticipantTile, useEnsureTrackRef } from '@livekit/components-react'
import { useCustomRoomContext } from '../CustomRoomContext'
import { UserLayoutPosition } from '../../liveKitHelpers'
import IndividualParticipantPlaceholder from './IndividualParticipantPlaceholder'

/**
 * A custom participant tile component for displaying audio participants in a room.
 * It handles participant positioning, hiding, and displaying based on the room's layout and participant metadata.
 *
 * @param {ParticipantTileProps} trackRef - The track reference of the participant.
 * @param {React.ReactNode} children - The children elements to be rendered inside the participant tile.
 * @param {function} onParticipantClick - The callback function when the participant tile is clicked.
 * @param {boolean} disableSpeakingIndicator - Whether to disable the speaking indicator for the participant.
 * @param {object} htmlProps - Additional HTML props to be applied to the participant tile.
 * @return {JSX.Element} The participant tile element.
 */

export function CustomAudioParticipantTile({
  trackRef,
  children,
  onParticipantClick,
  disableSpeakingIndicator,
  ...htmlProps
}: ParticipantTileProps) {
  const trackReference = useEnsureTrackRef(trackRef)

  const { elementProps } = useParticipantTile<HTMLDivElement>({
    htmlProps,
    disableSpeakingIndicator,
    onParticipantClick,
  })
  const [isHiddenParticipant, setIsHiddenParticipant] = React.useState<boolean>(false)
  const { stagedLayoutPositions, stagedParticipants, otherLayoutPositions, viewType } = useCustomRoomContext()

  const [, setUserPosition] = React.useState<UserLayoutPosition>({
    w: 0,
    h: 0,
    row: 0,
    col: 0,
    top: 0,
    left: -50,
  })
  const metadata = trackReference.participant.metadata ? JSON.parse(trackReference.participant.metadata) : {}
  const position = metadata.position
  const isStagedParticipant = stagedParticipants.includes(trackReference.participant.sid)

  React.useLayoutEffect(() => {
    if (
      stagedLayoutPositions &&
      stagedLayoutPositions.length > position &&
      isStagedParticipant &&
      stagedParticipants.length > 0
    ) {
      setUserPosition(stagedLayoutPositions[position])

      if (isHiddenParticipant) {
        setIsHiddenParticipant(false)
      }
    } else if (otherLayoutPositions && otherLayoutPositions.length > position) {
      setUserPosition(otherLayoutPositions[position])

      if (isHiddenParticipant) {
        setIsHiddenParticipant(false)
      }
    }
  }, [
    stagedLayoutPositions,
    stagedParticipants,
    position,
    isStagedParticipant,
    otherLayoutPositions,
    isHiddenParticipant,
  ])

  if (isHiddenParticipant) {
    return <></>
  }
  return (
    <div
      {...elementProps}
      className={`translate-x-0 transition-all ${
        viewType === 'hostHighlighted' ? 'aspect-[1/1.3] w-[7vw] min-w-[5rem]' : 'aspect-[1/1.2] h-[90%]'
      }`}
    >
      <IndividualParticipantPlaceholder
        participant={trackReference.participant}
        trackRef={trackReference}
        source={trackReference.source}
        publication={trackReference.publication}
        // eslint-disable-next-line react/no-children-prop
        children={children}
        isModerator={false}
      />
    </div>
  )
}
