/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router-dom'
import Icon from '../../Components/base/icon/icon'
import { Button } from '../../Components'
import { useTermsAndConditionsHooks } from '../../Hooks/TermsAndConditionsHook'
import { ToolTip } from '../../Components/base/tooltip/tooltip'
import Footer from '../Footer'
import { PageTitle } from '../../Components/pageTitle'
import { useAppSelector } from '../../Store/hooks'
import { getCurrentUser } from '../../Services/userReducer'
import { changeTermsAndCondition, getIsSignUpTermsAccepted } from '../../Services/signUpReducer'
import { useDispatch } from 'react-redux'
import { useUpdateUserTermsPolicyMutation } from '../../Services/userPolicyAgreementsApi'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { toastSuccess } from '../../Components/toast'
import Header from '../Header'
import Field from '../../Components/base/field/field'
import { getToken } from '../../Services/tokenReducer'
import { Accordion, AccordionBorder, AccordionItem, AccordionPanel } from '../../Components/base/accordion/accordion'
import { EventCard } from '../EventsCard/EventCard'
import CardTitle from '../EventsCard/CardTitle'
import { format } from 'date-fns'
import NewIcon from '../../Components/base/icon/newIcons'

/**
 * A component to display the terms and conditions of the application.
 * It retrieves the terms and conditions from the server and displays them
 * in a page with a print button.
 * If the user is not logged in, it will also display a cancel button.
 * If the user is logged in, it will also display a accept button.
 * If the user is not logged in and the terms and conditions have not been accepted,
 * it will display a accept button and a cancel button.
 * If the user is logged in and the terms and conditions have not been accepted,
 * it will display a accept button and a cancel button.
 * When the user clicks the accept button, it will update the user's terms and conditions
 * status in the server.
 * When the user clicks the cancel button, it will navigate to the login page.
 * When the user clicks the print button, it will print the terms and conditions.
 * @returns A JSX element to display the terms and conditions.
 */
function TermsAndConditions() {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userAccessToken = useAppSelector(getToken);
  const currentUser = useAppSelector(getCurrentUser);
  const [showPrintIcon, setShowPrintIcon] = useState<boolean>(true);

  const { hidebutton, policies, subPoliciesData, subPoliciesSuccess, navigateScreen, cancel } = useTermsAndConditionsHooks()
  console.log("subPoliciesData", subPoliciesData, subPoliciesSuccess)
  const [agreeTermsAndPolicies, { isSuccess: userPolicyAgreed, isLoading: userPolicyLoading }] = useUpdateUserTermsPolicyMutation()
  const termsAndConditionStatus = useAppSelector(getIsSignUpTermsAccepted)
  const [toasterView, setToasterView] = useState(false)
  const accordionRef = useRef();
  const [checkboxes, setCheckboxes] = useState<boolean[]>(subPoliciesData ? new Array(subPoliciesData.length).fill(false) : []); // assuming there are 'subPoliciesData.length' checkboxes
  const [isChecked, setIsChecked] = useState(false); // Track if all checkboxes are checked
  
  const handleCheckboxChange = (index: number, checked: boolean) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index] = checked;
    setCheckboxes(newCheckboxes);
  
    setIsChecked(newCheckboxes.every((checkbox) => checkbox === true));
  };
    
  if (navigateScreen === "NAVIGATE_TO_LOGIN") {
    navigate('/signIn')
  }

  /**
   * Print the terms and conditions page.
   * This function sets `showPrintIcon` to false and then calls the `window.print()` method.
   * It also sets up an event listener to reset `showPrintIcon` to true when the print event is finished.
   * This is necessary because the print icon is hidden while the print dialog is open.
   */
  const printPageArea = () => {
    setShowPrintIcon(false)
  }
  const [subTermsContentVisible, setSubTermsContentVisible] = useState(Array(8).fill(false));

  const togglePolicyDescription = (index: number) => {
    setSubTermsContentVisible((prev) =>
      prev.map((visible, i) => (i === index ? !visible : visible))
    );
  };


  useEffect(() => {
    if (!showPrintIcon) {
      window.print()

      window.addEventListener('afterprint', () => {
        setShowPrintIcon(true);
      });
    }
  }, [showPrintIcon])

  useEffect(() => {
    if (termsAndConditionStatus && toasterView) {
      toastSuccess(t('termsAndConditionsAcceptedSuccessfully'))
      navigate('/privacyPolicy');
    }
  }, [termsAndConditionStatus, toasterView])


  useEffect(() => {
    if (userPolicyAgreed) {
      dispatch(changeTermsAndCondition(true));
      toastSuccess(t('termsAndConditionsUpdatedSuccessfully'));
      navigate('/signIn')
    }
  }, [userPolicyAgreed, dispatch, t, navigate]);

  return (
    <div className="flex flex-col h-screen">
      <div className="flex flex-col h-screen">
        <div className="fixed top-0 left-0 w-full z-20 shadow-md px-2 py-0 overflow-auto bg-white">
          {!currentUser && <Header />}

          {/* Terms and Conditions Section */}
          <div className={`flex-grow flex flex-col ${currentUser ? 'h-full' : ''}`}>
            <PageTitle title={t('termsAndConditions')}>
             <div className="flex items-center justify-between w-full mt-0">
              <div className="flex-grow text-center text-black-600 text-sm ml-52">
                Last Updated & Effective Date:
                {policies?.updatedAt && format(new Date(policies.updatedAt), 'dd-MMMM-yyyy')}
              </div>
                <div className="w-64 mr-0">
                  <Field
                    name="title"
                    type="text"
                    placeholder={t('Search')}
                    id="filter_searchByTitle"
                    data-testid="filter_searchByTitle"
                    icon={<NewIcon icon="SEARCH_GLASS" stroke="#535353" />}
                  />
                </div>
              </div>
              <div className="text-center text-black-600 text-sm">
                Version: {policies?.policyVersion}
              </div>
            </PageTitle>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-grow flex flex-col mt-32">
        <PageTitle title={''}>
          <hr className="my-10 mx-auto block w-4/5" />

          <div className="flex flex-col">
            {/* Policy Description */}
            <div
              id="policy-content-scroll"
              className="text-center max-h-80 overflow-y-auto bg-white border-r-0 mx-[10%]"
            >
              <div className="flex-row mx-[8%] justify-content">
                <span
                  dangerouslySetInnerHTML={{
                    __html: policies ? policies?.description : '',
                  }}
                ></span>
              </div>
            </div>

            {/* Accordion Section */}
            <div
              className="mt-6 mx-[10%] h-auto"
            >
              {subPoliciesData &&
                Array.from({ length: subPoliciesData.length }).map((_, index) => (
                  <Accordion key={'acc' + index}>
                    <div
                      data-testid={`policyCard-${index}`}
                      id={`policyCard-${index}`}
                      className="border border-gray-200 rounded-2xl w-full mb-4"
                    >
                      <AccordionItem toggle={'accint' + index} color="card">
                        <EventCard keyId={index}>
                          <EventCard.Image>
                          <input
                              type="checkbox"
                              id="acceptTerms"
                              name="acceptTerms"
                              checked={checkboxes[index]}
                              onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                            />
                          </EventCard.Image>
                          <EventCard.Title>
                            <CardTitle
                              keyId={index}
                              meetingTitle={subPoliciesData[index].policyName}
                            />
                          </EventCard.Title>
                          <EventCard.Actions>
                            <button
                              onClick={() => togglePolicyDescription(index)}
                              className="p-2 rounded focus:outline-none hover:bg-gray-200"
                            >
                              <Icon
                                icon="RIGHT"
                                size="medium"
                                height="large"
                                stroke="#535353"
                                className={`transition-transform duration-300 ${
                                  subTermsContentVisible[index] ? 'rotate-90' : 'rotate-0'
                                }`}
                              />
                            </button>
                          </EventCard.Actions>
                        </EventCard>
                      </AccordionItem>
                      {subTermsContentVisible[index] && (
                        <div 
                          id="policy-content-scroll"
                          className="max-h-60 overflow-y-auto p-4 text-sm"
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: subPoliciesData[index]?.description || '',
                            }}
                          ></span>
                        </div>
                      )}
                    </div>
                  </Accordion>
                ))}
            </div>
          </div>

          {/* Buttons Section */}
          <div className="flex">
            <span className="w-full flex-wrap flex gap-2 mt-3 justify-end mr-28">
              {!hidebutton && (
                <>
                <Button
                  data-testid="btn_accept"
                  id="btn_acceptTerms"
                  className="w-64 normal-case"
                  disabled={!isChecked || userPolicyLoading}
                  onClick={() => {
                    if (currentUser && userAccessToken){
                      agreeTermsAndPolicies(currentUser);
                    } else {
                      dispatch(changeTermsAndCondition(true));
                      setToasterView(true);
                    }
                  }}
                >
                  {t('Accept & continue')}
                </Button>
                </>
              )}
            </span>
          </div>
        </PageTitle>
      </div>

      {!currentUser && <Footer />}
    </div>
  );
}

export default TermsAndConditions
