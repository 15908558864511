/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useEnsureParticipant, useEnsureRoom } from '@livekit/components-react'
import { Participant, LocalParticipant } from 'livekit-client'
import React from 'react'
import { encryptionStatusObservable } from '@livekit/components-core'
import { Observable } from 'rxjs'

/**
 * A custom React hook for managing state derived from an observable.
 *
 * @param {Observable<T> | undefined} observable - The observable to subscribe to.
 * @param {T} startWith - The initial state value.
 * @return {T} The current state value.
 */

export function useObservableState<T>(observable: Observable<T> | undefined, startWith: T) {
  const [state, setState] = React.useState<T>(startWith)
  React.useEffect(() => {
    // observable state doesn't run in SSR
    if (typeof window === 'undefined' || !observable) return
    const subscription = observable.subscribe(setState)
    return () => subscription.unsubscribe()
  }, [observable])
  return state
}

/**
 * A custom React hook for determining if a participant's connection is encrypted.
 *
 * @param {Participant | undefined} participant - The participant to check for encryption. If not provided, the current participant will be used.
 * @return {boolean} Whether the participant's connection is encrypted.
 */

export function useIsEncrypted(participant?: Participant) {
  const p = useEnsureParticipant(participant)
  const room = useEnsureRoom()

  const observer = React.useMemo(() => encryptionStatusObservable(room, p), [room, p])
  const isEncrypted = useObservableState(observer, p instanceof LocalParticipant ? p.isE2EEEnabled : p.isEncrypted)
  return isEncrypted
}

export const addSecondsToDate = (date: Date, seconds: number) => new Date(date.getTime() + seconds * 1000)

/**
 * A custom React hook for managing state derived from an observable.
 *
 * @param {Observable<T> | undefined} observable - The observable to subscribe to.
 * @param {T} startWith - The initial state value.
 * @return {T} The current state value.
 */

export const calculateTimeRemaining = (endTime: Date) => {
  const now = new Date()
  const timeLeft = Math.floor((endTime.getTime() - now.getTime()) / 1000)
  const minutes = Math.floor(timeLeft / 60)
  const seconds = timeLeft - minutes * 60
  const asString = `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
  return {
    timeLeftInSeconds: timeLeft,
    timeLeftString: asString,
    minutes,
    seconds,
  }
}

/**
 * Clones a single child element and applies the given props and key.
 *
 * @param {React.ReactNode | React.ReactNode[]} children - The child element(s) to clone.
 * @param {Record<string, any>} [props] - The props to apply to the cloned child.
 * @param {any} [key] - The key to apply to the cloned child.
 * @return {React.ReactNode[]} The cloned child element(s).
 */

export function cloneSingleChild(
  children: React.ReactNode | React.ReactNode[],
  props?: Record<string, any>,
  key?: any
) {
  return React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child) && React.Children.only(children)) {
      return React.cloneElement(child, { ...props, key })
    }
    return child
  })
}
