/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import * as React from 'react'
import {
  ChatEntry,
  ChatProps,
  formatChatMessageLinks,
  ReceivedChatMessage,
  useDataChannel,
  useLocalParticipant,
  useMaybeLayoutContext,
} from '@livekit/components-react'
import { ToolTip } from '../../base/tooltip/tooltip'
import { cloneSingleChild } from '../LiveKit.utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark, faFilePdf, faMessage, faPaperclip, faPaperPlane, faX } from '@fortawesome/free-solid-svg-icons'
import Draggable from 'react-draggable'
import { useTranslation } from 'react-i18next'
import { MeetingType } from '../../Models/meeting.model'
import { Transition, Dialog } from '@headlessui/react'

/** @public */
export interface ChatToggleProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

/**
 * The ChatToggle component toggles the visibility of the chat component.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <ToggleChat />
 * </LiveKitRoom>
 * ```
 * @public
 */
interface ChatMessages {
  id: string
  timestamp: number
  message: string
  from: any
  media?: string
  mediaType?: 'image' | 'document' | 'audio'
}

/**
 * A customizable chat toggle component that handles chat messaging functionality.
 *
 * @param {object} messageFormatter - A function to format chat messages.
 * @param {object} messageDecoder - A function to decode chat messages.
 * @param {object} messageEncoder - A function to encode chat messages.
 * @param {string} className - The CSS class name for the component.
 * @param {boolean} isPipEnabled - A flag indicating whether Picture-in-Picture mode is enabled.
 * @param {object} props - Additional props for the component.
 * @return {JSX.Element} The chat toggle component.
 */

interface ReceivedChatMessageWithMedia extends ReceivedChatMessage {
  media?: string
  mediaType?: 'image' | 'document' | 'audio'
}
export function CustomChatToggle({
  messageFormatter,
  messageDecoder,
  messageEncoder,
  className,
  isPipEnabled,
  meetingType,
  ...props
}: ChatProps & { isPipEnabled?: boolean; meetingType: MeetingType }) {
  const [isChatOpen, setIsChatOpen] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const ulRef = React.useRef<HTMLUListElement>(null)
  const fileInputRef = React.useRef<HTMLInputElement>(null)

  const { message: chatmsg, send, isSending } = useDataChannel('livekitChat')

  const layoutContext = useMaybeLayoutContext()
  const lastReadMsgAt = React.useRef<ChatMessages['timestamp']>(0)

  const [hasNewMessages, setHasNewMessages] = React.useState(false)
  const [newMessagesCount, setNewMessagesCount] = React.useState(0)
  const [messageLengthExceed, setMessageLengthExceed] = React.useState(false)
  const [chatMessages, setChatMessages] = React.useState<ChatMessages[]>([])
  const { localParticipant } = useLocalParticipant()

  const [isImageModalOpen, setIsImageModalOpen] = React.useState(false)
  const [selectedImage, setSelectedImage] = React.useState<string | null>(null)

  const [isPdfModalOpen, setIsPdfModalOpen] = React.useState(false)
  const [selectedPdf, setSelectedPdf] = React.useState<string | null>(null)

  const { t } = useTranslation()
  const [isModalOpen, setModalOpen] = React.useState(false)

  const capitalizeFirstLetter = (text: string) => {
    if (!text) return ''
    const linkRegex = /(https?:\/\/[^\s]+)/g
    const messageParts = text.split(linkRegex)

    return messageParts
      .map((part) => (part.match(linkRegex) ? part : part.charAt(0).toUpperCase() + part.slice(1)))
      .join('')
  }

  const scrollToBottom = () => {
    if (ulRef && ulRef.current) {
      ulRef.current.scrollTo({ top: ulRef.current.scrollHeight })
    }
  }

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl)
    setIsImageModalOpen(true)
  }

  const closeImageModal = () => {
    setSelectedImage(null)
    setIsImageModalOpen(false)
  }

  React.useEffect(() => {
    if (chatmsg) {
      // Decode chat messages
      const chatMessage = JSON.parse(new TextDecoder().decode(chatmsg.payload)) as ReceivedChatMessageWithMedia

      const newMessage: ChatMessages = {
        id: chatMessage.id,
        timestamp: chatMessage.timestamp,
        message: capitalizeFirstLetter(chatMessage.message),
        from: chatmsg.from,
        media: chatMessage.media || undefined,
        mediaType: chatMessage.mediaType || undefined,
      }
      setChatMessages((prev) => [...prev, newMessage])
    }
  }, [chatmsg])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (inputRef.current || isChatOpen) {
        inputRef.current?.focus()
      }
    }, 10)

    return () => clearTimeout(timer)
  }, [chatMessages, isChatOpen])

  React.useEffect(() => {
    if (isPipEnabled && isChatOpen) {
      setIsChatOpen(false)
    }
  }, [isPipEnabled])

  function handleToggleChat() {
    setIsChatOpen(!isChatOpen)
  }

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault()
    if (inputRef.current) {
      const message = inputRef.current.value.trim()
      if (message !== '') {
        if (message.length > 500) {
          setMessageLengthExceed(true)
          return
        }
        setMessageLengthExceed(false)

        const encodedMessage = new TextEncoder().encode(
          JSON.stringify({ id: localParticipant?.sid, message, timestamp: Date.now() })
        )

        const sentMessage: ChatMessages = {
          id: localParticipant?.sid,
          timestamp: Date.now(),
          message: capitalizeFirstLetter(message),
          from: { name: 'You' },
        }

        send(encodedMessage, { reliable: true })
        setChatMessages((prev) => [...prev, sentMessage])
        inputRef.current.value = ''
        inputRef.current.focus()
      }
    }
  }

  function handleMessageChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.length <= 500) {
      setMessageLengthExceed(false)
    }
  }

  const FileSizeErrorModal = ({ isOpen, closeModal }: { isOpen: boolean; closeModal: () => void }) => {
    return (
      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-1000" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center bg-transparent">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title as="h3" className="text-lg font-semibold text-gray-900">
                      File Size Exceeds Limit
                    </Dialog.Title>
                    <div className="mt-6">
                      <p className="text-sm text-gray-600">
                        File size exceeds the 60 KB limit. Please upload a smaller file or upload files in a drive and
                        share the link.
                      </p>
                    </div>
                    <div className="mt-6 flex justify-end gap-x-3">
                      <button
                        type="button"
                        className="bg-gray-200 py-1 px-4 text-base font-semibold text-black rounded hover:bg-amber-400"
                        onClick={() => closeModal()}
                      >
                        OK
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    )
  }

  const MAX_SIZE = 65536

  function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0]

    if (!file) return

    if (file) {
      if (file.size >= MAX_SIZE) {
        setModalOpen(true)
      } else {
        const reader = new FileReader()
        reader.onloadend = () => {
          let mediaType: 'image' | 'document' | 'audio' = 'image'

          if (file.type.startsWith('audio/')) {
            mediaType = 'audio'
          } else if (file.type === 'application/pdf') {
            mediaType = 'document'
          }
          const newMessage: ChatMessages = {
            id: localParticipant?.sid,
            timestamp: Date.now(),
            message: '',
            from: { name: 'You' },
            media: reader.result as string,
            mediaType,
          }

          const encodedMessage = new TextEncoder().encode(
            JSON.stringify({
              id: localParticipant?.sid,
              message: '',
              timestamp: Date.now(),
              media: reader.result,
              mediaType,
            })
          )

          send(encodedMessage, { reliable: true })
          setChatMessages((prev) => [...prev, newMessage])
        }
        reader.readAsDataURL(file)
      }
    }
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const handlePdfClick = (pdfUrl: string) => {
    setSelectedPdf(pdfUrl)
    setIsPdfModalOpen(true)
  }

  const closePdfModal = () => {
    setSelectedPdf(null)
    setIsPdfModalOpen(false)
  }

  React.useEffect(() => {
    if (!isChatOpen) {
      setNewMessagesCount(chatMessages.length)
    }
  }, [isChatOpen, chatMessages])

  React.useEffect(() => {
    if (!isChatOpen && chatMessages.length > newMessagesCount) {
      setHasNewMessages(true)
    }
  }, [isChatOpen, chatMessages, newMessagesCount])

  React.useEffect(() => {
    if (isChatOpen) {
      setHasNewMessages(false)
      setNewMessagesCount(chatMessages.length)
      scrollToBottom()
      inputRef.current?.focus()
    }
  }, [isChatOpen, chatMessages])

  React.useEffect(() => {
    if (ulRef && ulRef.current && isChatOpen) {
      ulRef.current?.scrollTo({ top: ulRef.current.scrollHeight })
    }
  }, [ulRef, chatMessages, isChatOpen])

  React.useEffect(() => {
    if (!layoutContext || chatMessages.length === 0) {
      return
    }

    if (
      layoutContext.widget.state?.showChat &&
      chatMessages.length > 0 &&
      lastReadMsgAt.current !== chatMessages[chatMessages.length - 1]?.timestamp
    ) {
      lastReadMsgAt.current = chatMessages[chatMessages.length - 1]?.timestamp
      return
    }

    const unreadMessageCount = chatMessages.filter(
      (msg) => !lastReadMsgAt.current || msg.timestamp > lastReadMsgAt.current
    ).length

    const { widget } = layoutContext
    if (unreadMessageCount > 0 && widget.state?.unreadMessages !== unreadMessageCount) {
      widget.dispatch?.({ msg: 'unread_msg', count: unreadMessageCount })
    }
  }, [chatMessages, layoutContext, layoutContext?.widget])

  React.useEffect(() => {
    const lastMessage = ulRef.current?.lastElementChild
    if (lastMessage && isChatOpen) {
      lastMessage.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [chatMessages, isChatOpen])

  return (
    <>
      <ToolTip tip={t('message')}>
        <button
          id="btn_chat"
          className={
            !isPipEnabled
              ? 'py-3 px-4 rounded-l-full rounded-r-full border font-medium bg-gray-200 text-gray-700 hover:bg-gray-50 transition-all text-sm'
              : ''
          }
          onClick={handleToggleChat}
        >
          <FontAwesomeIcon
            icon={faMessage}
            height={'small'}
            className={isChatOpen ? 'text-gray-500' : hasNewMessages ? 'text-yellow-500' : 'text-gray-500'}
          />
        </button>
      </ToolTip>

      {isPdfModalOpen && selectedPdf && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative w-full h-full bg-white p-4 rounded-md">
            <button className="absolute top-2 right-2 text-gray-600 text-xl">
              <FontAwesomeIcon icon={faCircleXmark} onClick={closePdfModal} />
            </button>
            <iframe src={selectedPdf} className="w-full h-full p-3" title="PDF Viewer" />
          </div>
        </div>
      )}

      {isChatOpen && (
        <Draggable handle="#chatHeader">
          <div
            {...props}
            className={`flex flex-col gap-2 w-[20rem] h-[60%] bg-white border-gray-200 border-solid border-2 ${
              isPipEnabled ? 'bottom-[7rem]' : 'bottom-[3rem]'
            } rounded-lg fixed z-50 right-2`}
          >
            {/* Top */}
            <div id="chatHeader" className="flex justify-between bg-amber-500 p-1 rounded-t-md">
              <div>
                <span id="chatHeader" className="text-white">
                  {meetingType === MeetingType.ChatVideoRequest ? t('privateChat') : t('groupChat')}
                </span>
              </div>
              <div className="cursor-pointer" id="btn_CloseChat">
                <FontAwesomeIcon icon={faCircleXmark} onClick={() => setIsChatOpen(!isChatOpen)} />
              </div>
            </div>

            {/* Messages */}
            <ul ref={ulRef} className="overflow-y-auto flex-1">
              {props.children
                ? chatMessages.map((msg, idx) =>
                    cloneSingleChild(props.children, {
                      entry: msg,
                      key: idx,
                      messageFormatter,
                    })
                  )
                : chatMessages.map((msg, idx, allMsg) => {
                    let slicedName = msg.from?.name || ''
                    if (slicedName.length > 30) {
                      slicedName = slicedName.slice(0, 30) + '...'
                    }
                    const hideName = idx >= 1 && allMsg[idx - 1].from?.name === slicedName
                    // If the time delta between two messages is bigger than 60s show timestamp.
                    // const hideTimestamp = idx >= 1 && msg.timestamp - allMsg[idx - 1].timestamp < 60_000

                    const slicedMsg = {
                      ...msg,
                      from: { ...msg.from, name: slicedName },
                      message: capitalizeFirstLetter(msg.message),
                    } as ChatMessages

                    // Media handling for images and documents
                    return (
                      <li key={idx} className={`pl-2 ${msg.mediaType ? 'mb-3' : ''}`}>
                        <div className="flex flex-col">
                          <div className="flex justify-between items-center mb-1">
                            {!hideName && (
                              <>
                                <strong className="lk-participant-name text-sm text-gray-700">
                                  {msg.from?.name ?? msg.from?.identity}
                                </strong>
                                <span className="lk-meta-data text-xs pr-2">
                                  {new Date(msg.timestamp).toLocaleTimeString(navigator.language, {
                                    timeStyle: 'short',
                                  })}
                                </span>
                              </>
                            )}
                          </div>

                          {msg.mediaType === 'audio' ? (
                            <div className="flex flex-col w-full">
                              <audio controls className="w-full mt-1">
                                <source src={msg.media as string} type="audio/mpeg" />
                                Your browser does not support the audio element.
                              </audio>
                            </div>
                          ) : msg.mediaType === 'image' ? (
                            <img
                              src={msg.media}
                              alt="Image"
                              className="w-20 h-20 object-cover cursor-pointer mb-1"
                              onClick={() => handleImageClick(msg.media as string)}
                            />
                          ) : msg.mediaType === 'document' ? (
                            <div
                              className="w-20 h-20 bg-gray-200 rounded-md flex justify-center items-center cursor-pointer mb-1"
                              onClick={() => handlePdfClick(msg.media as string)}
                            >
                              <FontAwesomeIcon icon={faFilePdf} className="text-red-600" />
                            </div>
                          ) : (
                            <ChatEntry
                              key={idx}
                              hideName={true}
                              hideTimestamp={true}
                              entry={slicedMsg}
                              messageFormatter={formatChatMessageLinks}
                            />
                          )}
                        </div>
                      </li>
                    )
                  })}
            </ul>

            {/* Chat Input & File Upload */}
            <div className="flex">
              <form
                onSubmit={handleSubmit}
                className="flex items-center p-1 w-full h-12 border border-gray-300 rounded-full m-2"
              >
                <input
                  disabled={isSending}
                  ref={inputRef}
                  className="bg-transparent relative flex flex-1 w-full rounded-md rounded-l-full py-2 pl-4 pr-10 text-gray-400 placeholder:text-sm placeholder-gray-400 text-base focus:outline-none focus:border-transparent h-full"
                  type="text"
                  id="input_typeMsg"
                  placeholder={t('typeAMessage')}
                  onChange={handleMessageChange}
                />

                <div className="flex items-center justify-center pl-2 pr-1">
                  <ToolTip tip={t('attach')}>
                    <button
                      type="button"
                      className="cursor-pointer text-gray-400 p-1"
                      onClick={() => fileInputRef.current?.click()}
                    >
                      <FontAwesomeIcon icon={faPaperclip} size="lg" />
                    </button>
                  </ToolTip>
                </div>

                <div>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="audio/*,image/*,.pdf"
                    onChange={handleFileUpload}
                    style={{ display: 'none' }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                      }
                    }}
                  />
                  <FileSizeErrorModal isOpen={isModalOpen} closeModal={closeModal} />
                </div>

                <ToolTip tip={t('send')}>
                  <button
                    type="submit"
                    className="p-1 mr-1 text-gray-500 rounded-full"
                    disabled={isSending || messageLengthExceed}
                  >
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </ToolTip>
                {messageLengthExceed && (
                  <div id="msgLengthError" className="text-red-500 p-0.5 text-sm">
                    {t('messageLengthExceeds')} 500 {t('characters')}
                  </div>
                )}
              </form>
            </div>
          </div>
        </Draggable>
      )}

      {isImageModalOpen && selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative">
            <img src={selectedImage} alt="Full view" className="max-w-full max-h-full" />
            <button
              className="absolute top-2 right-2 text-gray-500 bg-white rounded-full px-1"
              onClick={closeImageModal}
            >
              <FontAwesomeIcon icon={faX} size="1x" />
            </button>
          </div>
        </div>
      )}
    </>
  )
}
