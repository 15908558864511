/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable no-empty-pattern */
/* eslint-disable react/no-unescaped-entities */
import * as React from 'react'
import { log, computeMenuPosition } from '@livekit/components-core'
// import type { LocalAudioTrack, LocalVideoTrack } from 'livekit-client'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { CustomMediaDeviceSelect } from './CustomMediaDeviceSelect'
import { DisplayIcon } from './LivekitCustomTrackToggle'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { usePermissionCheckHooks } from '../livekitHooks'
import { MediaDeviceMenuProps } from '@livekit/components-react'

export interface CustomMediaDeviceMenuProps {
  isJoinView?: boolean
  bottom?: string
}

/**
 * A customizable media device menu component that allows users to select their media devices.
 *
 * @param {MediaDeviceKind} kind - The kind of media device (e.g. 'audioinput', 'videoinput', etc.)
 * @param {string} initialSelection - The initial selection of the media device
 * @param {function} onActiveDeviceChange - The callback function when the active device changes
 * @param {Partial<Record<MediaDeviceKind, LocalAudioTrack | LocalVideoTrack | undefined>>} tracks - The tracks of the media devices
 * @param {boolean} requestPermissions - Whether to request permissions for the media devices
 * @param {boolean} isJoinView - Whether the component is being used in a join view
 * @param {string} bottom - The bottom position of the menu
 * @param {...React.ButtonHTMLAttributes<HTMLButtonElement>} props - Additional button properties
 * @return {JSX.Element} The media device menu component
 */

export function CustomMediaDeviceMenu({
  kind,
  initialSelection,
  onActiveDeviceChange,
  tracks,
  requestPermissions = false,
  isJoinView = false,
  bottom,
  ...props
}: MediaDeviceMenuProps & CustomMediaDeviceMenuProps) {
  // const [isOpen, setIsOpen] = React.useState(false)
  const [devices, setDevices] = React.useState<MediaDeviceInfo[]>([])
  const [updateRequired, setUpdateRequired] = React.useState<boolean>(true)
  const { isAudioPermissionGrantedError, isVideoPermissionGrantedError } = usePermissionCheckHooks()

  const handleActiveDeviceChange = (kind: MediaDeviceKind, deviceId: string) => {
    log.debug('handle device change')
    // setIsOpen(false)
    onActiveDeviceChange?.(kind, deviceId)
  }
  const menuIconStyle = `py-3 px-4 inline-flex justify-center items-center gap-2 -ml-px first:ml-0 last:rounded-r-full border font-medium bg-gray-200 text-gray-700 align-middle ${
    (isAudioPermissionGrantedError === false && kind === 'audioinput') ||
    (isVideoPermissionGrantedError === false && kind === 'videoinput')
      ? 'hover:bg-gray-50'
      : 'cursor-not-allowed'
  } transition-all text-sm`

  const button = React.useRef<HTMLButtonElement>(null)
  const tooltip = React.useRef<HTMLDivElement>(null)
  const menuItemsRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (menuItemsRef.current) {
      menuItemsRef.current.style.bottom = `${bottom}px`
    }
  }, [bottom])

  React.useLayoutEffect(() => {
    if (button.current && tooltip.current && (devices || updateRequired)) {
      computeMenuPosition(button.current, tooltip.current).then(({ x, y }) => {
        if (tooltip.current) {
          Object.assign(tooltip.current.style, { left: `${x}px`, top: `${y}px` })
        }
      })
    }
    setUpdateRequired(false)
  }, [button, tooltip, devices, updateRequired])
  const [isOpen, setIsOpen] = React.useState(true)

  function closeModal() {
    setIsOpen(false)
  }

  function handleToolTipText() {
    if (kind === 'audioinput') return isAudioPermissionGrantedError ? 'No Audio devices found' : 'Audio Devices'
    if (kind === 'videoinput') return isVideoPermissionGrantedError ? 'No Video devices found' : 'Video Devices'
    return ''
  }

  return (
    <>
      {/** only render when enabled in order to make sure that the permissions are requested only if the menu is enabled */}
      {!props.disabled && (
        <Menu>
          <Menu.Button>
            {(kind === 'audioinput' || kind === 'audiooutput') && (
              <button className={menuIconStyle} {...props} ref={button} disabled={isAudioPermissionGrantedError}>
                {props.children}
                <DisplayIcon icon={faChevronUp} isAllowed={true} tipName={handleToolTipText()} />
              </button>
            )}
            {kind === 'videoinput' && (
              <button className={menuIconStyle} {...props} ref={button} disabled={isVideoPermissionGrantedError}>
                {props.children}
                <DisplayIcon icon={faChevronUp} isAllowed={true} tipName={handleToolTipText()} />
              </button>
            )}
          </Menu.Button>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={`absolute z-10 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none 
                ${kind === 'audioinput' ? '-translate-y-48' : 'mt-2'}`}
              ref={menuItemsRef}
            >
              <Menu.Item>
                {({}) => (
                  <>
                    {!isAudioPermissionGrantedError && kind === 'audioinput' && (
                      <div className="p-4 bg-gray-200">
                        <h2 className="text-lg font-semibold mb-4">Input Devices</h2>
                        {!isAudioPermissionGrantedError && (
                          <CustomMediaDeviceSelect
                            initialSelection={initialSelection}
                            onActiveDeviceChange={(deviceId) => handleActiveDeviceChange('audioinput', deviceId)}
                            onDeviceListChange={setDevices}
                            kind="audioinput"
                            track={tracks?.[kind]}
                            requestPermissions={requestPermissions}
                          />
                        )}
                        <h2 className="text-lg font-semibold mt-4 mb-4">Output Devices</h2>
                        {!isAudioPermissionGrantedError && (
                          <CustomMediaDeviceSelect
                            initialSelection={initialSelection}
                            onActiveDeviceChange={(deviceId) => handleActiveDeviceChange('audiooutput', deviceId)}
                            onDeviceListChange={setDevices}
                            kind="audiooutput"
                            track={tracks?.[kind]}
                            requestPermissions={requestPermissions}
                          />
                        )}
                      </div>
                    )}
                    {!isVideoPermissionGrantedError && kind === 'videoinput' && (
                      <CustomMediaDeviceSelect
                        initialSelection={initialSelection}
                        onActiveDeviceChange={(deviceId) => handleActiveDeviceChange(kind, deviceId)}
                        onDeviceListChange={setDevices}
                        kind={kind}
                        track={tracks?.[kind]}
                        requestPermissions={requestPermissions}
                      />
                    )}
                  </>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
      {isAudioPermissionGrantedError && (
        <>
          <Transition appear show={isOpen} as={React.Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => {
                setIsOpen(false)
              }}
            >
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black/25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Permission Denied
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          You don't have permission to access your media devices. Please allow access in your browser
                          settings.
                        </p>
                      </div>

                      <div className="mt-4">
                        <div className="justify-end flex">
                          <button
                            type="button"
                            className="bg-gray-200 py-1 text-sm uppercase focus:ring-0 hover:bg-amber-400 font-semibold rounded px-2 text-black"
                            onClick={closeModal}
                          >
                            Got it, thanks!
                          </button>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      )}
    </>
  )
}
