/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import * as React from 'react'
import type { LocalAudioTrack, LocalVideoTrack } from 'livekit-client'
import { useMaybeRoomContext, useMediaDeviceSelect } from '@livekit/components-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'

/** @public */
export interface MediaDeviceSelectProps extends React.HTMLAttributes<HTMLUListElement> {
  kind: MediaDeviceKind
  onActiveDeviceChange?: (deviceId: string) => void
  onDeviceListChange?: (devices: MediaDeviceInfo[]) => void
  onDeviceSelectError?: (e: Error) => void
  initialSelection?: string
  /** will force the browser to only return the specified device
   * will call `onDeviceSelectError` with the error in case this fails
   */
  exactMatch?: boolean
  track?: LocalAudioTrack | LocalVideoTrack
  /**
   * this will call getUserMedia if the permissions are not yet given to enumerate the devices with device labels.
   * in some browsers multiple calls to getUserMedia result in multiple permission prompts.
   * It's generally advised only flip this to true, once a (preview) track has been acquired successfully with the
   * appropriate permissions.
   *
   * @see {@link MediaDeviceMenu}
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/enumerateDevices | MDN enumerateDevices}
   */
  requestPermissions?: boolean
  icon?: any
}

/**
 * Renders a list of media devices of a specific kind and allows the user to select one.
 *
 * @param {MediaDeviceSelectProps} props - The properties for the media device select.
 * @param {MediaDeviceKind} props.kind - The kind of media device.
 * @param {string} props.initialSelection - The initial selection of the media device.
 * @param {function} props.onActiveDeviceChange - The callback function when the active device changes.
 * @param {function} props.onDeviceListChange - The callback function when the device list changes.
 * @param {function} props.onDeviceSelectError - The callback function when there is an error selecting a device.
 * @param {boolean} props.exactMatch - Whether to force the browser to only return the specified device.
 * @param {LocalAudioTrack | LocalVideoTrack} props.track - The track of the media device.
 * @param {boolean} props.requestPermissions - Whether to request permissions for the media devices.
 * @param {any} props.icon - The icon to display for the device.
 * @return {JSX.Element} The media device select component.
 */

export function CustomMediaDeviceSelect({
  kind,
  initialSelection,
  onActiveDeviceChange,
  onDeviceListChange,
  onDeviceSelectError,
  exactMatch,
  track,
  requestPermissions,
  icon,
  ...props
}: MediaDeviceSelectProps) {
  const room = useMaybeRoomContext()
  const { devices, activeDeviceId, setActiveMediaDevice } = useMediaDeviceSelect({
    kind,
    room,
    track,
    requestPermissions,
  })

  React.useEffect(() => {
    if (initialSelection !== undefined) {
      setActiveMediaDevice(initialSelection)
    }
  }, [initialSelection, setActiveMediaDevice])

  React.useEffect(() => {
    if (typeof onDeviceListChange === 'function') {
      onDeviceListChange(devices)
    }
  }, [onDeviceListChange, devices])

  React.useEffect(() => {
    if (activeDeviceId && activeDeviceId !== '') {
      onActiveDeviceChange?.(activeDeviceId)
    }
  }, [activeDeviceId, onActiveDeviceChange])

  const handleActiveDeviceChange = async (deviceId: string) => {
    try {
      await setActiveMediaDevice(deviceId, { exact: exactMatch })
    } catch (e) {
      if (e instanceof Error) {
        onDeviceSelectError?.(e)
      } else {
        throw e
      }
    }
  }
  // Merge Props
  //   const mergedProps = React.useMemo(
  //     () => mergeProps(props, { className }, { className: 'lk-list' }),
  //     [className, props],
  //   );

  function isActive(deviceId: string, activeDeviceId: string, index: number) {
    return deviceId === activeDeviceId || (index === 0 && activeDeviceId === 'default')
  }

  return (
    <ul className="flex flex-col gap-y-2 bg-white border border-gray-400 rounded-md p-2 " {...props}>
      {devices.map((device, index) => (
        <li
          key={device.deviceId}
          id={device.deviceId}
          data-lk-active={isActive(device.deviceId, activeDeviceId, index)}
          aria-selected={isActive(device.deviceId, activeDeviceId, index)}
          role="option"
        >
          <div className="flex flex-row items-center gap-x-2 hover:bg-gray-200 rounded-md px-2 py-1">
            <button className=" text-black text-sm" onClick={() => handleActiveDeviceChange(device.deviceId)}>
              {device.label}
            </button>
            {isActive(device.deviceId, activeDeviceId, index) && (
              //  icon='TICK'/>
              <FontAwesomeIcon icon={faCircleCheck} className="bg-transparent" />
            )}
          </div>
        </li>
      ))}
    </ul>
  )
}
