/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useRoomInfo } from '@livekit/components-react'
import { useMeetingEndTimerHook } from '../livekitHooks'
import React, { useMemo } from 'react'
import { useCustomRoomContext } from 'livekit/room/CustomRoomContext'
import { useTranslation } from 'react-i18next'

/**
 * Displays the time left in a meeting and triggers alerts when the meeting is about to end.
 *
 * @param {string} meetingTitle - The title of the meeting.
 * @return {JSX.Element} A JSX element containing the time left and alert indicators.
 */

const DisplayTimeLeft = ({ meetingTitle }: { meetingTitle: string }) => {
  const { timeLeftString, showAlertFiveMin, twoMinAlert, showPing } = useMeetingEndTimerHook()
  const { onMessage } = useCustomRoomContext()
  const { t } = useTranslation()

  React.useEffect(() => {
    if (showAlertFiveMin && onMessage) {
      onMessage(`${t('meetingWillEndIn')} 5 ${t('minutes')}`, 'error')
    }

    if (twoMinAlert && onMessage) {
      onMessage(`${t('meetingWillEndIn')} 2 ${t('minutes')}`, 'error')
    }
  }, [showAlertFiveMin, twoMinAlert, onMessage, t])

  return (
    <>
      <div className="flex flex-row items-center gap-x-3 mr-10">
        <div id="showPing" className={`${showPing ? 'animate-ping w-3 h-3' : 'w-3 h-3'} rounded-full bg-red-600`}></div>
        <div id="meetingTime" className="text-base font-semibold">
          {timeLeftString}
        </div>
      </div>
    </>
  )
}

const LiveKitMeetingTitle = React.memo(() => {
  const { name } = useRoomInfo()

  const newDisplayTimeLeft = useMemo(() => {
    return <DisplayTimeLeft meetingTitle={name} />
  }, [name])

  return (
    <div className="flex flex-col gap-y-2 items-center justify-between text-black">
      {name && (
        <>
          <div
            id="meetingTitle"
            className="flex absolute text-2xl pt-4 font-semibold lg:text-3xl md:text-2xl sm:text-lg xs:text-xs"
          >
            {name}
          </div>
          <div className="flex mt-14">{newDisplayTimeLeft}</div>
        </>
      )}
    </div>
  )
})

LiveKitMeetingTitle.displayName = 'LiveKitMeetingTitle'

export default LiveKitMeetingTitle
