/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import React from 'react'
import LiveKitMeetingTitle from './LiveKitMeetingTitle'
import ParticipantCount from './ParticipantCount'
import useLanguage from './Language'
import VideoLayout from './VideoLayout'
import { ToolTip } from 'base/tooltip/tooltip'
import { t } from 'i18next'

interface Props {
  onClick: any
  logo?: string
}

/**
 * A functional component that renders the LiveKit header, including the logo, meeting title, video layout, language dropdown, and participant count.
 *
 * @param {Props} props - The component props, including the onClick event handler.
 * @return {JSX.Element} The rendered LiveKit header component.
 */

const LiveKitHeader = ({ onClick, logo }: Props) => {
  const { isDropdownOpen, toggleDropdown, handleChange, dropdownRef, currentLanguage } = useLanguage()
  const language = (
    <svg
      className="xs:w-5 xs:h-5 sm:w-5 sm:h-5 md:w-6 md:h-6 lg:w-7 lg:h-7"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 41C33.3888 41 41 33.3888 41 24C41 14.6112 33.3888 7 24 7C14.6112 7 7 14.6112 7 24C7 33.3888 14.6112 41 24 41Z"
        stroke="#010101"
        strokeWidth="1.75"
        strokeMiterlimit="10"
      />
      <path
        d="M24 41C28.9706 41 33 33.3888 33 24C33 14.6112 28.9706 7 24 7C19.0294 7 15 14.6112 15 24C15 33.3888 19.0294 41 24 41Z"
        stroke="#010101"
        strokeWidth="1.75"
        strokeMiterlimit="10"
      />
      <path
        d="M36 11.9C34.1 14.9 29.1 17 24 17C18.9 17 13.9 14.9 12 11.9"
        stroke="#010101"
        strokeWidth="1.75"
        strokeMiterlimit="10"
      />
      <path
        d="M12 36.1C13.9 33.1 18.9 31 24 31C29.1 31 34.1 33.1 36 36.1"
        stroke="#010101"
        strokeWidth="1.75"
        strokeMiterlimit="10"
      />
      <path d="M7 24H41" stroke="#010101" strokeWidth="1.75" strokeMiterlimit="10" />
      <path d="M24 7V41" stroke="#010101" strokeWidth="1.75" strokeMiterlimit="10" />
    </svg>
  )

  return (
    <div className="flex items-center justify-between bg-white text-black xs:px-2 md:px-[2.5%]">
      <div className="flex-1 flex justify-start">
        {logo ? (
          <img src={logo} alt="logo" className="w-12 h-12" onClick={onClick} />
        ) : (
          <div className="w-12 h-12" aria-hidden="true" />
        )}
      </div>
      <div className="flex-1 flex flex-col gap-y-1 justify-center items-center">
        <LiveKitMeetingTitle />
      </div>
      <div className="flex-1 flex justify-end items-center h-16">
        <div className="relative pr-2">
          <VideoLayout />
        </div>
        <div className="relative pr-2" ref={dropdownRef}>
          {/* <ToolTip tip={t('language')}>
            <button
              id="dropdownDefaultButton"
              className="px-1 py-1 dropdown-trigger cursor-pointer"
              type="button"
              onClick={toggleDropdown}
            >
              <div>{language}</div>
            </button>
          </ToolTip> */}

          {isDropdownOpen && (
            <div id="dropdown" className="z-10 divide-y rounded-lg shadow w-44 absolute right-0 top-12 bg-white">
              <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                {currentLanguage === 'en' && (
                  <li>
                    <div
                      onClick={() => handleChange('ja')}
                      id="lang_jp"
                      className="py-2 px-5 text-left block hover:bg-gray-100 dark:hover:bg-gray-100 dark:hover:text-black bg-white text-black rounded my-1 mx-2 cursor-pointer"
                    >
                      日本語
                    </div>
                  </li>
                )}
                {currentLanguage === 'ja' && (
                  <li>
                    <div
                      onClick={() => handleChange('en')}
                      id="lang_en"
                      className="py-2 px-5 text-left block hover:bg-gray-100 dark:hover:bg-gray-100 dark:hover:text-black bg-white text-black rounded my-1 mx-2 cursor-pointer"
                    >
                      English
                    </div>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
        <div className="relative">
          <ParticipantCount />
        </div>
      </div>
    </div>
  )
}

export default LiveKitHeader
