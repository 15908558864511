/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import Tippy from '@tippyjs/react'
import React, { ReactNode } from 'react'
import 'tippy.js/themes/translucent.css'

interface Props {
  title: JSX.Element | string
  position: 'top' | 'bottom'
  children: ReactNode
  className: string
  iconClassName?: string
  cursor?: boolean
  keyId?: string
}

const positions = {
  top: 'bottom-full',
  bottom: 'top-full',
}

/**
 * A React functional component that renders a tooltip with a title and children.
 * The tooltip's position, cursor style, and icon can be customized.
 *
 * @param {string} keyId - A unique identifier for the tooltip.
 * @param {'top' | 'bottom'} position - The position of the tooltip relative to its children.
 * @param {JSX.Element | string} title - The title of the tooltip.
 * @param {ReactNode} children - The content of the tooltip.
 * @param {string} className - Additional CSS classes for the tooltip.
 * @param {string} [iconClassName] - CSS classes for the tooltip icon.
 * @param {boolean} [cursor=true] - Whether the tooltip should have a pointer cursor.
 * @return {JSX.Element} The rendered tooltip component.
 */

const Tooltip = ({ keyId, position, title, children, className, iconClassName, cursor = true }: Props) => (
  <div
    id={`title-${keyId}`}
    className={`${cursor ? 'group relative text-center cursor-pointer' : 'group relative text-center cursor-default'}`}
  >
    {title}
    <div
      data-testid={`message-${children}`}
      id={`message-${children}`}
      className={`${positions[position]} ${className} opacity-0 text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 -left-8 mr-20 px-3 pointer-events-none`}
    >
      {children}
      <svg
        className={`${iconClassName} absolute h-2 w-full left-0 top-full`}
        x="0px"
        y="0px"
        viewBox="0 0 255 255"
        xmlSpace="preserve"
      >
        <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
      </svg>
    </div>
  </div>
)
interface TooltipProps {
  tip: string
  children: ReactNode
  keyId?: string
  theme?:
    | 'light'
    | 'light-border'
    | 'material'
    | 'translucent'
    | 'BeeMG-light-gray'
    | 'BeeMG-orange'
    | 'BeeMG-yellow'
    | 'BeeMG-green'
    | 'BeeMG-red'
    | 'transparent-dark'
  cursor?: 'pointer' | 'default'
  trElements?: {}
  trigger?: 'click'
  isCapitalize?: boolean
}

/**
 * A customizable tooltip component.
 *
 * @param {string} tip - The text to be displayed in the tooltip.
 * @param {ReactNode} children - The content to be wrapped with the tooltip.
 * @param {string} [keyId] - A unique identifier for the tooltip.
 * @param {'light' | 'light-border' | 'material' | 'translucent' | 'BeeMG-light-gray' | 'BeeMG-orange' | 'BeeMG-yellow' | 'BeeMG-green' | 'BeeMG-red' | 'transparent-dark'} [theme='BeeMG-light-gray'] - The visual theme of the tooltip.
 * @param {'pointer' | 'default'} [cursor='default'] - The cursor style when hovering over the tooltip.
 * @param {'click'} [trigger] - The event that triggers the tooltip.
 * @param {boolean} [isCapitalize] - Whether to capitalize the tooltip text.
 * @return {JSX.Element} The rendered tooltip component.
 */

export const ToolTip = ({
  tip,
  children,
  keyId,
  theme = 'BeeMG-light-gray',
  cursor = 'default',
  trigger,
  isCapitalize,
}: TooltipProps) => {
  return (
    <div id={`tooltip_${tip}`} data-testid={`tooltip_${tip}`}>
      <Tippy
        duration={1}
        className={isCapitalize ? 'capitalize' : ''}
        theme={theme}
        content={tip}
        offset={[0, 8]}
        trigger={trigger}
      >
        <div className={`cursor-${cursor} group relative text-center `}>{children}</div>
      </Tippy>
    </div>
  )
}

export default Tooltip
