/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {baseQueryWithInterceptor} from './api';

export const allApis = createApi({
  reducerPath: 'allApi',
  baseQuery: baseQueryWithInterceptor,

  tagTypes: [
    'CancellationRequest',
    // 'Category',
    // 'ContactFeedbackAttachements',
    'InviteList',
    // 'Ledger','razorPaySuccess',
    'Preference',
    'ReRegisterAfterCancel',
    'CurrentUser',
    'UpcomingMeeting',
    'MeetingList',
    'FeedbackMeetingDetails',
    'FeedbackMeeting',
    'FeedbackCountAndAverage',
    'MeetingDetails',
    'MeetingDetail',
    'FavMeetingList',
    'MeetingParticipant',
    'AttendingMeeting',
    'CoHost',
    'AddMeetingDetailsImage',
    'DeleteMeetingDetailsImage',
    'UpdateMeetingDetailsImage',
    'MeetingNotificationRemainder',
    'MeetingRequest',
    'MeetingRequestByUser',
    'MeetingSelectedDate',
    'FutureMeeting',
    'AttendingMeeting',
    'MeetingByID',
    'MeetingRegister',
    'MeetingRegisterAllMeeting',
    'MeetingRegisterForScheduledMeetings',
    'MeetingList',
    'MeetingFavorite',
    'PaidInviteList',
    'EditInvitedList',
    'NotificationBadge',
    'MeetingDelete',
    'PastEventMeeting',
    'EventVideoUpload',
    'RazorpayAccount',
    'MeetingNotificationCount',
    'VideoRequest',
    'DeleteCoHost',
    'VideoMeeting',
    'nextEventHeader',
    'refundRequest',
    'refundCategory',
    'UserPolicyAgreements',
    'UnreadChatCount',
    'AvatarImageUpload',
    'blockedRequests',
    'blockedChats',
    'rfmMeetingFeedback',
    'ServerDateTime',
    'UploadFile',
    'OpenAIImages'
  ],

  endpoints: builder => ({}),
});
