/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */
import {MeetingDetails} from '../models/meetingDetails.model';
import {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../Store/hooks';
import {useGetSettingValue} from '../Services/settingReducer';
import {getCurrentUser, getPreference} from '../Services/userReducer';
import {useGetPaymentQuery} from '../Services/allOthersApi';
import {ledgerApi, useRazorPaySuccessMutation} from '../Services/ledgerApi';
import {
  meetingParticipantApi,
  useCreateParticipantMutation,
} from '../Services/meetingParticipantApi';
import {useUpdateFavoriteMeetingMutation} from '../Services/favoriteApi';
import {Meeting, MeetingType} from '../models/meeting.model';
import {Verified} from '../models/user.model';
import {useMeetingJoinHooks} from './AttendingHooks';
import {useGetMeetingQuery} from '../Services/meetingApi';
import {getCreatorEventById} from '../Services/creatorReducer';
import {MeetingStatus, useCheckRegisteredHook} from './livekitHooks';
import useRazorpay, {RazorpayOptions} from 'react-razorpay';
import beemgLogo from '../Assets/Images/beeMG_logo-icon-notext-04.svg';
/**
 * A custom React hook for handling creator card functionality.
 *
 * It provides various functions and state variables for managing meeting registrations, payments, and favorites.
 *
 * @param {Meeting} meeting - The meeting object containing details about the meeting.
 * @param {MeetingDetails} meetingDetails - The meeting details object containing additional information about the meeting.
 * @param {string} eventsTitle - The title of the events.
 *
 * @return {Object} An object containing various functions and state variables for managing creator card functionality.
 */
export const useCreatorCardHook = (
  meeting: Meeting,
  meetingDetails: MeetingDetails,
  eventsTitle: string,
) => {
  const imageURL = useGetSettingValue('IMAGE_URL');
  const dispatch = useAppDispatch();
  const preference = useAppSelector(getPreference);
  const currentUser = useAppSelector(getCurrentUser);
  const meetingResult = useAppSelector(state =>
    getCreatorEventById(state, meeting.id),
  );
  const [loadMeetingResults, setLoadMeetingResults] = useState<boolean>(false);
  const [triggerRefetchMeeting, setTriggerRefetchMeeting] =
    useState<boolean>(false);
  const [meetingToDisplay, setMeetingToDisplay] = useState<Meeting>(meeting);
  const [Razorpay] = useRazorpay();
  const [razorPaySuccess] = useRazorPaySuccessMutation<any>();
  const [registerFreeEvent, {isSuccess: registerSuccess}] =
    useCreateParticipantMutation<any>();
  const [addFavorite] = useUpdateFavoriteMeetingMutation();
  const {refetch: refetchMeetingResult} = useGetMeetingQuery(meeting.id, {
    skip: !loadMeetingResults,
  });
  const [navigateScreenLink, setNavigateScreenLink] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [razorpayOpen, setRazorpayOpen] = useState(false);
  const [navigateTo, setNavigateTo] = useState<boolean>(false);
  const [creatorDisplayButton, setCreatorDisplayButton] = useState<
    | 'JOIN'
    | 'SOLDOUT'
    | 'PAYNOW'
    | 'PAID'
    | 'REGISTER'
    | 'REGISTERED'
    | 'COHOST'
    | 'INVITED'
    | 'SHOW'
  >();
  const [creatorDisplayButtonLive, setCreatorDisplayButtonLive] = useState<
    'JOIN' | 'LIMITREACHED' | 'SHOW'
  >();
  const {meetingStatus} = useCheckRegisteredHook(meeting);
  const [eventTitleForID] = useState<string>(eventsTitle?.split(' ').join(''));
  const {joinTime} = useMeetingJoinHooks({meeting});
  const {data: paymentDetailIndia} = useGetPaymentQuery('INR');
  useEffect(() => {
    if (
      !meeting?.meetingDetails?.isFreeAudioMeeting &&
      meeting?.seatsAvailable > 0 &&
      !meeting?.paidStatus &&
      currentUser?.id !== meeting?.meetingDetails?.host?.id &&
      currentUser?.verified !== Verified.NotComplete &&
      !meeting.invitedUserStatus
    ) {
      setCreatorDisplayButton('PAYNOW');
    } else if (
      !meeting?.meetingDetails?.isFreeAudioMeeting &&
      meeting?.paidStatus &&
      !meetingDetails.isCoHost &&
      currentUser?.id !== meeting?.meetingDetails?.host?.id &&
      currentUser?.verified !== Verified.NotComplete &&
      !meeting.invitedUserStatus
    ) {
      if (joinTime) {
        setCreatorDisplayButton('JOIN');
        if(meeting.type === MeetingType.Type1){
          setCreatorDisplayButton('JOIN');
        } else if(meeting.type === MeetingType.MovieStream){
          setCreatorDisplayButton('SHOW');
        }
      } else {
        setCreatorDisplayButton('PAID');
      }
    } else if (
      meetingDetails?.isFreeAudioMeeting === false &&
      meeting?.paidStatus &&
      currentUser?.id !== meetingDetails?.host?.id &&
      currentUser?.verified !== Verified.NotComplete &&
      !meeting.invitedUserStatus
    ) {
      setCreatorDisplayButton('COHOST');
    } else if (
      meetingDetails?.isFreeAudioMeeting === true &&
      meeting?.paidStatus &&
      meetingDetails.isCoHost &&
      meeting.meetingParticipant.length === 0 &&
      currentUser?.id !== meetingDetails?.host?.id &&
      currentUser?.verified !== Verified.NotComplete &&
      !meeting.invitedUserStatus
    ) {
      setCreatorDisplayButton('COHOST');
    } else if (
      meeting?.meetingDetails?.isFreeAudioMeeting &&
      meeting?.seatsAvailable > 0 &&
      !meeting?.paidStatus &&
      currentUser?.id !== meeting?.meetingDetails?.host?.id &&
      currentUser?.verified !== Verified.NotComplete &&
      !meeting.invitedUserStatus
    ) {
      setCreatorDisplayButton('REGISTER');
    } else if (
      meeting?.meetingDetails?.isFreeAudioMeeting &&
      meeting?.paidStatus &&
      currentUser?.id !== meeting?.meetingDetails?.host?.id &&
      currentUser?.verified !== Verified.NotComplete &&
      !meeting.invitedUserStatus
    ) {
      setCreatorDisplayButton('REGISTERED');
    } else if (
      !meeting?.meetingDetails?.isFreeAudioMeeting &&
      !meeting?.paidStatus &&
      !meetingDetails.isCoHost &&
      currentUser?.id !== meeting?.meetingDetails?.host?.id &&
      currentUser?.verified !== Verified.NotComplete &&
      meeting.invitedUserStatus
    ) {
      setCreatorDisplayButton('INVITED');
    }
    if (
      meeting.type === MeetingType.MovieStream &&
      meeting.paidStatus 
    ) {
      setCreatorDisplayButtonLive('SHOW');
    } else if (
      (meetingStatus === MeetingStatus.Registered ||
        meeting.seatsAvailable > 0) &&
      currentUser.verified === Verified.Complete && meeting.type !== MeetingType.MovieStream
    ) {
      setCreatorDisplayButtonLive('JOIN');
    } else if (
      meeting.paidStatus &&
      currentUser.verified === Verified.Complete && meeting.type !== MeetingType.MovieStream
    ) {
      setCreatorDisplayButtonLive('JOIN');
    } else if( meeting.paidStatus &&
      currentUser.verified === Verified.Complete && meeting.type === MeetingType.MovieStream){
      setCreatorDisplayButtonLive('SHOW');
    }
      else if (meeting.seatsAvailable === 0 && !meeting.paidStatus) {
      setCreatorDisplayButtonLive('LIMITREACHED');
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    meetingDetails,
    meeting,
    currentUser?.id,
    currentUser?.verified,
    joinTime,
  ]);
  
  useEffect(() => {
    if (meetingResult) {
      setMeetingToDisplay(meetingResult);
    }
  }, [meetingResult]);
  useEffect(() => {
    if (loadMeetingResults) {
      refetchMeetingResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRefetchMeeting, loadMeetingResults]);
  const handleRegister = (meeting: any, joinAnonymous: boolean) => {
    const meetingResult = {id: meeting.id, joinAnonymous: joinAnonymous};
    registerFreeEvent(meetingResult);
  };
  const handlefav = (meetingList: any) => {
    addFavorite({meeting: meetingList})
      .unwrap()
      .then(() => {
        setLoadMeetingResults(true);
        setTriggerRefetchMeeting(!triggerRefetchMeeting);
      });
  };
  const payment = async (
    id: string,
    meetingDetailsId: string,
    currency: string,
    joinAnonymous: boolean,
  ) => {
    try {
      const guestCount = 1;
      const res = await dispatch(
        ledgerApi.endpoints.payment.initiate({
          id,
          meetingDetailsId,
          guestCount,
          joinAnonymous,
        }),
      );
      if (res.data) {
        if (
          paymentDetailIndia &&
          paymentDetailIndia.currencyList.indexOf(currency) > -1
        ) {
          const options: RazorpayOptions = {
            key: res.data.key_id,
            amount: res.data.amount,
            currency: 'INR',
            name: res.data.name,
            description: 'BeeMG',
            image: beemgLogo,
            order_id: res.data.order_id,
            handler: rzpayResponse => {
              if (rzpayResponse.razorpay_payment_id) {
                onPaymentSuccess(rzpayResponse);
              } else {
                onPaymentFailure(rzpayResponse);
              }
            },
          };
          const rzpay = new Razorpay(options);
          rzpay.open();
          setRazorpayOpen(false);
        } else {
          if (!navigateScreenLink) {
            setNavigateScreenLink(res.data.url);
            setRazorpayOpen(false);
          }
        }
      }
    } catch (error) {
      console.error('Error during payment initiation:', error);
      // Handle errors if needed
    }
  };
  const onPaymentSuccess = (response: any) => {
    const args = {
      orderId: response.razorpay_order_id,
      paymentId: response.razorpay_payment_id,
    };
    const res = razorPaySuccess(args);
    if (!loading) {
      setLoading(true);
    }
    res.then((val: any) => {
      if (!navigateScreenLink) {
        if (loading) {
          setLoading(false);
        }
        setNavigateScreenLink('/s/payment/' + val.data.transactionId);
      }
    });
  };
  const onPaymentFailure = (response: any) => {
    const args = {
      orderId: response.error.metadata.order_id,
      paymentId: response.error.metadata.payment_id,
    };
    dispatch(ledgerApi.endpoints.razorPayFail.initiate(args));
  };
  const handleJoin = (meeting: Meeting) => {
    const meetingResult = Object.assign({}, meeting);
    meetingResult.cost = Number(meeting.cost);
    if (
      meetingResult.paidStatus ||
      currentUser?.id === meetingResult.meetingDetails?.host?.id
    ) {
      if (!navigateTo) {
        setNavigateTo(true);
      }
    } else {
      const res = dispatch(
        meetingParticipantApi.endpoints.createParticipant.initiate(
          meetingResult,
        ),
      );
      res.then((val: any) => {
        if (val) {
          if (!navigateTo) {
            setNavigateTo(true);
          }
        }
      });
    }
  };
  return {
    handleJoin,
    navigateTo,
    creatorDisplayButtonLive,
    creatorDisplayButton,
    registerSuccess,
    handlefav,
    handleRegister,
    preference,
    imageURL,
    currentUser,
    payment,
    navigateScreenLink,
    loading,
    meetingToDisplay,
    meetingStatus,
    eventTitleForID,
    razorpayOpen,
    setRazorpayOpen,
  };
};

