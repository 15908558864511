/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */
import { PlatformFees } from '../models/platformFee.model';
import {allApis} from './allApis';

/**
 * Platform Fee API 
 * 
 * @param {object} builder - The builder object used to define the endpoints.
 * @return {{ getPlatformFees: query }} - An object containing the defined endpoints.
 */

export const platformFeeApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getPlatformFees: builder.query<PlatformFees[], void>({
      query: () => 'platformFee',
      
    }),
    
  }),
});
export const {useGetPlatformFeesQuery} = platformFeeApi;