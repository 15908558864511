/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetSettingValue } from '../Services/settingReducer'


/**
 * A React component for rendering a static image on the movies page.
 *
 * The component renders a static image and overlays two text elements on top of it.
 * The first text element displays the title of the page, and the second text element
 * displays a short description of the page.
 *
 * @returns {ReactElement} - The rendered component.
 */
const MoviesImage: FC = () => {
  const { t } = useTranslation()
  const baseImageURL = useGetSettingValue('IMAGE_URL')

  const style = {
    image: `inline z-0 object-cover w-full h-full`,
    textOverlay1: `w-full text-white text-2xl lg:text-4xl font-semibold text-left`,
    textOverlay2: `w-full text-white text-sm lg:text-lg text-left font-normal`,
  }

  return (
    <>
      <div className="relative w-full overflow-hidden h-[10rem] md:h-[13rem]">
        <img
          className={style.image}
          src={`${baseImageURL}/new/creator-banner.webp`}
          alt="moviesImage"
        />
        <div className="absolute bottom-4 flex flex-col px-4 lg:pl-[10%]">
          <div className={style.textOverlay1}>
            {t('Movies')} {t('network')}
          </div>
          <div className={style.textOverlay2}>
            {t('exploreMoviesFromVariousGenres')}{' '}
            {t('craftedByTalentedCreators')}
          </div>
        </div>
      </div>
      <div className="divide-y divide-fuchsia-300"></div>
    </>
  )
}

export default React.memo(MoviesImage)
