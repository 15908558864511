/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useEnsureParticipant, useParticipantContext, useParticipantInfo } from '@livekit/components-react'
import React, { useEffect, useState } from 'react'
import { UserType } from '../../livekitTypes'
import { useHandRaiseReceivedHook } from '../../livekitHooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHand, faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons'
import PermissionsToasterComp from '../PermissionsToasterComp'
import { useCustomRoomContext } from '../CustomRoomContext'
import { useTranslation } from 'react-i18next'
import AudioLevelIndicator from './AudioLevelIndicator'

interface _Props {
  isModerator: boolean
}

export const defaultAvatar = '/static/media/beemg-icons-creator-camera-2023.7f7bef14aeeda42088aa9bb7c1ddbfd2.svg'
// 'https://qa1.test.beemg.com/static/media/beemg-icons-creator-camera-2023.7f7bef14aeeda42088aa9bb7c1ddbfd2.svg'

/**
 * CustomAudioParticipantPlaceholder is a React component that represents an audio participant in a meeting.
 * It displays the participant's avatar, name, and microphone status, and allows the participant to raise their hand.
 *
 * @param {object} props - The component props.
 * @param {boolean} props.isModerator - Whether the participant is a moderator.
 * @return {JSX.Element} The rendered component.
 */

export function CustomAudioParticipantPlaceholder(props: _Props) {
  const { name, metadata } = useParticipantInfo()
  const participant = useEnsureParticipant()
  const { isMicrophoneEnabled } = useParticipantContext()
  const { allowUnmute, handRaisedParticipants } = useHandRaiseReceivedHook()
  const [isNullPresent, setIsNullPresent] = useState(false)
  let metaData: any
  const fullName = name ? name.replace(/\b\w/g, (c) => c.toUpperCase()) : ''
  const truncatedName = fullName.length > 8 ? fullName.slice(0, 8) + '...' : fullName
  const [isHovered, setIsHovered] = useState(false)
  const p = useEnsureParticipant()
  const { stagedParticipants, viewType } = useCustomRoomContext()
  const { t } = useTranslation()

  let newTruncatedName = ''

  if (fullName.length > 20) {
    const spaceIndex = fullName.indexOf(' ')
    if (spaceIndex !== -1 && spaceIndex <= 18) {
      const firstPart = fullName.slice(0, spaceIndex)
      const secondPart = fullName.slice(spaceIndex + 1)
      newTruncatedName = `${firstPart}\n${secondPart}`
    } else {
      const firstPart = fullName.slice(0, 20)
      const secondPart = fullName.slice(20)
      newTruncatedName = `${firstPart}\n${secondPart}`
    }
  } else {
    newTruncatedName = fullName
  }

  if (metadata) {
    try {
      metaData = JSON.parse(metadata)
    } catch (error) {
      console.error('Error parsing JSON:', error)
    }
  } else {
    console.log('Metadata is empty or null', metadata)
    // Handle the case where metadata is empty or null
    // You can set a default value or perform any other necessary action.
  }

  useEffect(() => {
    if (metaData?.profileImageUrl && metaData?.profileImageUrl.includes('null')) {
      setIsNullPresent(true)
    }
  })

  function HandRaiseToggle({
    participantId,
    filteredHandRaisedParticipants,
    allowUnmute,
  }: {
    participantId: string
    filteredHandRaisedParticipants: string[]
    allowUnmute: () => void
  }) {
    const [isVisible, setIsVisible] = useState<boolean>(true)

    useEffect(() => {
      if (!filteredHandRaisedParticipants.includes(participantId)) {
        setIsVisible(false)
      }
    }, [filteredHandRaisedParticipants, participantId])

    const handleClick = () => {
      allowUnmute()
      setIsVisible(false)
    }

    if (!isVisible || !filteredHandRaisedParticipants.includes(participantId)) {
      return null
    }

    return (
      <>
        <div
          className="absolute top-2 transition-all animate-waving-hand ease-in-out left-2 bg-white z-40 rounded-full shadow-md justify-center items-center p-1 cursor-pointer"
          onClick={handleClick}
          id={`handRaise_${participant.sid.slice(0, -5)}`}
        >
          <FontAwesomeIcon icon={faHand} />
        </div>
      </>
    )
  }

  function filterStagedParticipants(handRaisedParticipants: any[], stagedParticipants: string | any[]) {
    return handRaisedParticipants.filter((id) => !stagedParticipants.includes(id))
  }

  return (
    <>
      <PermissionsToasterComp />
      <div className="flex flex-col justify-center items-center relative h-full w-full">
        {/* Avatar section */}
        <div className="relative w-full aspect-square">
          <div
            className={`relative inline-block ${isHovered ? 'scale-100' : ''}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <AudioLevelIndicator />
            {!isNullPresent ? (
              <img
                className={`rounded-full h-full w-full transition-transform duration-200 ease-in-out ${
                  isHovered ? 'scale-110' : 'scale-100'
                } ${metaData?.userType === UserType.HOST ? 'outline outline-yellow-400' : ''} ${
                  metaData?.userType === UserType.CO_HOST ? 'outline outline-orange-400' : ''
                }`}
                src={metaData?.profileImageUrl}
                alt="avatar"
              />
            ) : (
              <img
                className={`rounded-full h-full w-full transition-transform duration-200 ease-in-out ${
                  isHovered ? 'scale-110' : 'scale-100'
                } ${metaData?.userType === UserType.HOST ? 'outline outline-yellow-400' : ''} ${
                  metaData?.userType === UserType.CO_HOST ? 'outline outline-orange-400' : ''
                }`}
                src={defaultAvatar}
                alt="avatar"
              />
            )}
            <div
              className={`absolute bg-white rounded-full shadow-md p-1 z-10 lg:text-md md:text-sm sm:text-xs xs:text-xs 
              ${
                (metaData?.userType === UserType.HOST || metaData?.userType === UserType.CO_HOST) &&
                viewType === 'hostHighlighted'
                  ? 'bottom-6 right-2'
                  : 'bottom-4 right-0'
              }
              `}
            >
              <FontAwesomeIcon icon={isMicrophoneEnabled ? faMicrophone : faMicrophoneSlash} />
            </div>
          </div>
        </div>

        {/* Name section */}
        <div className="w-full">
          {metaData?.userType === UserType.HOST ? (
            <div
              id={`Host_${participant.sid.slice(0, -5)}`}
              className={`text-black text-center justify-center text-clip overflow-hidden text-nowrap cursor-pointer capitalize ${
                viewType === 'grid'
                  ? 'lg:text-md md:text-sm sm:text-xs xs:text-xs'
                  : 'lg:text-lg md:text-md sm:text-sm xs:text-xs'
              }`}
            >
              {isHovered ? newTruncatedName : truncatedName} <div>({t('host')})</div>
            </div>
          ) : metaData?.userType === UserType.CO_HOST ? (
            <div
              id={`CoHost_${participant.sid.slice(0, -5)}`}
              className={`text-black text-center justify-center text-clip overflow-hidden text-nowrap cursor-pointer capitalize ${
                viewType === 'grid'
                  ? 'lg:text-md md:text-sm sm:text-xs xs:text-xs'
                  : 'lg:text-lg md:text-md sm:text-sm xs:text-xs'
              }`}
            >
              {isHovered ? newTruncatedName : truncatedName} <div>({t('coHost')})</div>
            </div>
          ) : (
            <div
              id={`Participant_${participant.sid.slice(0, -5)}`}
              className="lg:text-md md:text-sm sm:text-xs xs:text-xs text-black text-center justify-center text-clip overflow-hidden text-nowrap cursor-pointer capitalize"
            >
              {isHovered ? newTruncatedName : truncatedName}
            </div>
          )}
        </div>

        <HandRaiseToggle
          participantId={p.sid}
          filteredHandRaisedParticipants={filterStagedParticipants(handRaisedParticipants, stagedParticipants)}
          allowUnmute={allowUnmute}
        />
      </div>
    </>
  )
}
