/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Icon from '../../Components/base/icon/icon';
import {ToolTip} from '../../Components/base/tooltip/tooltip';
import {toastError} from '../../Components/toast';
import {
  useChatErrorAndAllowed,
  useChatUserBlockedHook,
  useCheckOfflineMessages,
} from '../../Hooks/MessageHooks';
import {
  getCurrentChatUser,
  getSendVideoChatRequest,
  sendFileMessage,
  sendTextMessage,
  sendVideoRequest,
  setSendVideoChatRequest,
} from '../../Store/Chat/chat';
import {useAppDispatch, useAppSelector} from '../../Store/hooks';
import NewIcon from '../../Components/base/icon/newIcons';

/**
 * MessageTypeArea
 *
 * This component displays a form with a text input and buttons for sending text messages and video requests.
 *
 * It uses the following hooks:
 * - useForm() for managing the form state
 * - useChatErrorAndAllowed() for checking if the user is allowed to send messages
 * - useChatUserBlockedHook() for checking if the user is blocked by the current chat user
 * - useCheckOfflineMessages() for checking if there are offline messages
 *
 * It also uses the following selectors:
 * - getCurrentChatUser() for getting the current chat user
 * - getSendVideoChatRequest() for getting the send video chat request state
 *
 * It dispatches the following actions:
 * - sendTextMessage() for sending a text message
 * - sendVideoRequest() for sending a video request
 * - setSendVideoChatRequest() for setting the send video chat request state
 *
 * It renders:
 * - A text input for typing messages
 * - A button for sending the message
 * - A button for sending a video request
 *
 * It also renders a tooltip for the video request button
 */
function MessageTypeArea() {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const currentChatUser = useAppSelector(getCurrentChatUser);
  const sendVideoChatRequest = useAppSelector(getSendVideoChatRequest);

  const {
    showUpdatePreferences,
    showCannotSend,
    handleMessageSize,
    maxTextSize,
    videoLimitExceeded,
    hasActiveVideoRequest,
  } = useChatErrorAndAllowed();
  const {isBlockedByMe, isBlockedMe} = useChatUserBlockedHook();
  const {register, handleSubmit, resetField, getValues} = useForm();
  const isSendDisabled =
    (!showUpdatePreferences && !showCannotSend) === false ||
    isBlockedByMe(currentChatUser?.id);

  const [attachedFile, setAttachedFile] = useState<File | null>(null); // State for attached files
  const [preview, setPreview] = useState<string | null>(null); // Preview for attached image

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    console.log("handleFileChange", file)
    if (file) {
      console.log("Inside file", file)
      setAttachedFile(file)
      
      // Generate a preview for images
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = e => setPreview(e.target?.result as string);
        reader.readAsDataURL(file);
      } else if (file.type === 'image/svg+xml') {
        // Handle SVG differently, for example, set it as raw HTML
        const reader = new FileReader();
        reader.onload = e => setPreview(e.target?.result as string); // set the SVG content as a string
        reader.readAsText(file);
      } else {
        setPreview(null);
      }
    }
  };

  const clearAttachment = () => {
    setAttachedFile(null);
    setPreview(null);
  };

  useCheckOfflineMessages();

  // useEffect(() => {
  //   if (attachedFile) {
  //     sendAttachment();
  //   }
  // }, [attachedFile]);

  useEffect(() => {
    if (
      sendVideoChatRequest &&
      currentChatUser &&
      videoLimitExceeded !== undefined &&
      videoLimitExceeded === false
    ) {
      if (hasActiveVideoRequest) {
        dispatch(setSendVideoChatRequest(false));
        handleActiveVideoRequest();
        return;
      }
      dispatch(
        sendVideoRequest({
          toUserId: currentChatUser.id,
          isBlocked: isBlockedMe(currentChatUser?.id),
        }),
      );
      dispatch(setSendVideoChatRequest(false));
    }
  }, [
    currentChatUser,
    sendVideoChatRequest,
    videoLimitExceeded,
    hasActiveVideoRequest,
  ]);

  useEffect(() => {
    if (
      currentChatUser &&
      getValues('message') &&
      getValues('message')?.length > 0 || 
      attachedFile || preview
    ) {
      resetField('message');
      handleMessageSize('');
      clearAttachment();
    }
  }, [currentChatUser]);

  /**
   * Called when the user submits a message.
   * @param {any} data The object containing the message text
   */
  // const onSubmit = (data: any) => {
  //   console.log('01. onSubmit', data);

  //   if (!isSendDisabled && data.message && data.message?.trim() && data.message?.trim().length <= maxTextSize) {
  //     console.log('02. inside if', data, isSendDisabled);
  //     dispatch(sendTextMessage({ messageText: (data?.message).trim(), isBlocked: isBlockedMe(currentChatUser?.id) }))
  //     .then((response: any) => {
  //       console.log('03. response', response);
  //     })
  //     .catch((error: any) => {
  //       console.log('04. error', error);
  //     })

  //     resetField('message')
  //     handleMessageSize("")
  //   }
  //   else {
  //     console.log('05. else error', data, isSendDisabled);
  //   }
  // }

  const onSubmit = (data: any) => {
    if (attachedFile) {
      // Dispatch the new sendFileMessage function
      dispatch(
        sendFileMessage({
          attachedFile,
          toUserId: currentChatUser?.id,
          isBlocked: isBlockedMe(currentChatUser?.id),
          messageText: data.message?.trim(),
        }),
      )
        .then(() => clearAttachment())
        .catch((error: {message: any}) => toastError(error.message));
    } else if (data.message?.trim()) {
      // Send the text message
      dispatch(
        sendTextMessage({
          messageText: data.message.trim(),
          isBlocked: isBlockedMe(currentChatUser?.id),
        }),
      );
    }
    resetField('message');
  };

  /**
   * Called when the user wants to send a video request.
   * It dispatches the sendVideoRequest() action.
   * It also checks if the user has exceeded the video request limit.
   * If the user has exceeded the limit, it does not send the request.
   * If the user is blocked by the other user, it also does not send the request.
   */
  const sendVideoRequestToServer = () => {
    console.log('05. sendVideoRequestToServer');

    if (currentChatUser) {
      console.log('06. inside if', currentChatUser);

      if (videoLimitExceeded) {
        console.log('07. inside if', videoLimitExceeded);
        return;
      }
      console.log('08. after inside if', videoLimitExceeded);

      dispatch(
        sendVideoRequest({
          toUserId: currentChatUser.id,
          isBlocked: isBlockedMe(currentChatUser?.id),
        }),
      )
        .then((response: any) => {
          console.log('09. response', response);
        })
        .catch((error: any) => {
          console.log('10. error', error);
        });
    }
  };

  const sendAttachment = () => {
    console.log('attachedFile', attachedFile);
    if (attachedFile) {
      console.log('attachedFile--1', attachedFile);
      dispatch(
        sendFileMessage({
          attachedFile,
          toUserId: currentChatUser?.id,
          isBlocked: isBlockedMe(currentChatUser?.id),
        }),
      )
        .then(() => {
          console.log('Clear Attachment--2');
        })
        .catch((error: {message: any}) => toastError(error.message));
    }
  };

  /**
   * Handles the case when the user has an active video request and
   * wants to send another one. It shows an error message and returns.
   */
  const handleActiveVideoRequest = () => {
    toastError(t('youHaveAnActiveRequestAlready'));
    return;
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleAttachClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
 
  };

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
        {/* Attachment preview */}
        {attachedFile && (
          <div className="relative flex items-center gap-1 p-2 border border-gray-300 rounded-md">
            {preview ? (
              <div className="relative max-w-full max-h-64">
                <img
                  src={preview}
                  alt="Attachment Preview"
                  className="max-w-full max-h-64 object-contain rounded"
                />
                <button
                  type="button"
                  onClick={clearAttachment}
                  className="absolute top-1 right-1 w-6 h-6 flex items-center justify-center text-white bg-gray-400 hover:bg-gray-300 hover:opacity-80 rounded-full text-sm">
                  ✕
                </button>
              </div>
            ) : (
              <div className="relative w-max flex flex-col items-center justify-between border border-gray-300 rounded-md p-2">
                <div className="flex items-center justify-center mb-2">
                  <Icon
                    icon="PDFICON"
                    size="large"
                    height="small"
                    className="cursor-pointer"
                  />
                </div>
                <div className="w-max text-center text-xs text-gray-600 overflow-hidden text-ellipsis max-w-full">
                  <span>{attachedFile.name}</span>{' '}
                </div>
                <button
                  type="button"
                  onClick={clearAttachment}
                  className="absolute top-1 right-1 w-6 h-6 flex items-center justify-center text-white bg-gray-400 hover:bg-gray-300 hover:opacity-80 rounded-full text-sm">
                  ✕
                </button>
              </div>
            )}
          </div>
        )}

        <div className="flex items-center gap-1">
          {/* Input box */}
          <input
            className={`flex-1 p-2 ml-2 text-xs border border-gray-300 rounded-md outline-none h-full ${
              showUpdatePreferences || showCannotSend ? 'bg-gray-200' : ''
            }`}
            maxLength={parseInt(maxTextSize) + 1}
            {...register('message', {
              onChange: e => {
                handleMessageSize(e.target.value);
              },
              required: !attachedFile ? 'Message is required' : false,
              // required: 'message is required',
            })}
            id="input_typeMessage"
            data-testid="input_typeMessage"
            placeholder={t('startTyping')}
            name="message"
            disabled={!!attachedFile}
          />

           {/* Send button */}
           <button
            type="submit"
            className="flex items-center text-sm text-gray-500 py-2 rounded">
            <ToolTip tip="Send" keyId="send">
              <Icon
                icon="SEND"
                size="medium"
                height="small"
                className="cursor-pointer"
              />
            </ToolTip>
          </button>

          {/* Attach button */}
          <button
            type="button"
            className="flex items-center gap-1 text-gray-500"
            onClick={handleAttachClick}
            >
            <ToolTip tip="Attach File" keyId="attachFile">
              <Icon
                icon="PAPERCLIP"
                size="medium"
                height="small"
                className="cursor-pointer"
              />
            </ToolTip>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*,application/pdf"
              className="hidden"
              onChange={handleFileChange}
            />
          </button>

          {/* Video request button */}
          <button
            id="btn_sendVideoRequest"
            data-testid="btn_sendVideoRequest"
            disabled={isSendDisabled || videoLimitExceeded}
            onClick={e => {
              e.preventDefault();
              if (hasActiveVideoRequest) {
                handleActiveVideoRequest();
                return;
              }
              sendVideoRequestToServer();
            }}
            className="flex items-center p-1 text-gray-500">
            <ToolTip tip="chatVideoRequest" keyId="chatVideoRequest">
              <NewIcon
                icon="VIDEO_CAM"
                size="medium"
                height="small"
                className="cursor-pointer"
              />
            </ToolTip>
          </button>

        </div>
      </form>
    </div>
  );
}

export default MessageTypeArea;
