/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { TrackLoop, TrackReferenceOrPlaceholder } from '@livekit/components-react'
import * as React from 'react'
import { useCustomRoomContext } from '../CustomRoomContext'

interface ParticipantSectionProps {
  title: string
  count: number
  tracks: TrackReferenceOrPlaceholder[]
  children: React.ReactNode
}

/**
 * A React component that displays a section of participants in a meeting.
 * It conditionally renders the title and count of participants based on the view type.
 * It also renders a TrackLoop component with the provided tracks and children.
 *
 * @param {string} title - The title of the participant section.
 * @param {number} count - The number of participants in the section.
 * @param {TrackReferenceOrPlaceholder[]} tracks - The tracks to be rendered in the TrackLoop.
 * @param {React.ReactNode} children - The children elements to be rendered inside the TrackLoop.
 * @return {JSX.Element} The rendered participant section element.
 */

export const ParticipantSection: React.FC<ParticipantSectionProps> = ({ title, count, tracks, children }) => {
  const { viewType } = useCustomRoomContext()
  const elementRef = React.useRef<HTMLDivElement>(null)

  // Use useEffect to apply dynamic min-width only when viewType is not 'hostHighlighted'
  React.useEffect(() => {
    if (elementRef.current && viewType !== 'hostHighlighted') {
      elementRef.current.style.minWidth = `${count * 17 * 7}px`
    }
  }, [viewType, count])

  return (
    <div className="flex flex-col items-center w-full h-full">
      {count > 0 && (
        <div className="text-center lg:text-lg md:text-md sm:text-sm xs:text-xs font-medium text-black pt-4">
          {viewType === 'grid' && `${title} (${count})`}
        </div>
      )}
      <div className="flex-1 w-full h-full overflow-x-scroll overflow-hidden scrollbar-hide">
        <div
          ref={elementRef}
          className={
            viewType === 'hostHighlighted'
              ? 'h-full content-center gap-3 flex flex-wrap justify-center'
              : 'flex justify-center items-center gap-2 h-full'
          }
        >
          {count > 0 && <TrackLoop tracks={tracks}>{children}</TrackLoop>}
        </div>
      </div>
    </div>
  )
}
